<template>
    <v-layout>
        <v-dialog v-model='visibility' origin='top center' :min-width='minWidth' :max-width='maxWidth'
                  @click:outside='closeDialog'>
            <v-card flat>
                <v-toolbar flat dense :color='$store.getters.appTheme.dialogHeader'>
                    <v-toolbar-title class="title font-weight-regular mx-auto">
                        <slot name='title'>Dialog Title</slot>
                    </v-toolbar-title>
                </v-toolbar>
                <v-divider :color='$store.getters.uiAccent' v-if='!indicator'></v-divider>
                <v-progress-linear
                    :color='$store.getters.uiAccent'
                    height='1' class='py-0 my-0'
                    :active='indicator'
                    indeterminate
                ></v-progress-linear>
                <v-card-text class='text-center my-0 py-1 px-2'>
                    <slot name='content'>
                        <span>Default Dialog Content</span>
                    </slot>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class='py-1 px-2'>
                    <v-row class='my-0 py-0' no-gutters>
                        <v-col class='my-0 py-0' cols='2'>
                            <v-btn block depressed @click='closeDialog' class='px-0'>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class='my-0 py-0 ml-auto pl-1' cols='10'>
                            <slot name='action'>
                                <v-btn block text disabled class='px-0'>-</v-btn>
                            </slot>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>
    export default {
        name: 'DialogGeneric',
        props: {
            indicator: {default: false},
            visibility: {default: false},
            minWidth: {default: '320px'},
            maxWidth: {default: '420px'}
        },
        methods: {
            closeDialog() {
                this.$emit('update:visibility', false)
            }
        }
    }
</script>