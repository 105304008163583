<template>

    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>Редактирование фида Avito</template>
        <template v-slot:action>
            <v-btn
                text
                color='green darken-2'
                @click="saveFeed"
            >
                Обновить
            </v-btn>
        </template>
        <template v-slot:content>
            <v-card flat>
                <v-tabs
                    v-model='activeTab'
                    :slider-color='$store.getters.uiAccent'
                    :color='$store.getters.uiAccent'
                    grow
                    show-arrows
                    input='boundTabEvent'
                    :value='activeTab'
                >

                    <v-tab key='0' ripple>Общие элементы</v-tab>
                    <v-tab key='1' ripple>Контактная информация</v-tab>
                    <v-tab key='2' ripple>Местоположение</v-tab>
                    <v-tab key='3' ripple>Описание</v-tab>
                    <v-tab key='4' ripple>Параметры недвижимости</v-tab>
                    <v-tab key='5' ripple>Фото и видео</v-tab>
                </v-tabs>
            </v-card>

            <v-tabs-items v-model='activeTab' v-if="visibility">

                <v-tab-item key="0">
                    <v-form v-model="validGeneralInfo" style='min-width: 320px;'>

                        <v-autocomplete
                            :items="avito.listingFeeChoices"
                            v-model="feed.avito.listing_fee"
                            clearable
                            label="Вариант платного размещения"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                        <v-autocomplete
                            :items="avito.adStatusChoices"
                            v-model="feed.avito.ad_status"
                            clearable
                            label="Платная услуга, которую нужно применить к объявлению"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                        <v-text-field
                            v-model="feed.avito.avito_id"
                            clearable
                            label="Номер объявления на Авито"
                        ></v-text-field>

                    </v-form>
                </v-tab-item>


                <v-tab-item key="1">

                    <v-form style='min-width: 320px; width: 500px;'>

                        <v-autocomplete
                            :items="avito.yesNoChoices"
                            v-model="feed.avito.allow_email"
                            clearable
                            label="Возможность написать сообщение по объявлению через сайт"
                        ></v-autocomplete>
                        <v-text-field
                            v-model="feed.avito.manager_name"
                            :rules="[rules.maxLength]"
                            counter
                            maxlength="40"
                            hint="строка не более 40 символов"
                            label="Имя менеджера, контактного лица компании по данному объявлению"
                        ></v-text-field>
                        <v-text-field
                            v-model="feed.avito.contact_phone"
                            label="Контактный телефон"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Только один российский номер телефона;<br>
                                    Должен быть обязательно указан код города или мобильного оператора. <br>
                                    Корректные примеры:
                                    <br>
                                    - +7 (495) 777-10-66, <br>
                                    - (81374) 4-55-75, <br>
                                    - 8 905 207 04 90, <br>
                                    - +7 905 2070490, <br>
                                    - 88123855085, <br>
                                    - 9052070490
                                </div>
                            </v-tooltip>
                        </v-text-field>

                    </v-form>

                </v-tab-item>

                <v-tab-item key="2">

                    <v-form style='min-width: 320px; width: 500px;'>
                        <v-text-field
                            v-model="feed.avito.address"
                            clearable
                            counter
                            :rules="[rules.required, rules.descriptionMaxLength]"
                            maxlength="256"
                            label="Полный адрес объекта"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <p>
                                    Например: <br>
                                    Россия, Тамбовская область, Моршанск, Лесная улица, 7
                                </p>
                            </v-tooltip>
                        </v-text-field>
                    </v-form>

                </v-tab-item>

                <v-tab-item key="3">
                    <v-form style='min-width: 320px; width: 500px;'>
                        <v-textarea
                            v-model="feed.avito.description"
                            :rules="[rules.required, rules.descriptionMaxLength]"
                            counter
                            maxlength="5000"
                            hint="строка не более 5000 символов"
                            label="Текстовое описание объявления в соответствии с правилами Авито"
                            @keydown="changeDinamicKey"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <p>
                                    Если у вас есть оплаченная Подписка, то поместив описание внутрь CDATA, <br>
                                    вы можете использовать дополнительное форматирование с помощью HTML-тегов — строго
                                    из указанного списка:
                                    <br> p, br, strong, em, ul, ol, li.
                                </p>
                            </v-tooltip>
                        </v-textarea>

                        <div v-if="mKeys">
                          <div class='key-list-item'
                                v-for='(keyItem, index) in keyItems'
                                :key='index'
                                @click="insertKeyInText"
                                :title=keyItem.text
                               >
                                       {{ keyItem.value }}
                          </div>
                        </div>

                    </v-form>
                </v-tab-item>

                <v-tab-item key="4">
                    <v-form style='min-width: 320px; width: 500px;'>


                        <v-textarea
                            v-model="feed.avito.title"
                            clearable
                            label="Заголовок объявления"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <p>
                                    В заголовке необходимо указывать только вид объекта и основные параметры. <br>
                                    Указание цены, слов, привлекающих внимание, контактной информации, <br>
                                    адреса сайта или только слова «продам / куплю» нарушает правила Авито.
                                </p>
                            </v-tooltip>

                        </v-textarea>








                    </v-form>

                </v-tab-item>

                <v-tab-item key="5">
                    <v-form style='min-width: 320px; width: 500px;'>
                        <v-text-field
                            v-model="feed.avito.layout_photo"
                            label="Изображение планировки"
                        ></v-text-field>
                        <v-text-field
                            v-model="feed.avito.floor_plan_photo"
                            label="Изображение поэтажного плана"
                        ></v-text-field>
                        <v-textarea
                            v-model="feed.avito.images"
                            label="Фотографии"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <p>
                                    Каждая ссылка на отдельной строчке <br>
                                    <b>Максимальное количество фотографий - 40</b>
                                </p>
                            </v-tooltip>

                        </v-textarea>
                        <v-text-field
                            v-model="feed.avito.video_url"
                            label="Видео c YouTube — ссылка"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <p>
                                    Формат ссылки на видео должен соответствовать - <b>https://www.youtube.com/watch?v=*** </b>
                                    <br>
                                    Пример неправильной ссылки - <b>http://www.youtube.com/embed/***</b>
                                </p>
                            </v-tooltip>
                        </v-text-field>
                    </v-form>
                </v-tab-item>


            </v-tabs-items>
        </template>
    </dialog-fullscreen>

</template>

<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue';
    import avitoVariables from "../../../store/avitoVariables";
    import feedVariables from "../../../store/feedVariables";

    export default {
        name: 'AvitoCommercialBuildingUpdateDialog',
        props: ['initialState', 'feed'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                valid: false,
                validGeneralInfo: false,
                indicator: false,
                visibility: this.initialState,
                activeTab: 0,
                mKeys: false,
                feedVariables: feedVariables,
                keyItems: [],
                currentCursorPosition: 0,
                rules: {
                    required: value => !!value || 'Обязательное поле.',
                    email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный электронный адрес',
                    maxLength: v => v && v.length <= 40 || 'Макс 40 символов',
                    addressMaxLength: v => v && v.length <= 256 || 'Макс 256 символов',
                    descriptionMaxLength: v => v && v.length <= 5000 || 'Макс 5000 символов',
                },
                avito: {
                    buildings: [],
                    _additional: [],
                    ...avitoVariables.avito
                }
            }
        },
        methods: {
            getAvitoBuilding() {
                this.$http.get(
                    this.$store.getters.endpoint + '/avito/buildings', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin,
                            'rc': this.feed.residential_complex || '',
                        },
                        headers: this.$store.state.restrictedHeaders
                    },
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.avito.buildings = response.data
                    } else {
                        console.log(response)
                    }
                })
            },
            print() {
                console.log(this.feed.avito)
            },
            getAdditionalObjects() {
                if (this.feed.avito.object_type) {
                    const object_type = this.avito.objectTypeChoices.find(el => el.value === this.feed.avito.object_type);
                    this.avito._additional = this.avito.additionalObjectTypeChoices[object_type.typeId]
                } else {
                    this.avito._additional = []
                }
                this.feed.avito.additional_object_type = null;
            },
            saveFeed() {
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin,
                }
                this.$http.put(this.$store.getters.endpoint + `/feeds/${this.feed.id}/avito`, {
                    ...requestData,
                    avito: this.feed.avito
                }, {
                    headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                }).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        console.log(response)
                    } else {
                        console.log(response)
                    }
                })
            },
            changeDinamicKey(key) {
              let text_description = this.feed.avito.description
              this.currentCursorPosition = event.path[0].selectionStart
              if (key.keyCode == 192) {
                  if (!this.mKeys) {
                      this.mKeys = true
                  } else {
                      this.mKeys = false
                  }
              }
              this.keyItems = feedVariables.replaceKeys;
            },
            insertKeyInText(e) {
              let F = this.feed.avito.description.substr(0, this.currentCursorPosition)
              let L = this.feed.avito.description.substr(this.currentCursorPosition + 1)
              this.feed.avito.description = F + e.target.textContent.trim() + L

              this.mKeys = false
            }
        },
        watch: {
            initialState(value) {
                // this.getAvitoBuilding();
                if (value) {
                    this.visibility = value;
                }

            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
        },
        computed: {
            isSetable() {
                return this.feed.avito.object_type && ["Офисное помещение", "Складское помещение"].includes(this.feed.avito.object_type)
            }
        }
    }
</script>
