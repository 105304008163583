export default {
    operations: [
        {
            header: 'Базовые параметры'
        },
        {
            value: 'area',
            text: 'Изменить общую площадь',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'living_space',
            text: 'Изменить жилую площадь',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'kitchen_space',
            text: 'Изменить площадь кухни',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'price',
            text: 'Изменить цену',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'rooms',
            text: 'Изменить количество комнат',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'studio',
            text: 'Изменить параметр студия',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'floor',
            text: 'Изменить этаж',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'cian_feed_format',
            text: 'Изменить принадлежность к фиду (ЦИАН)',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        // {
        //     value: 'floors_total',
        //     text: 'Изменить количество этажей',
        //     group: 'Базовые параметры',
        //     group_id: 'base',
        //     defaultValue: null
        // },
        // {
        //     value: 'longitude',
        //     text: 'Изменить географическую долготу',
        //     group: 'Базовые параметры',
        //     group_id: 'base'
        // },
        // {
        //     value: 'latitude',
        //     text: 'Изменить географическую широту',
        //     group: 'Базовые параметры',
        //     group_id: 'base',
        //     defaultValue: null
        // },
        {
            value: 'mortgage',
            text: 'Изменить доступность по ипотеке',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'is_active',
            text: 'Включение / Отключение лотов',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'is_cian_active',
            text: 'Включение / Отключение лотов (ЦИАН)',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'is_yandex_active',
            text: 'Включение / Отключение лотов (Яндекс)',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        {
            value: 'is_avito_active',
            text: 'Включение / Отключение лотов (Авито)',
            group: 'Базовые параметры',
            group_id: 'base',
            defaultValue: null
        },
        // {
        //     value: 'built_year',
        //     text: 'Изменить год сдачи (постройки)',
        //     group: 'Базовые параметры',
        //     group_id: 'base',
        //     defaultValue: null
        // },
        {divider: true},
        {header: 'Параметры ЦИАН'},
        {
            value: 'cian_phones',
            text: 'Изменить контактный телефон (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_address',
            text: 'Изменить адрес объявления (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_flat_number',
            text: 'Изменить номер на площадке (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_section_number',
            text: 'Изменить номер секции (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_photos',
            text: 'Изменить ссылки на фотографии (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_videos',
            text: 'Изменить ссылку на видео (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_flat_type',
            text: 'Изменить тип квартиры (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_sale_type',
            text: 'Изменить тип продажи (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_room_type',
            text: 'Изменить тип комнаты (комнат) (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_is_apartments',
            text: 'Изменить признак апартамента (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_loggias_count',
            text: 'Изменить количество лоджий (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_balconies_count',
            text: 'Изменить количество балконов (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_decoration',
            text: 'Изменить отделку (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_windows_view_type',
            text: 'Изменить выход окон (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_separate_wcs_count',
            text: 'Изменить количество раздельных санузлов (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_combined_wcs_count',
            text: 'Изменить количество совместных санузлов (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_description',
            text: 'Изменить текст объявления (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'auction',
            text: 'Изменить ставку на объявления в аукционе (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {
            value: 'cian_title',
            text: 'Изменить заголовок объявления (ЦИАН)',
            group: 'ЦИАН',
            group_id: 'cian',
            defaultValue: ''
        },
        {divider: true},
        {
            header: 'Параметры Яндекс'
        },
        {
            value: 'yandex_sales_agent_phone',
            text: 'Изменить контактный телефон (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: ''
        },
        {
            value: 'yandex_description',
            text: 'Изменить дополнительную информацию (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: ''
        },
        {
            value: 'yandex_renovation',
            text: 'Изменить ремонт (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: null
        },
        {
            value: 'yandex_online_show',
            text: 'Изменить возможность показа недвижимости по видео-звонку (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: null
        },
        {
            value: 'yandex_youtube_url',
            text: 'Изменить ссылку на видео с объектом с YouTube (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: null
        },
        {
            value: 'yandex_images',
            text: 'Изменить фотографии объекта и планировки (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: null
        },
        {
            value: 'yandex_rooms_type',
            text: 'Изменить изменить тип комнат (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: []
        },
        {
            value: 'yandex_apartments',
            text: 'Изменить признак апартамента (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: null
        },
        {
            value: 'yandex_balcony',
            text: 'Изменить тип балкона (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: null
        },
        {
            value: 'yandex_window_view',
            text: 'Изменить вид из окон (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: []
        },
        {
            value: 'yandex_floor_covering',
            text: 'Изменить покрытие пола (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: []
        },
        {
            value: 'yandex_bathroom_unit',
            text: 'Изменить тип санузла (Яндекс)',
            group: 'Яндекс',
            group_id: 'yandex',
            defaultValue: null
        },
        {divider: true},
        {header: 'Параметры Авито'},
        {
            value: 'avito_listing_fee',
            text: 'Изменить вариант платного размещения (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: null
        },
        {
            value: 'avito_ad_status',
            text: 'Изменить платную услуга, которую нужно применять к объявлениям (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: null
        },
        {
            value: 'avito_description',
            text: 'Изменить текстовое описание объявления (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: ''
        },
        {
            value: 'avito_contact_phone',
            text: 'Изменить контактный телефон (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: ''
        },
        // {
        //     value: 'house_type',
        //     text: 'Изменить тип дома (Авито)',
        //     group: 'Авито',
        //     group_id: 'avito',
        //     defaultValue: ''
        // },
        {
            value: 'avito_decoration',
            text: 'Изменить отделку помещения (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: ''
        },
        {
            value: 'avito_apartment_number',
            text: 'Изменить номер квартиры (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: ''
        },
        {
            value: 'avito_status',
            text: 'Изменить статус недвижимости (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: ''
        },
        {
            value: 'avito_balcony_or_loggia',
            text: 'Изменить балкон или лоджию (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: ''
        },
        {
            value: 'avito_view_from_windows',
            text: 'Изменить вид из окон (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: []
        },
        // {
        //     value: 'passenger_elevator',
        //     text: 'Изменить количество пассажирских лифтов (Авито)',
        //     group: 'Авито',
        //     group_id: 'avito',
        //     defaultValue: null
        // },
        // {
        //     value: 'freight_elevator',
        //     text: 'Изменить количество грузовых лифтов (Авито)',
        //     group: 'Авито',
        //     group_id: 'avito',
        //     defaultValue: null
        // },
        {
            value: 'avito_courtyard',
            text: 'Изменить двор (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: []
        },
         {
             value: 'parking',
             text: 'Изменить парковку (Авито)',
             group: 'Авито',
             group_id: 'avito',
             defaultValue: []
         },
        // {
        //     value: 'ceiling_height',
        //     text: 'Изменить высоту потолков (Авито)',
        //     group: 'Авито',
        //     group_id: 'avito',
        //     defaultValue: null
        // },
        {
            value: 'avito_bathroom',
            text: 'Изменить санузел (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: null
        },
        {
            value: 'avito_nd_additionally',
            text: 'Изменить дополнительно (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: []
        },
        {
            value: 'avito_deal_type',
            text: 'Изменить тип сделки (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: null
        },
        {
            value: 'avito_room_type',
            text: 'Изменить тип комнат (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: []
        },
        {
            value: 'avito_video_url',
            text: 'Изменить ссылку на видео (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: null
        },
        {
            value: 'avito_title',
            text: 'Изменить заголовок объявления (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: null
        },
        // {
        //     value: 'object_type',
        //     text: 'Изменить вид объекта (Авито)',
        //     group: 'Авито',
        //     group_id: 'avito',
        //     defaultValue: null
        // },
        {
            value: 'avito_photos',
            text: 'Изменить ссылки на фотографии (Авито)',
            group: 'Авито',
            group_id: 'avito',
            defaultValue: ''
        },
    ],
}
