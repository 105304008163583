import Vue from 'vue'
import router from '@/router'

export default {

    authorizeUser(context, payload) {
        context.state.progress.log = true
        let flow = payload.flow
        let data = payload.user
        let authority = ''
        switch (flow) {
            case 'start':
                authority = `Plain ${data.login.toLowerCase()}:${data.password}`
                break
            case 'resume':
                authority = `Bearer ${context.getters.userToken}`
                break
            case 'social':
                authority = `External ${data.email}`
                break;
        }
        let requestHeaders = {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': authority
        }
        Vue.http.post(
            context.getters.authHandler + '/login', {user: data}, {headers: requestHeaders}
        ).then((r) => {
            if (r.body.result) {
                context.state.progress.log = false
                let ah = {
                    'X-Auth-User': String(r.body.user['uid']),
                    'X-Auth-State': String(r.body.user['superuser']),
                    'Authorization': `Bearer ${r.body.user['token']}`
                }
                context.commit('authHeaders', ah)
                context.commit('authorize', {'user': r.body.user});
                context.state.progress.retreive = false;
                if (router.currentRoute.query.redirect) {
                    console.log(router.currentRoute.query.redirect);
                    router.push(router.currentRoute.query.redirect);
                } else {
                    if (router.currentRoute.name == 'auth') {
                        router.push('/')
                    }
                }
            } else {
                let user = r.body
                context.state.progress.log = false
                if (flow == 'resume') {
                    context.dispatch('deauthorizeUser')
                }
                if (user.reason == 'login not found' || user.reason == 'lookup') {
                    context.commit('authErrorEmail', 'Неверный логин')
                }
                if (user.reason == 'Empty or short password') {
                    context.commit('authErrorPassword', 'Слишком короткий пароль')
                }
                if (user.reason == 'challenge failed') {
                    context.commit('authErrorPassword', 'Неверный пароль')
                }
            }
        })
    },
    deauthorizeUser(context) {
        context.state.progress.log = true;
        Vue.http.post(
            context.getters.authHandler + '/logout', {}, {headers: {'Content-Type': 'application/json; charset=UTF-8'}}
        ).then((r) => {
            context.state.progress.log = false;
            context.commit('exitSystem');
            router.push({'path': '/auth', 'query': {'redirect': '/'}});
        });
    }
}
