<template>
    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>

        <template v-slot:title>Создание проекта</template>
        <template v-slot:action>
            <v-btn
                text
                :disabled='!valid'
                :loading='indicator'
                color='green darken-2'
                @click='createProject'
            >
                Создать
            </v-btn>
        </template>
        <template v-slot:content>

            <v-form v-model='valid' style='min-width: 320px; width: 600px;' v-if="visibility">

                <v-text-field
                    v-model="project.name"
                    label="Название проекта"
                    :rules="[rules.required]"
                ></v-text-field>

                <v-select
                    v-model="project.type"
                    label="Тип проекта"
                    :rules="[rules.required]"
                    :items="projectTypes"
                    item-value="id"
                    item-text="name"
                >

                </v-select>

                <p>
                    Добавить пользователей для уведомлений о результатах валидации
                    <v-btn depressed x-small @click="addUser">
                        <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                </p>

                <v-container
                    v-for="(n, i) in project.notification.users"
                    :key="'notify_' + i"
                    fluid
                >
                    <v-row>
                        <v-col cols="3">
                            <v-container>
                                <v-select
                                    v-model="n.type"
                                    :items="[{value: 'tgm', text: 'Telegram'}, {value: 'email', text: 'Email'}]"
                                    @change="changeNotificationType(i)"
                                    dense
                                    flat
                                >
                                </v-select>
                            </v-container>
                        </v-col>
                        <v-col cols="9">
                            <v-container v-if="n.type == 'tgm'">
                                <v-text-field
                                    v-model="n.id"
                                    :rules="[rules.required]"
                                    placeholder="Введите Ваш никнейм без @"
                                    dense
                                    flat
                                >
                                    <v-tooltip
                                        :open-on-hover="true"
                                        top
                                        slot="prepend"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                        </template>
                                        <div>
                                            Перед тем как добавить пользователя, необходимо добавить бота @artics_io_bot к себе
                                            <br>
                                            Введите Ваш никнейм без @
                                        </div>
                                    </v-tooltip>

                                    <template slot="append-outer">
                                        <v-icon @click="removeUser(i)" :color='$store.getters.uiAccentIcon'>mdi-delete</v-icon>
                                    </template>
                                </v-text-field>
                            </v-container>
                            <v-container v-else>
                                <v-text-field
                                    v-model="n.id"
                                    :rules="[rules.required, rules.email]"
                                    dense
                                    flat
                                >
                                    <template slot="append-outer">
                                        <v-icon @click="removeUser(i)" :color='$store.getters.uiAccentIcon'>mdi-delete</v-icon>
                                    </template>
                                </v-text-field>
                            </v-container>
                        </v-col>

                    </v-row>

                </v-container>



                <v-divider></v-divider>

                <template v-if="project.type == 'realty'">

                    <v-card flat>
                        <v-tabs
                            v-model='activeTab'
                            :slider-color='$store.getters.uiAccent'
                            :color='$store.getters.uiAccent'
                            grow
                            show-arrows
                            input='boundTabEvent'
                            :value='activeTab'
                        >
                            <v-tab key='0' ripple>Яндекс</v-tab>
                            <v-tab key='1' ripple>Авито</v-tab>
                            <!--                        <v-tab key='2' ripple>Циан</v-tab>-->
                        </v-tabs>
                    </v-card>

                    <div class="mt-25">
                        <v-tabs-items v-model='activeTab' v-if="visibility" class="mt-25">
                            <v-tab-item key="0">
                                <v-text-field
                                    v-model="project.yandex.name"
                                    label="Имя продавца, арендодателя или агента"
                                ></v-text-field>
                            <v-select
                                :items="salesAgentCategories"
                                v-model="project.yandex.category"
                                clearable
                                label="Тип продавца или арендодателя"
                                :rules="[rules.required]"
                            ></v-select>

                                <v-text-field
                                    v-model="project.yandex.phone"
                                    label="Номер телефона"
                                    :rules="[rules.required]"
                                    required
                                >
                                    <v-tooltip
                                        :open-on-hover="true"
                                        top
                                        slot="append-outer"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                        </template>
                                        <div>
                                            Номер указывается в международном формате; +74951234567
                                        </div>
                                    </v-tooltip>
                                </v-text-field>
                                <v-text-field
                                    v-model="project.yandex.organization"
                                    label="Название организации"
                                ></v-text-field>
                                <v-text-field
                                    v-model="project.yandex.url"
                                    label="Сайт агентства или застройщика"
                                ></v-text-field>
                                <v-text-field
                                    v-model="project.yandex.email"
                                    label="Электронный адрес продавца"
                                    :rules="[rules.email]"
                                ></v-text-field>
                                <v-text-field
                                    v-model="project.yandex.photo"
                                    label="Ссылка на фотографию агента или логотип компании"
                                ></v-text-field>

                            </v-tab-item>

                            <v-tab-item key="1">
                                <v-autocomplete
                                    :items="yesNoChoices"
                                    v-model="project.avito.allow_email"
                                    clearable
                                    label="Возможность написать сообщение по объявлению через сайт"
                                ></v-autocomplete>

                                <v-text-field
                                    v-model="project.avito.manager_name"
                                    :rules="[rules.maxLength]"
                                    counter
                                    maxlength="40"
                                    hint="строка не более 40 символов"
                                    label="Имя менеджера, контактного лица компании по данному объявлению"
                                ></v-text-field>

                                <v-text-field
                                    v-model="project.avito.contact_phone"
                                    label="Контактный телефон"
                                >
                                    <v-tooltip
                                        :open-on-hover="true"
                                        top
                                        slot="append-outer"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                        </template>
                                        <div>
                                            Только один российский номер телефона;<br>
                                            Должен быть обязательно указан код города или мобильного оператора. <br>
                                            Корректные примеры:
                                            <br>
                                            - +7 (495) 777-10-66, <br>
                                            - (81374) 4-55-75, <br>
                                            - 8 905 207 04 90, <br>
                                            - +7 905 2070490, <br>
                                            - 88123855085, <br>
                                            - 9052070490
                                        </div>
                                    </v-tooltip>
                                </v-text-field>
                            </v-tab-item>
                        </v-tabs-items>
                    </div>

                </template>

                <template v-if="project.type == 'ecom'">

                    <v-card flat>
                        <v-tabs
                            v-model='activeTab'
                            :slider-color='$store.getters.uiAccent'
                            :color='$store.getters.uiAccent'
                            grow
                            show-arrows
                            input='boundTabEvent'
                            :value='activeTab'
                        >
                            <v-tab key='0' ripple>Яндекс Маркет</v-tab>
<!--                            <v-tab key='1' ripple>Google Merchant</v-tab>-->
                        </v-tabs>
                    </v-card>

                    <div class="mt-25">
                        <v-tabs-items v-model='activeTab' v-if="visibility" class="mt-25">
                            <v-tab-item key="0">

                                <v-text-field
                                    v-model="project.yandex_market.name"
                                    label="Короткое название магазина"
                                    :rules="[rules.required]"
                                ></v-text-field>

                                <v-text-field
                                    v-model="project.yandex_market.company"
                                    label="Полное наименование компании, владеющей магазином"
                                    :rules="[rules.required]"
                                ></v-text-field>

                                <v-text-field
                                    v-model="project.yandex_market.url"
                                    label="URL главной страницы магазина"
                                    :rules="[rules.required]"
                                ></v-text-field>

                                <v-text-field
                                    v-model="project.yandex_market.platform"
                                    label="Система управления контентом, на основе которой работает магазин (CMS)."
                                ></v-text-field>

                                <v-text-field
                                    v-model="project.yandex_market.version"
                                    label="Версия CMS."
                                ></v-text-field>

                                <v-text-field
                                    v-model="project.yandex_market.agency"
                                    label="Наименование агентства, которое оказывает техническую поддержку магазину и отвечает за работоспособность сайта."
                                ></v-text-field>


                            </v-tab-item>
                        </v-tabs-items>
                    </div>

                </template>



            </v-form>

        </template>

    </dialog-fullscreen>
</template>

<style>
    .mt-25 {
        margin-top: 25px;
    }
</style>

<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue'

    export default {
        name: 'ProjectCreateDialog',
        props: ['initialState', 'postAction'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                valid: false,
                indicator: false,
                visibility: this.initialState,
                errorCreate: false,
                errorCreateMessage: '',
                activeTab: 0,
                tgmUsers: [],
                yandexMarketCurrencies: [
                    {
                        value: 'RUR',
                        text: 'RUR',
                    },
                    {
                        value: 'UAH',
                        text: 'UAH',
                    },
                    {
                        value: 'BYN',
                        text: 'BYN',
                    },
                    {
                        value: 'KZT',
                        text: 'KZT',
                    },
                    {
                        value: 'USD',
                        text: 'USD',
                    },
                    {
                        value: 'EUR',
                        text: 'EUR',
                    },
                ],
                projectTypes: [],
                rules: {
                    required: value => !!value || 'Обязательное поле.',
                    email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный электронный адрес',
                    maxLength: v => v.length <= 40 || 'Макс 40 символов',
                },
                salesAgentCategories: [
                    {
                        value: 'developer',
                        text: 'Застройщик'
                    },
                    {
                        value: 'agency',
                        text: 'Агентство'
                    },
                ],
                yesNoChoices: [
                    {
                        value: 'Нет',
                        text: 'Нет'
                    },
                    {
                        value: 'Да',
                        text: 'Да'
                    },
                ],
                project: {
                    name: '',
                    yandex: {
                        name: '',
                        phone: '',
                        organization: '',
                        url: '',
                        email: '',
                        photo: ''
                    },
                    avito: {
                        allow_email: 'Нет',
                        manager_name: '',
                        contact_phone: ''
                    },
                    cian: {},
                    yandex_market: {
                        name: '',
                        company: '',
                        url: '',
                        platform: '',
                        version: '',
                        agency: '',
                        email: '',
                    },
                    notification: {
                        users: []
                    }
                }
            }
        },
        watch: {
            initialState(value) {
                if (value) {
                    this.visibility = value;
                    this.getProjectTypes();
                }
            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
        },
        computed: {
            validCurrency() {
                if (this.project.yandex_market.currencies.length > 0) {
                    this.project.yandex_market.currencies.forEach(c => {
                        if (!c.cur_id || !c.rate) {
                            return false
                        }
                    });
                    return true;
                }
                return false
            }
        },
        methods: {
            getProjectTypes() {
                this.loading = true;
                this.projectTypes = [];
                const params = {
                    'login': this.$store.getters.userLogin,
                };
                this.$http.get(
                    this.$store.getters.endpoint + `/project_types`, {
                        emulateJSON: true,
                        params: params,
                        headers: this.$store.state.restrictedHeaders
                    }
                ).then(
                    r => {
                        let response = r.body;
                        console.log(response)
                        if (response.result) {
                            this.projectTypes = response.data;
                        }
                    }
                ).finally(() => {
                    this.loading = false;
                })
            },
            addUser() {
                this.project.notification.users.push(
                    {
                        type: 'tgm',
                        id: null
                    }
                )
            },
            removeUser(index) {
                this.project.notification.users.splice(index, 1);
            },
            //
            // addCurrency() {
            //     this.project.yandex_market.currencies.push(
            //         {
            //             id: null,
            //             cur_id: null,
            //             rate: null,
            //         }
            //     )
            // },
            // removeCurrency(index) {
            //     this.project.yandex_market.currencies.splice(index, 1)
            // },
            //
            // addCategory() {
            //     this.project.yandex_market.categories.push(
            //         {
            //             id: null,
            //             cat_id: null,
            //             parent_id: null,
            //             name: null,
            //         }
            //     )
            // },
            // removeCategory(index) {
            //     this.project.yandex_market.categories.splice(index, 1)
            // },
            //
            // getCategories(catId) {
            //     if (catId) {
            //         return this.project.yandex_market.categories.filter(c => {
            //             return c.cat_id != catId
            //         })
            //     }
            //     return this.project.yandex_market.categories || [];
            // },

            changeNotificationType(index) {
                this.project.notification.users[index].id = null;
            },
            createProject() {
                this.indicator = true;
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin
                }

                this.$http.post(this.$store.getters.endpoint + '/projects',
                    {...requestData, ...this.project},
                    {
                        headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                    }).then((r) => {
                    let response = r.body;
                    this.indicator = false;
                    if (response.result) {
                        this.postAction()
                        this.visibility = false;
                    } else {
                        this.errorCreate = true;
                        this.errorCreateMessage = ['Идентификатор уже используется в другом объекте']
                    }
                })
            }

        }
    }
</script>
