import Vue from 'vue'
import Router from 'vue-router'
import Projects from "./views/Projects.vue";
import Project from "./views/Project.vue";
import Auth from "./views/Auth.vue"
import NotFound from "./views/404.vue"
import store from '@/store'
import Housing from "./views/Housing.vue";
import Shop from "./views/Shop.vue";

Vue.use(Router);

const appRoutes = [
    {
        path: '/auth',
        name: 'auth',
        component: Auth,
        meta: {
            requiresAuth: false,
            title: 'Авторизация'
        }
    },
    {
        path: '/',
        name: 'projects',
        component: Projects,
        meta: {
            requiresAuth: true,
            title: 'Проекты'
        }
    },
    {
        path: '*',
        name: '404',
        component: NotFound,
        meta: {
            requiresAuth: false,
            title: 'Не смогли найти'
        }
    },
    {
        path: '/p/:id',
        name: 'project',
        component: Project,
        meta: {
            requiresAuth: true,
            title: 'Проект'
        }
    },
    {
        path: '/h/:id',
        name: 'housing',
        component: Housing,
        meta: {
            requiresAuth: true,
            title: 'Корпус'
        }
    },
    {
        path: '/s/:id',
        name: 'shop',
        component: Shop,
        meta: {
            requiresAuth: true,
            title: 'Магазин'
        }
    },
    // {
    //     path: '/krost',
    //     name: 'Krost',
    //     component: () => import('./views/Krost.vue')
    // },
    // {
    //     path: '/mrgroup',
    //     name: 'Mrgroup',
    //     component: () => import('./views/Mrgroup.vue')
    // }
];

// export default new Router({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes: []
// })
const router = new Router({
    routes: appRoutes,
    mode: 'history',
    base: process.env.BASE_URL
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    store.commit('clearHeaders');
    if (to.matched.some(record => record.meta.requiresAuth) && !store.state.isLoggedIn) {
        next({path: '/auth', query: {redirect: to.fullPath}})
    } else {
        if (to.name === "auth" && to.hash.includes("access_token")) {
            next({path: to.fullPath.replace('#', '?')})
        } else if (to.hash.length && to.name !== "auth") {
            let modifiedPath = to.hash.replace('#', '');
            if (to.hash.includes('/projects/')) {
                modifiedPath = modifiedPath.replace('/projects/', '/p/')
            }
            next({path: modifiedPath})
        } else {
            if (to.matched.some(record => !record.meta.requiresAuth && record.name != '404' && record.name != 'privacy' && record.name != 'about') && store.state.isLoggedIn) {
                next({path: '/'})
            } else {
                next()
            }
        }
    }
});

router.afterEach((to, from) => {
    document.title = store.getters.pageTitle + ' | ' + to.meta.title;
});

export default router;
