<template>
    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>Редактирование корпуса</template>

        <template v-slot:action>
            <v-btn
                text
                :disabled='!valid'
                :loading='indicator'
                color='green darken-2'
                @click="updateHousing"
            >
                Обновить
            </v-btn>
        </template>

        <template v-slot:content>

            <v-form v-model='valid' style='min-width: 320px; width: 600px;' v-if="visibility">

                <v-text-field
                    :rules="[rules.required]"
                    v-model="housing.name"
                    label="Название корпуса *"
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model.number="housing.floors_total"
                    type="number"
                    label="Общее количество этажей в доме *"
                    clearable
                ></v-text-field>

                <v-select
                    :items="ya.buildingStateChoices"
                    v-model="housing.building_state"
                    label="Стадия строительства дома *"
                    clearable
                ></v-select>

                <v-text-field
                    v-model.number="housing.built_year"
                    type="number"
                    label="Год сдачи (год постройки) *"
                    clearable
                ></v-text-field>

                <v-select
                    :items="ya.quarterChoices"
                    v-model="housing.ready_quarter"
                    label="Квартал сдачи дома *"
                    clearable
                ></v-select>

                <v-text-field
                    v-model="housing.building_phase"
                    label="Очередь строительства"
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model="housing.building_section"
                    label="Корпус дома"
                    clearable
                    placeholder="Возможные значения: «корпус 1», «корпус А», «дом 3» и т. п."
                ></v-text-field>

                <v-text-field
                    v-model.number="housing.ceiling_height"
                    type="number"
                    label="Высота потолков в метрах"
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model.number="housing.latitude"
                    type="number"
                    label="Географическая широта *"
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model.number="housing.longitude"
                    type="number"
                    label="Географическая долгота *"
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model.number="housing.cian_business_id"
                    type="number"
                    label="ID ТЦ/БЦ из базы ЦИАН"
                    v-if="housing.building_type == 'commercial_building'"
                    clearable
                ></v-text-field>

                <v-card flat>
                    <v-tabs
                        v-model='activeTab'
                        :slider-color='$store.getters.uiAccent'
                        :color='$store.getters.uiAccent'
                        grow
                        show-arrows
                        input='boundTabEvent'
                        :value='activeTab'
                    >

                        <v-tab key='0' ripple>Яндекс</v-tab>
                        <v-tab key='1' ripple>Авито</v-tab>
                        <v-tab key='2' ripple>ЦИАН</v-tab>
                    </v-tabs>
                </v-card>

                <v-tabs-items v-model='activeTab' v-if="visibility">
                    <v-tab-item key="0">
                        <br>

                        <v-autocomplete
                            :items="ya.housings"
                            v-model="housing.yandex_id"
                            label="Идентификатор корпуса жилого комплекса в базе Яндекса *"
                            :loading="isLoadingYandex"
                            :filter="filterHousing"
                            clearable
                        >
                            <template slot='append-outer'>
                                <v-btn small icon @click="getYAHousings">
                                    <v-icon>mdi-reload</v-icon>
                                </v-btn>
                            </template>
                        </v-autocomplete>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="housing.yandex.lift"
                            clearable
                            label="Наличие лифта"
                        ></v-select>

                        <template v-if="housing.building_type == 'new_building'">



                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="housing.yandex.rubbish_chute"
                                clearable
                                label="Наличие мусоропровода"
                            ></v-select>

                        </template>

                        <template v-if="housing.building_type == 'commercial_building'">
                            <v-select
                                :items="ya.officeClasses"
                                v-model="housing.yandex.office_class"
                                label="Класс бизнес-центра"
                                clearable
                            ></v-select>

                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="housing.yandex.access_control_system"
                                label="Наличие пропускной системы"
                                clearable
                            ></v-select>


                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="housing.yandex.security"
                                label="Наличие охраны"
                                clearable
                            ></v-select>

                        </template>

                        <label>
                            Ближайшая станция метро
                            <v-btn depressed x-small @click="addYandexMetro">
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </label>
                        <v-container
                            v-for="(m, i) in housing.yandex.metro_list"
                            :key='i'
                        >
                            <v-text-field
                                v-model="m.name"
                                label="Название станции метро"
                            >
                                <template slot="append-outer">
                                    <v-icon @click="removeYandexMetro(i)">mdi-minus</v-icon>
                                </template>

                            </v-text-field>
                            <v-text-field
                                v-model.number="m.time_on_transport"
                                type="number"
                                label="Время до метро в минутах на транспорте"
                            ></v-text-field>
                            <v-text-field
                                v-model.number="m.time_on_foot"
                                type="number"
                                label="Время до метро в минутах пешком"
                            ></v-text-field>
                        </v-container>

                    </v-tab-item>
                    <v-tab-item key="1">
                        <br>
                        <template v-if="housing.building_type == 'new_building'">
                            <v-autocomplete
                                :items="avito.housings"
                                v-model="housing.avito_id"
                                label="Объект новостройки из справочника Avito *"
                                :loading="isLoadingAvito"
                                :filter="filterHousing"
                                clearable
                            >
                                <template slot='append-outer'>
                                    <v-btn small icon @click="getAvitoHousings">
                                        <v-icon>mdi-reload</v-icon>
                                    </v-btn>
                                </template>
                            </v-autocomplete>

                            <v-select
                                :items="avito.houseTypeChoices"
                                v-model="housing.avito.house_type"
                                :rules="[rules.required]"
                                clearable
                                label="Тип дома *"
                            ></v-select>

                            <v-select
                                :items="avito.passengerElevatorChoices"
                                v-model="housing.avito.passenger_elevator"
                                clearable
                                label="Пассажирский лифт"
                            ></v-select>

                            <v-select
                                :items="avito.freightElevatorChoices"
                                v-model="housing.avito.freight_elevator"
                                clearable
                                label="Грузовой лифт"
                            ></v-select>

                        </template>

                        <template v-if="housing.building_type == 'commercial_building'">
                            <v-select
                                :items="avito.objectTypeChoices"
                                v-model="housing.avito.object_type"
                                :rules="[rules.required]"
                                clearable
                                label="Вид объекта *"
                                @change="getAdditionalObjects"
                            ></v-select>

                            <v-select
                                :items="avito.buildingClassChoices"
                                v-model="housing.avito.building_class"
                                clearable
                                label="Класс здания"
                                v-if="isSetable"
                            ></v-select>

                            <v-select
                                :items="avito._additional"
                                v-model="housing.avito.additional_object_type"
                                clearable
                                multiple
                                max-selected="2"
                                label="Вид объекта - дополнительные варианты"
                            ></v-select>


                        </template>
                    </v-tab-item>
                    <v-tab-item key="2">
                        <br>

                        <v-autocomplete
                            :items="cian.housings"
                            v-model="housing.cian_id"
                            label="ID Корпус ЖК в базе CIAN *"
                            :loading="isLoadingCian"
                            :filter="filterHousing"
                            clearable
                        >
                            <template slot='append-outer'>
                                <v-btn small icon @click="getCianHousings">
                                    <v-icon>mdi-reload</v-icon>
                                </v-btn>
                            </template>
                        </v-autocomplete>

                        <!-- Новостройка -->
                        <template v-if="isNewBuildingFlatSale()">
                            <v-text-field
                                v-model.number="housing.cian.passenger_lifts_count"
                                type="number"
                                label="Количество пассажирских лифтов"
                                clearable
                            ></v-text-field>

                            <v-text-field
                                v-model.number="housing.cian.cargo_lifts_count"
                                type="number"
                                label="Количество грузовых лифтов"
                                clearable
                            ></v-text-field>

                            <v-text-field
                                v-model="housing.cian.action_id"
                                label="Акция ЖК"
                                clearable
                            ></v-text-field>

                        </template>
                        <!-- Новостройка -->

                        <!-- Офис -->
                        <template v-if="isOfficeSale()">

                            <v-select
                                :items="cian.types"
                                v-model="housing.cian.type"
                                clearable
                                label="Тип здания"
                            ></v-select>

                            <v-select
                                :items="cian.classTypes"
                                v-model="housing.cian.class_type"
                                clearable
                                label="Класс"
                            ></v-select>

                            <v-select
                                :items="cian.ventilationTypes"
                                v-model="housing.cian.ventilation_type"
                                clearable
                                label="Вентиляция"
                            ></v-select>
                            <v-select
                                :items="cian.conditioningTypes"
                                v-model="housing.cian.conditioning_type"
                                clearable
                                label="Кондиционирование"
                            ></v-select>

                            <v-select
                                :items="cian.statusTypes"
                                v-model="housing.cian.status_type"
                                clearable
                                label="Категория"
                            ></v-select>

                            <v-select
                                :items="cian.accessTypes"
                                v-model="housing.cian.access_type"
                                clearable
                                label="Вход"
                            ></v-select>

                            <v-autocomplete
                                :items="cian.infrastructures"
                                v-model="housing.cian.infrastructures"
                                clearable
                                multiple
                                label="Инфраструктура"
                            ></v-autocomplete>

                        </template>
                        <!-- Офис -->

                        <!-- Помещение свободного значения -->

                        <template v-if="isFreeAppointmentObjectSale">
                            <v-select
                                :items="cian.types"
                                v-model="housing.cian.type"
                                clearable
                                label="Тип здания"
                            ></v-select>

                            <v-select
                                :items="cian.classTypes"
                                v-model="housing.cian.class_type"
                                clearable
                                label="Класс"
                            ></v-select>

                            <v-select
                                :items="cian.statusTypes"
                                v-model="housing.cian.status_type"
                                clearable
                                label="Категория"
                            ></v-select>

                            <v-select
                                :items="cian.inputTypes"
                                v-model="housing.cian.input_type"
                                clearable
                                label="Вход"
                            >

                            </v-select>

                            <v-autocomplete
                                :items="cian.infrastructures"
                                v-model="housing.cian.infrastructures"
                                clearable
                                multiple
                                label="Инфраструктура"
                            ></v-autocomplete>

                        </template>

                        <!-- Помещение свободного значения -->

                        <p>
                            Список метро (не более 3-х)
                            <v-btn depressed x-small @click="addCianMetro">
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </p>
                        <v-container
                            v-for="(m, i) in housing.cian.metros"
                            :key="'metro_' + i"
                        >
                            <v-autocomplete
                                :items="cian.metroTransportTypeChoices"
                                v-model="m.transport_type"
                                label="Способ передвижения до метро"
                            >
                                <template slot="append-outer">
                                    <v-icon @click="removeCianMetro(i)">mdi-minus</v-icon>
                                </template>
                            </v-autocomplete>

                            <v-text-field
                                v-model.number="m.time"
                                type="number"
                                label="Время в пути в минутах до метро"
                            ></v-text-field>

                            <v-autocomplete
                                :items="cian.metros"
                                v-model="m.id"
                                item-value="id"
                                item-text="name"
                                label="Станция метро"
                            ></v-autocomplete>
                        </v-container>

                    </v-tab-item>

                </v-tabs-items>

            </v-form>

        </template>

    </dialog-fullscreen>
</template>

<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue'
    import yaVariables from "../../../store/yaVariables";
    import cianVariables from "../../../store/cianVariables";
    import avitoVariables from "../../../store/avitoVariables";

    export default {
        name: 'HousingUpdateDialog',
        props: ['initialState', 'data'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                visibility: this.initialState,
                indicator: false,
                valid: false,
                housing: this.data,
                isLoadingYandex: false,
                isLoadingCian: false,
                isLoadingAvito: false,
                activeTab: 0,
                rules: {
                    required: value => !!value || 'Обязательное поле.',
                    email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный электронный адрес',
                    maxLength: v => v && v.length <= 40 || 'Макс 40 символов',
                    descriptionMaxLength: v => v && v.length <= 5000 || 'Макс 5000 символов',
                },
                ya: {
                    housings: [],
                    ...yaVariables.ya
                },
                cian: {
                    housings: [],
                    metros: [],
                    ...cianVariables.cian
                },
                avito: {
                    housings: [],
                    ...avitoVariables.avito
                }
            }
        },
        methods: {
            isNewBuildingFlatSale() {
                return this.housing.building_category == 'newBuildingFlatSale';
            },
            isOfficeSale() {
                return this.housing.building_category == 'officeSale';
            },
            isShoppingAreaSale() {
                return this.housing.building_category == 'shoppingAreaSale';
            },
            isFreeAppointmentObjectSale() {
                return this.housing.building_category == 'freeAppointmentObjectSale';
            },
            addYandexMetro() {
                if (!Array.isArray(this.housing.yandex.metro_list)) {
                    this.housing.yandex.metro_list = []
                }
                this.housing.yandex.metro_list.push(
                    {
                        name: null,
                        time_on_foot: null,
                        time_on_transport: null
                    }
                )
            },
            removeYandexMetro(index) {
                this.housing.yandex.metro_list.splice(index, 1)
            },
            addCianMetro() {
                if (this.housing.cian.metros.length < 3) {
                    this.housing.cian.metros.push(
                        {
                            transport_type: null,
                            time: null,
                            id: null
                        }
                    )
                }
            },
            removeCianMetro(index) {
                this.housing.cian.metros.splice(index, 1)
            },
            getCianMetros() {
                this.$http.get(
                    this.$store.getters.endpoint + '/cian/metros', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin,
                        },
                        headers: this.$store.state.restrictedHeaders
                    },
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.cian.metros = response.data
                    } else {
                        console.log(response)
                    }
                })
            },
            getYAHousings() {
                this.ya.housings = [];
                this.isLoadingYandex = true;
                this.$http.get(
                    this.$store.getters.endpoint + '/yandex/housings', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin,
                            'building_id': this.housing.yandex_building_id
                        },
                        headers: this.$store.state.restrictedHeaders
                    },
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.ya.housings = response.data;

                    } else {
                        console.log(response)
                    }
                }).finally(() => {
                    this.isLoadingYandex = false;
                })
            },
            getAvitoHousings() {
                this.avito.housings = [];
                this.isLoadingAvito = true;
                this.$http.get(
                    this.$store.getters.endpoint + '/avito/housings', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin,
                            'building_id': this.housing.avito_building_id
                        },
                        headers: this.$store.state.restrictedHeaders
                    },
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.avito.housings = response.data;

                    } else {
                        console.log(response)
                    }
                }).finally(() => {
                    this.isLoadingAvito = false;
                })
            },
            getCianHousings() {
                this.cian.housings = [];
                this.isLoadingCian = true;
                this.$http.get(
                    this.$store.getters.endpoint + '/cian/housings', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin,
                            'building_id': this.housing.cian_building_id
                        },
                        headers: this.$store.state.restrictedHeaders
                    },
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.cian.housings = response.data;

                    } else {
                        console.log(response)
                    }
                }).finally(() => {
                    this.isLoadingCian = false;
                })
            },
            getAdditionalObjects() {
                if (this.housing.avito.object_type) {
                    const object_type = this.avito.objectTypeChoices.find(el => el.value === this.housing.avito.object_type);
                    this.avito._additional = this.avito.additionalObjectTypeChoices[object_type.typeId]
                } else {
                    this.avito._additional = []
                }
                this.housing.avito.additional_object_type = null;
            },
            filterHousing(item, queryText, itemText) {
                return (item.text.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
                    item.value.toString().toLocaleLowerCase().includes(queryText.toLocaleLowerCase()))
            },
            updateHousing() {
                // Object.keys(this.housing.yandex).forEach((k, i) => {
                //     if (!this.housing.yandex[k] && this.housing.yandex[k] !== 0) {
                //         delete this.housing.yandex[k]
                //     }
                // });
                // Object.keys(this.housing.avito).forEach((k, i) => {
                //     if (!this.housing.avito[k] && this.housing.avito[k] !== 0) {
                //         delete this.housing.avito[k]
                //     }
                // });
                // Object.keys(this.housing.cian).forEach((k, i) => {
                //     if (!this.housing.cian[k] && this.housing.cian[k] !== 0) {
                //         delete this.housing.cian[k]
                //     }
                // });
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin,
                }

                this.$http.put(this.$store.getters.endpoint + `/housings`, {
                    ...requestData,
                    ...this.housing
                }, {
                    headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                }).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.$emit('requestUpdateReportsList', 'requestUpdateReportsList');
                        this.visibility = false;
                    } else {
                        console.log(response)
                    }
                })
            }
        },
        watch: {
            initialState(value) {
                if (value) {
                    this.visibility = value;
                }
            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
            data(value) {
                this.housing = value;
                this.getCianMetros();
                if (this.housing.building_type == 'new_building') {
                    this.getYAHousings();
                    this.getAvitoHousings();
                    this.getCianHousings();
                }
            },
        },
        computed: {
            isSetable() {
                return this.housing.avito.object_type && ["Офисное помещение", "Складское помещение"].includes(this.housing.avito.object_type)
            }
        }
    }
</script>
