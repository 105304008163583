<template>
    <div>
        <div class="d-flex flex-row justify-space-between align-center">

            <div class="d-flex flex-row align-center">

<!--                <v-text-field-->
<!--                    label="Search Table"-->
<!--                    v-model="searchValue"-->
<!--                    append-icon="mdi-magnify"-->
<!--                ></v-text-field>-->

                <v-menu
                    :close-on-content-click="false"
                    :nudge-width="600"
                    v-model="showFilterMenu"
                    offset-x
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: onTooltip }">
                                <v-badge
                                    :value="filtersEnabledCount > 0"
                                    color="accent"
                                    :content="filtersEnabledCount"
                                    overlap
                                >
                                    <div v-on="onTooltip">
                                        <v-btn
                                            v-on="onMenu"
                                            fab
                                            x-small
                                        >
                                            <v-icon>mdi-filter</v-icon>
                                        </v-btn>
                                    </div>
                                </v-badge>
                            </template>
                            <span v-if="loading">Загрузка</span>
                            <div v-else>
                                <span>Фильтрация данных</span>
                            </div>
                        </v-tooltip>
                    </template>

                    <v-card>
                        <v-card-title>
                            Фильтрация данных
                            <v-spacer></v-spacer>
                            <v-btn small dark @click="clearFilters">
                                <v-icon left>mdi-close</v-icon>
                                Очистить фильтры
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-subtitle v-if="!hasFilters">
                            No filters enabled
                        </v-card-subtitle>
                        <v-card-text>
                            <v-list dense flat>

                                <v-list-item
                                    v-for="(f, i) in selectManyFilters"
                                    :key="i"
                                    dense
                                    class="ma-0 pa-0"
                                    style="height: 20px"
                                >
                                    <v-container>
                                        <v-row>

                                            <template v-if="f.name == 'building_name' || f.name == 'external_id'">
                                                <v-col cols="4" style="display: flex; align-items: center;">
                                                    <v-card-text>{{ f.label }}</v-card-text>
                                                </v-col>
                                                <v-col cols="6" style="display: flex; align-items: center;">
                                                    <v-text-field
                                                        dense
                                                        flat
                                                        v-model="f.value"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="1" style="display: flex; align-items: center;">
                                                    <v-btn small depressed @click="clearFilter(i)" :disabled="!(f.value && f.operand)">
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </template>

                                            <template v-else>
                                                <v-col cols="4" style="display: flex; align-items: center;">
                                                    <v-card-text>{{ f.label }}</v-card-text>
                                                </v-col>
                                                <v-col cols="2" style="display: flex; align-items: center;">
                                                    <v-select
                                                        dense
                                                        :items="f.operands"
                                                        v-model="f.operand"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="4" style="display: flex; align-items: center;">
                                                    <v-text-field
                                                        v-if="numberTypeInput(f.name)"
                                                        type="number"
                                                        dense
                                                        flat
                                                        v-model.number="f.value"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="1" style="display: flex; align-items: center;">
                                                    <v-btn small depressed @click="clearFilter(i)" :disabled="!(f.value && f.operand)">
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </template>


                                        </v-row>
                                    </v-container>
                                </v-list-item>

                            </v-list>
                        </v-card-text>

                        <v-card-actions>
                            <v-col class="text-right">
                                <v-btn
                                    small
                                    @click="onChangedFilters"
                                >
                                    Применить фильтры
                                </v-btn>

                            </v-col>

                        </v-card-actions>

                    </v-card>


                </v-menu>

            </div>

        </div>
    </div>
</template>

<style scoped>
    .align-center {
        align-items: center;
    }
    .d-flex {
        display: flex;
    }
    .flex-row {
        flex-direction: row;
    }
    .justify-space-between {
        justify-content: space-between;
    }
    .ma-0 {
        margin: 0;
    }
    .pa-0 {
        padding: 0;
    }
    .mr-1 {
        margin-right: 1em;
    }
</style>

<script>
    export default {
        props: [
            'data',
            'loading',
            'hasFilters',
            'selectManyFilters',
            'filtersEnabledCount'
        ],
        data() {
            return {
                searchValue: '',
                showFilterMenu: null,
            }
        },
        methods: {
            onChangedFilters() {
                this.$emit('onChangedFilters')
            },
            clearFilters() {
                this.$emit('clearFilters')
            },
            clearFilter(e) {
                this.$emit('clearFilter', e)
            },
            numberTypeInput(field) {
                return [
                    'price',
                    'area',
                    'living_space',
                    'kitchen_space',
                    'rooms',
                    'floor',
                    'floors_total',
                    'built_year',
                    'id'
                ].includes(field)
            }
        },
        watch: {
            showFilterMenu(newValue) {
                this.$emit('showFilterMenuChanged', newValue)
            }
        },
    }
</script>
<style></style>
