<template>

    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>Редактирование фида ЦИАН</template>
        <template v-slot:action>
            <v-btn
                text
                color='green darken-2'
                :disabled="!validGeneralInfo"
                @click="saveCian"
            >
                Обновить
            </v-btn>
        </template>

        <template v-slot:content>

            <v-card flat>
                <v-tabs
                    v-model='activeTab'
                    :slider-color='$store.getters.uiAccent'
                    :color='$store.getters.uiAccent'
                    grow
                    show-arrows
                    input='boundTabEvent'
                    :value='activeTab'
                >
                    <v-tab key='0' ripple>Общая информация об объявлении</v-tab>
                    <v-tab key='1' ripple>Информация о квартире</v-tab>
                </v-tabs>
            </v-card>

            <v-tabs-items v-model='activeTab' v-if="visibility">

                <v-form ref="form" style='min-width: 320px; width:600px;' v-model="validGeneralInfo">

                    <v-tab-item key="0">

                        <v-select
                            v-model="feed.cian_feed_format"
                            :items="[{value: 'basic', text: 'Основной фид'}, {value: 'additional', text: 'Дополнительный фид'}]"
                            label="Фид"
                            clearable
                        ></v-select>
                        <br>
                        <v-textarea
                            v-model="feed.cian.description"
                            label="Текст объявления"
                            :rules="[rules.required]"
                            @keydown="changeDinamicKey"
                        ></v-textarea>
                        <div v-if="mKeys">
                              <div class='key-list-item'
                                    v-for='(keyItem, index) in keyItems'
                                    :key='index'
                                    @click="insertKeyInText"
                                    :title=keyItem.text
                                   >
                                           {{ keyItem.value }}
                              </div>
                        </div>


                        <v-text-field
                            v-model="feed.cian.address"
                            label="Адрес объявления"
                            :rules="[rules.required]"
                        ></v-text-field>

                        <v-text-field
                            v-model="feed.cian.cadastral_number"
                            label="Кадастровый номер"
                        ></v-text-field>

                        <v-select
                            v-model="feed.cian.publish_terms"
                            :items="cian.publishTerms"
                            label="Условия размещения объявления"
                            clearable
                        ></v-select>

                        <v-select
                            v-model="feed.cian.sale_type"
                            :items="cian.saleTypes"
                            :rules="[rules.required]"
                            label="Тип продажи"
                            clearable
                        ></v-select>

                        <v-text-field
                            v-model="feed.cian.title"
                            label="Заголовок объявления"
                            clearable
                        ></v-text-field>

                        <v-text-field
                            v-model="feed.cian.layout_photo"
                            label="Изображение планировки"
                        ></v-text-field>

                        <v-text-field
                            v-model="feed.cian.floor_plan_photo"
                            label="Изображение поэтажного плана"
                        ></v-text-field>

                        <v-text-field
                            v-model="feed.cian.videos"
                            label="Ссылка на видео"
                        ></v-text-field>

                        <v-textarea
                            v-model="feed.cian.photos"
                            label="Фотография объекта *"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Ссылки на фотографии<br>
                                    Каждая ссылка на отдельной строчке <br>
                                </div>
                            </v-tooltip>
                        </v-textarea>

                        <p>
                            Телефоны
                            <v-btn depressed x-small @click="addPhone">
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </p>
                        <v-container
                            v-for="(p, i) in feed.cian.phones"
                            :key="'phone_' + i"
                        >
                            <v-text-field
                                v-model="p.country_code"
                                label="Код страны"
                            >
                                <template slot="append-outer">
                                    <v-icon @click="removePhone(i)">mdi-minus</v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                v-model="p.number"
                                label="Номер"
                            ></v-text-field>
                        </v-container>
                    </v-tab-item>

                    <v-tab-item key="1">


                        <v-text-field
                            v-model="feed.cian.flat_number"
                            clearable
                            label="Номер на площадке"
                        ></v-text-field>

                        <v-text-field
                            v-model="feed.cian.section_number"
                            clearable
                            label="Номер секции"
                        ></v-text-field>

                        <v-text-field
                            v-model="feed.cian.flat_type"
                            clearable
                            label="Тип квартиры"
                        ></v-text-field>

                        <v-autocomplete
                            :items="cian.roomTypeChoices"
                            v-model="feed.cian.room_type"
                            clearable
                            label="Тип комнаты (комнат)"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="cian.yesNoChoices"
                            v-model="feed.cian.is_apartments"
                            clearable
                            label="Апартаменты"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="cian.yesNoChoices"
                            v-model="feed.cian.is_penthouse"
                            clearable
                            label="Пентхаус"
                        ></v-autocomplete>

                        <v-text-field
                            v-model.number="feed.cian.loggias_count"
                            clearable
                            type="number"
                            label="Количество лоджий"
                        ></v-text-field>

                        <v-text-field
                            v-model.number="feed.cian.balconies_count"
                            clearable
                            type="number"
                            label="Количество балконов"
                        ></v-text-field>

                        <v-autocomplete
                            :items="cian.decorations"
                            v-model="feed.cian.decoration"
                            clearable
                            label="Отделка"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="cian.windowsViews"
                            v-model="feed.cian.windows_view_type"
                            clearable
                            label="Куда выходят окна"
                        ></v-autocomplete>

                        <v-text-field
                            v-model.number="feed.cian.separate_wcs_count"
                            clearable
                            type="number"
                            label="Количество раздельных санузлов"
                        ></v-text-field>

                        <v-text-field
                            v-model.number="feed.cian.combined_wcs_count"
                            clearable
                            type="number"
                            label="Количество совместных санузлов"
                        ></v-text-field>

                    </v-tab-item>

                </v-form>


            </v-tabs-items>

        </template>

    </dialog-fullscreen>

</template>
<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue';
    import cianVariables from "../../../store/cianVariables";
    import feedVariables from "../../../store/feedVariables";

    export default {
        name: 'CianNewBuildingUpdateDialog',
        props: ['initialState', 'feed'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                valid: false,
                indicator: false,
                activeTab: 0,
                isLoading: false,
                visibility: this.initialState,
                validGeneralInfo: false,
                mKeys: false,
                feedVariables: feedVariables,
                keyItems: [],
                currentCursorPosition: 0,
                rules: {
                    required: value => !!value || 'Обязательное поле.',
                    email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный электронный адрес',
                    maxLength: v => v && v.length <= 40 || 'Макс 40 символов',
                    descriptionMaxLength: v => v && v.length <= 5000 || 'Макс 5000 символов',
                },
                cian: {
                    metros: [],
                    buildings: [],
                    ...cianVariables.cian
                }
            }
        },
        methods: {
            saveCian() {
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin,
                }
                this.$http.put(this.$store.getters.endpoint + `/feeds/${this.feed.id}/cian`, {
                    ...requestData,
                    cian: this.feed.cian,
                    cian_feed_format: this.feed.cian_feed_format
                }, {
                    headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                }).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        console.log(response)
                        this.visibility = false;
                    } else {
                        console.log(response)
                    }
                })
            },
            getCianBuildings() {
                this.isLoading = true;
                this.cian.buildings = [];
                delete this.feed.cian.cian_jk;
                this.$http.get(
                    this.$store.getters.endpoint + '/cian/buildings', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin,
                            'building_name': this.feed.building_name,
                        },
                        headers: this.$store.state.restrictedHeaders
                    },
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.cian.buildings = response.data
                        this.isLoading = false;
                    } else {
                        console.log(response)
                    }
                })
            },
            addPhone() {
                this.feed.cian.phones.push(
                    {
                        country_code: '',
                        number: ''
                    }
                )
            },
            removePhone(index) {
                this.feed.cian.phones.splice(index, 1)
            },
            changeDinamicKey(key) {
                let text_description = this.feed.cian.description
                this.currentCursorPosition = event.path[0].selectionStart
                // console.log(feedVariables)
                if (key.keyCode == 192) {
                    if (!this.mKeys) {
                        this.mKeys = true

                    } else {
                        this.mKeys = false
                    }
                }

                /* if (key.keyCode == 40 || key.keyCode == 38) {
                    if (this.mKeys) {
                        console.log(event.path[0].selectionStart)
                        event.preventDefault();
                        return false;
                    }
                    if (key.keyCode == 40) {

                    }
                } */

                this.keyItems = feedVariables.replaceKeys;
            },
            insertKeyInText(e) {
                // selected_key = e.target.textContent.trim()
                let F = this.feed.cian.description.substr(0, this.currentCursorPosition)
                let L = this.feed.cian.description.substr(this.currentCursorPosition + 1)
                this.feed.cian.description = F + e.target.textContent.trim() + L

                this.mKeys = false
            }
        },
        watch: {
            initialState(value) {
                if (value) {
                    this.visibility = value;
                }

            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
        },
    }
</script>

<style>
    .key-list-item {
        cursor: pointer
    }
</style>