export default {
    options: [
        {
            label: 'ЖК',
            name: 'building_name',
            value: null,
            selected: false,
            operand: '7',
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Внешний ID',
            name: 'external_id',
            value: null,
            selected: false,
            operand: '7',
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
                {
                    value: '7',
                    text: 'like'
                },
            ]

        },
        {
            label: 'ID',
            name: 'id',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Цена',
            name: 'price',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Общая площадь',
            name: 'area',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Жилая площадь',
            name: 'living_space',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Площадь кухни',
            name: 'kitchen_space',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Комнатность',
            name: 'rooms',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Этаж',
            name: 'floor',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Этажность',
            name: 'floors_total',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Год сдачи',
            name: 'built_year',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },

        // {
        //     label: 'Жилая площадь',
        //     name: 'living_space',
        //     value: null,
        //     selected: false,
        //     operand: null,
        //     operands: [
        //         {
        //             value: '1',
        //             text: '='
        //         },
        //         {
        //             value: '2',
        //             text: '>'
        //         },
        //         {
        //             value: '3',
        //             text: '<'
        //         },
        //         {
        //             value: '5',
        //             text: '>='
        //         },
        //         {
        //             value: '6',
        //             text: '<='
        //         },
        //     ]
        // },
    ]
}
