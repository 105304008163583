<template>

    <v-container
        tag="section"
        justify-center
        fill-height app v-show='!$store.getters.objectsProgress'
        class='py-0'
    >

        <v-layout align-center justify-center>

            <v-scale-transition key='0' mode='in-out' leave-absolute v-show='social'>


                <v-card
                    v-if='social'
                    tile flat
                    class='px-4 py-2 grey lighten-4' justify-center
                    style='min-height: 40%; min-width: 35%; display: flex; flex-direction: column; justify-content: flex-start; border-radius: 4px; box-shadow: 0px 0px 60px 4px rgba(175,175,175,0.5);'
                >
                    <v-card-title class="headline text-center" style='outline: 0; user-select: none; margin: 0 auto;'>
                        Авторизация в системе
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style='margin: auto;'>
                        <v-layout column d-flex>
                            <v-btn
                                :loading='$store.getters.logProgress'
                                class='font-weight-regular ma-0'
                                :disabled='$store.state.brandingOption != "artics"'
                                block outlined large
                                color='info'
                                @click.prevent="yandexPopup"
                                id='google_user_activator'
                            >
                                <!--                                <v-icon left>$vuetify.icons.gcolorized</v-icon>-->
                                <!--                                <span style='line-height: 0 !important;'>Войти через Google</span>-->
                                <svg
                                    fill="#FFF"
                                    width=24
                                    height=34
                                    viewBox="0 0 34 34"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g id="surface1">
                                        <path
                                            style='stroke: none; fillRule: nonzero; fill: rgb(98.823529%,24.705882%,11.372549%); fillOpacity: 1'

                                            d="M 33.964844 17 C 33.964844 26.371094 26.371094 33.964844 17 33.964844 C 7.628906 33.964844 0.0351562 26.371094 0.0351562 17 C 0.0351562 7.628906 7.628906 0.0351562 17 0.0351562 C 26.371094 0.0351562 33.964844 7.628906 33.964844 17 Z M 33.964844 17 "
                                        />
                                        <path
                                            style='stroke: none; fillRule: nonzero; fill: rgb(100%,100%,100%);fillOpacity: 1'
                                            d="M 23.140625 27.648438 L 19.414062 27.648438 L 19.414062 9.230469 L 17.753906 9.230469 C 14.714844 9.230469 13.121094 10.75 13.121094 13.019531 C 13.121094 15.597656 14.21875 16.789062 16.492188 18.308594 L 18.367188 19.570312 L 12.984375 27.648438 L 8.980469 27.648438 L 13.824219 20.4375 C 11.039062 18.449219 9.46875 16.503906 9.46875 13.230469 C 9.46875 9.132812 12.324219 6.347656 17.730469 6.347656 L 23.113281 6.347656 L 23.113281 27.644531 L 23.140625 27.644531 Z M 23.140625 27.648438 "
                                        />
                                    </g>
                                </svg>
                                <span style='line-height: 0 !important;'>Войти через Yandex</span>
                            </v-btn>
                            <!--                    <v-btn class = 'font-weight-regular mt-1' block depressed @click='social=!social'>Войти через Логин/Пароль</v-btn>-->
                        </v-layout>
                    </v-card-text>
                </v-card>

            </v-scale-transition>

        </v-layout>
        <v-alert
            :value='$store.state.errors.login.email.indicator'
            dismissible
            transition="scale-transition"
            type='error'
            class='my-1 mx-auto'
            style='position: absolute; bottom: 0;'
        >
            Пользователь с почтой <b>{{auth.login}}</b> не найден
        </v-alert>
        <v-alert
            :value='$store.state.errors.login.yandex.indicator'
            dismissible
            transition="scale-transition"
            type='error'
            class='my-1 mx-auto'
            style='position: absolute; bottom: 0;'
        >
            {{ $store.state.errors.login.yandex.message }}
        </v-alert>
    </v-container>

</template>


<script>
    import GoogleIcon from '@/components/elements/misc/GoogleIcon.vue';
    import router from '@/router'


    export default {
        data() {
            return {
                showPassword: false,
                social: false,
                auth: {'login': '', 'password': '', 'persistance': true}
            }
        },
        components: {
            'gicon': GoogleIcon
        },
        methods: {
            attachSignIn(element, handler) {
                handler.attachClickHandler(element, {}, (gUser) => {
                    let basicProfile = gUser.getBasicProfile();
                    let googleFullInfoUser = {
                        'id': basicProfile.getId(),
                        'fullName': basicProfile.getName(),
                        'givenName': basicProfile.getGivenName(),
                        'familyName': basicProfile.getFamilyName(),
                        'profilePhoto': basicProfile.getImageUrl(),
                        'email': basicProfile.getEmail(),
                        'persistance': true,
                        'type': 'Google'
                    }
                    document.getElementById('google_user_activator').classList.add('v-btn--active');
                    this.auth = {login: googleFullInfoUser.email, password: '', persistance: true}
                    this.$store.dispatch('authorizeUser', {flow: 'social', user: googleFullInfoUser})
                    window.g_auth2.disconnect();
                }, (e) => {
                    if (e.error != 'popup_closed_by_user') {
                        console.log(JSON.stringify(e, undefined, 2));
                    }
                });
            },
            dispatchGoogleAuth() {
                if (typeof gapi == 'object' && gapi.hasOwnProperty('auth2') && !!document.getElementById('google_user_activator')) {
                    window.g_auth2 = gapi.auth2.init({
                        client_id: '136450845049-j39ea8obolprlpquuca75d5ivgjpnfls.apps.googleusercontent.com'
                    });
                    this.attachSignIn(document.getElementById('google_user_activator'), window.g_auth2)
                } else {
                    window.setTimeout(this.dispatchGoogleAuth, 200);
                }
            },
            getCurrentUrl() {
                if (process.env.hasOwnProperty("VUE_APP_FEED_UI_URL"))
                  return process.env.VUE_APP_FEED_UI_URL
                if (typeof window !== "undefined") {
                    let currentUrl = window.location.href;

                    if (currentUrl[currentUrl.length - 1] === "/") {
                        currentUrl = currentUrl.slice(0, currentUrl.length - 1);
                    }
                    if (currentUrl.includes('?')) {
                        currentUrl = currentUrl.split('?')[0] + '/'
                    }
                    return currentUrl;
                }
            },
            getYandexAuthUrl(clientId, redirectUrl) {
                let requestUrl = "https://oauth.yandex.ru/authorize?response_type=token&client_id=" + clientId;
                requestUrl += "&redirect_uri=" + encodeURIComponent(redirectUrl);

                requestUrl += "&display=popup";
                // requestUrl += "&force_confirm=yes";
                return requestUrl;
            },

            yandexPopup() {

                let requestUrl = this.getYandexAuthUrl(
                    "3a0d7e71f7024d7d8e2ff79609aaab6a",
                    this.getCurrentUrl()
                );
                window.location.href = requestUrl;
            },
            checkWindow() {
                if (window.location.href.includes('access_token')) {
                    let parts = window.location.href.split("?");
                    let queryPartUrl =
                        parts.length > 1 && parts[1] !== "frame" ? parts[1] : null;
                    if (!queryPartUrl) {
                        return null;
                    }

                    let result = {};
                    queryPartUrl = decodeURIComponent(queryPartUrl);

                    let partsUrl = queryPartUrl.split("#")
                    partsUrl[1].split("&").forEach(function (part) {
                        let item = part.split("=");
                        result[item[0]] = decodeURIComponent(item[1]);
                    });
                    if (result.hasOwnProperty('access_token')) {
                        this.$http.post(
                            this.$store.getters.authHandler + '/yandex-login',
                            {},
                            {headers: {'Authorization': `OAuth ${result.access_token}`}}
                        ).then(o => {
                            let yandexUser = o.data.user;
                            let user = {
                                email: yandexUser.default_email,
                                familyName: yandexUser.last_name,
                                fullName: yandexUser.real_name,
                                givenName: yandexUser.first_name,
                                id: yandexUser.id,
                                persistance: true,
                                profilePhoto: `https://avatars.mds.yandex.net/get-yapic/${yandexUser.default_avatar_id}/islands-200`,
                                type: "Yandex"
                            };
                            this.auth = {login: user.email, password: '', persistance: true};
                            this.$store.dispatch('authorizeUser', {flow: 'social', user: user})
                        }).catch(r => {
                            this.$store.commit('authYandexError', 'Ошибка при аутентификации пользователя. Попробуйте позже')
                            router.push({'path': '/auth', 'query': {'redirect': '/'}})
                        })
                    }
                }
            }
        },
        watch: {
            social(value) {
                if (value) {
                    this.dispatchGoogleAuth()
                }
            }
        },
        mounted: function () {
            this.$store.commit('disablePreloader');
            // this.dispatchGoogleAuth();
            this.checkWindow();
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.$store.commit('disablePreloader');
            });
        }
    }
</script>
