<template>
    <v-layout>

        <v-dialog v-model='visibility' fullscreen hide-overlay transition="dialog-bottom-transition" persistent
                  @keydown='closeOnEvent'>
            <v-card flat>
                <v-toolbar flat dense :color='$store.getters.appTheme.dialogHeader'>
                    <v-toolbar-items>
                        <v-btn icon @click='closeDialog'>
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                    <v-toolbar-title class="title font-weight-regular ml-2">
                        <slot name='title'>Dialog Title</slot>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <slot name='action'>
                            <v-btn block text disabled class='px-0'>-</v-btn>
                        </slot>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider :color='$store.getters.uiAccent' v-if='!indicator'></v-divider>
                <v-progress-linear
                    :color='$store.getters.uiAccent'
                    height='1' class='py-0 my-0'
                    :active='indicator'
                    indeterminate
                ></v-progress-linear>
                <v-card-text class='text-center my-0 py-1 px-2'>
                    <v-layout column align-center my-2>
                        <slot name='content'>
                            <span>Default Dialog Content</span>
                        </slot>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-layout>
</template>

<script>
    export default {
        name: 'DialogFullscreen',
        props: ['indicator', 'visibility'],
        methods: {
            closeDialog() {
                this.$emit('update:visibility', false)
            },
            closeOnEvent(e) {
                if (e.code == 'Escape') {
                    this.closeDialog()
                }
            }
        }
    }
</script>