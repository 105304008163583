export default {
    setBrandingOption(state, data) {
        state.brandingOption = data
    },
    togglePreloader(state) {
        state.progress.retreive = !state.progress.retreive
    },
    disablePreloader(state) {
        state.progress.retreive = false
    },
    clearHeaders(state) {
        state.headerSecondary = '';
    },
    clearCursors(state) {
        state.currentReport = {};
        state.currentProject = {};
    },
    extendBreadcrumbs(state, data) {
        state.breadcrumbs = state.breadcrumbs.concat(Array.isArray(data) ? data : [data])
    },
    setBreadcrumbLevel(state, data) {
        state.breadcrumbs[data['level'] - 1] = data['value'];
    },
    setBreadcrumbs(state, data) {
        state.breadcrumbs = (Array.isArray(data) ? data : [data])
    },
    appendBreadcrumb(state, data) {
        state.breadcrumbs.append(data)
    },
    authHeaders(state, data) {
        state.restrictedHeaders = data
    },
    authErrorEmail(state, data) {
        state.errors.login.email.indicator = true
        state.errors.login.email.message = [data]
    },
    authYandexError(state, data) {
      state.errors.login.yandex.indicator = true;
      state.errors.login.yandex.message = data
    },
    setSourceTypes(state, data) {
        state.sourceTypes = data;
    },
    setDataSourceTypes(state, data) {
        state.dataSourceTypes = data;
    },
    setSegments(state, data) {
        state.segments = data;
    },
    setVerticals(state, data) {
        state.verticals = data;
    },
    authErrorsFlush(state) {
        state.errors.login = {
            password: {
                indicator: false,
                message: []
            },
            email: {
                indicator: false,
                message: []
            }
        }
    },
    authErrorPassword(state, data) {
        state.errors.login.password.indicator = true
        state.errors.login.password.message = [data]
    },
    updateHeaderSecondary(state, data) {
        state.headerSecondary = data;
    },
    modifyUserReportNotes(state, data) {
        let reportsNotes = (state.userData.meta && state.userData.meta.notes && state.userData.meta.notes.reports) || {}
        reportsNotes[data.report_id] = (reportsNotes[data.report_id] || []).filter(n => n.identity != data.note.identity)
        reportsNotes[data.report_id].push(data.note)
        if (state.userData.meta.hasOwnProperty('notes')) {
            state.userData.meta.notes.reports[data.report_id] = reportsNotes[data.report_id]
        } else {
            state.userData.meta.notes = {
                reports: reportsNotes
            }
        }
    },
    updateUserMeta(state, data) {
        state.userData.meta = data
    },
    addToFavorites(state, data) {
        state.userData.meta = state.userData.meta || {favorites: []}
        state.userData.meta.favorites.push(data.report_id);
    },
    delFromFavorites(state, data) {
        state.userData.meta.favorites = state.userData.meta.favorites.filter(id => id != data.report_id)
    },
    toggleUserTheme(state) {
        state.userData.meta = state.userData.meta || {favorites: []}
        state.userData.meta.theme = state.userData.meta.theme == 'dark' ? 'light' : 'dark'
    },
    authorize(state, data) {
        state.isLoggedIn = true;
        state.userData = {...state.userData, ...data};
    },
    authorize2(state, data) {
        state.userData = {...state.userData, ...data};
    },
    updateUserData(state, data) {
        state.userData = {...state.userData, ...data}
    },
    updateUserNotifications(state, data) {
        state.userData.notifications = data;
    },
    exitSystem(state) {
        state.isLoggedIn = false;
        state.userData = {
            meta: {
                theme: 'light'
            }
        }
    },
    updateUserReports(state, data) {
        state.userData.reports = data
    },
    updateUserProjects(state, data) {
        state.userData.projects = data;
    },
    updateUserBuildings(state, data) {
        state.userData.buildings = data;
    },
    updateUserHousings(state, data) {
        state.userData.housings = data;
    },
    updateUserNotes(state, data) {
        state.userData.notes = data
    },
    pushUserNote(state, data) {
        state.userData.notes = state.userData.notes || []
        state.userData.notes.push(data)
    },
    cursorReport(state, data) {
        if (data && state.recentObjects.indexOf(data.report_id) === -1) {
            state.recentObjects.push(data.report_id);
        }
        state.currentReport = data
    },
    cursorProject(state, data) {
        state.currentProject = data
    },
    cursorHousing(state, data) {
        state.currentHousing = data
    }
}
