<template>
    <v-layout column style='flex: 0 0 auto !important;'>

        <template v-for="(p, i) in projects">
            <v-list-group
                :data-index='i'
                :key='i' no-action
                :value.sync="expand[i]"
                :style='`border-bottom: 1px solid;border-color: ${!$vuetify.theme.dark? "#EEEEEE;": "#757575;"}`'
            >
                <template v-slot:activator>

                    <v-list-item style='display: flex; flex-wrap: wrap;' class='mx-0 px-0'>
                        <v-list-item-action class='mr-4' v-if="p.type == 'realty'">
                            <v-btn
                                icon
                                small
                                :to='{name: "project", params: {id: p.id}}'
                                v-if="$store.getters.currentUser.superuser"
                            >
                                <v-icon small>mdi-settings-outline</v-icon>
                            </v-btn>
                        </v-list-item-action>

                        <v-list-item-action class='mr-4' v-if="p.type == 'ecom'">
                            <v-btn
                                icon
                                small
                                :to='{name: "shop", params: {id: p.id}}'
                            >
                                <v-icon small>mdi-settings-outline</v-icon>
                            </v-btn>
                        </v-list-item-action>



                        <v-list-item-content>
                            <v-list-item-title class='px-1 font-weight-light'>{{p.name}}</v-list-item-title>
                        </v-list-item-content>

                        <!-- Редактирование проекта-->
                        <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;' @click.stop="updateProject(p)">
                            <v-btn class='mr-1' small depressed icon>
                                <v-icon small color='light-blue darken-1'>mdi-square-edit-outline</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        <!-- Редактирование проекта-->

                        <!-- Данные импорта данных для проекта-->
                        <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;' @click.stop="importData(p)">
                            <v-btn class='mr-1' small depressed icon title="Импорт данных">
                                <v-icon small color='light-blue darken-1'>mdi-database-plus</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        <!-- Данные импорта данных для проекта-->

                        <!-- Экспорт фида -->
                        <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;' @click.stop="exportData(p)">
                            <v-btn class='mr-1' small depressed icon title="Экспорт данных">
                                <v-icon small color='light-blue darken-1'>mdi-database-plus</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        <!-- Экспорт фида -->

                        <!-- Удаление проекта-->
                        <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;' @click.stop="deleteProject(p)">
                            <v-btn class='mx-auto' small icon title="Удалить проект">
                                <v-icon small :color='$store.getters.uiAccentIcon'>delete_outline</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        <!-- Удаление проекта-->

                        <!-- Добавление ЖК -->
                        <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;' title="Добавить ЖК"
                                            @click.stop="createBuilding(p)" v-if="p.type == 'realty'">
                            <v-btn class='mr-1' small depressed icon>
                                <v-icon small>mdi-plus-outline</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        <!-- Добавление ЖК -->

                    </v-list-item>
                </template>
                <template v-for="(b, bi) in p.buildings">
                    <v-list-group
                        :data-index='bi'
                        :key='bi' no-action
                        sub-group
                        :style='`width: 95.4% !important;border-bottom: 1px solid;border-color: ${!$vuetify.theme.dark? "#EEEEEE;": "#757575;"}`'
                    >
                        <template v-slot:activator>

                            <v-list-item
                                style='display: flex; flex-wrap: wrap;'
                                class='mx-0 px-0'>
                                <v-list-item-action class='mr-4'>
                                    <v-btn
                                        icon
                                        small
                                    >
                                        <v-icon small>mdi-settings-outline</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class='px-1 font-weight-light'>{{b.name}}</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;'
                                                    @click.stop="updateBuilding(b)">
                                    <v-btn class='mr-1' small depressed icon>
                                        <v-icon small color='light-blue darken-1'>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </v-list-item-action>

                                <!-- Экспорт фида по ЖК -->
                                <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;' @click.stop="exportBuildingData(p, b)">
                                    <v-btn class='mr-1' small depressed icon title="Экспорт данных">
                                        <v-icon small color='light-blue darken-1'>mdi-database-plus</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                                <!-- Экспорт фида по ЖК -->

                                <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;' @click.stop="deleteBuilding(b)">
                                    <v-btn class='mx-auto' small icon title="Удалить Жк">
                                        <v-icon small :color='$store.getters.uiAccentIcon'>delete_outline</v-icon>
                                    </v-btn>
                                </v-list-item-action>

                                <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;'
                                                    @click.stop="copyBuilding(b)">
                                    <v-btn class='mx-auto' small icon>
                                        <v-icon small>mdi-content-copy</v-icon>
                                    </v-btn>
                                </v-list-item-action>

                                <!-- Добавление ЖК -->
                                <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;'
                                                    title="Добавить ЖК" @click.stop="createHousing(b)">
                                    <v-btn class='mr-1' small depressed icon>
                                        <v-icon small>mdi-plus-outline</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                                <!-- Добавление ЖК -->
                            </v-list-item>

                        </template>
                        <template>
                            <v-list-item
                                v-for='h in b.housings'
                                :key='h.id'
                                :style='`border-bottom: 1px solid;border-color: ${!$vuetify.theme.dark? "#EEEEEE;": "#757575;"};display: flex; flex-wrap: wrap; width: 92.4%; margin-left: 80px !important;`'
                                class='mx-0 px-0 pl-4'
                            >
                                <v-list-item-action class='mr-4'>
                                    <v-btn
                                        icon
                                        small
                                        :to='{name: "housing", params: {id: h.id}}'
                                    >
                                        <v-icon small>mdi-settings-outline</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class='px-1 font-weight-light'>{{h.name}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;'
                                                    @click.stop="updateHousing(h, b)">
                                    <v-btn class='mr-1' small depressed icon>
                                        <v-icon small color='light-blue darken-1'>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                                <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;' @click.stop="deleteHousing(h)">
                                    <v-btn class='mx-auto' small icon title="Удалить Housings">
                                        <v-icon small :color='$store.getters.uiAccentIcon'>delete_outline</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                                <v-list-item-action class='pa-0 ma-0' style='min-width: auto !important;'
                                                    @click.stop="copyHousing(h)">
                                    <v-btn class='mx-auto' small icon>
                                        <v-icon small>mdi-content-copy</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </template>

                    </v-list-group>

                </template>

            </v-list-group>

        </template>

        <project-import-setting
            :initialState.sync="visibility.importSetting"
            :project="cursorProject"
        ></project-import-setting>

        <project-export-setting
            :initialState.sync="visibility.exportSettings"
            :project="cursorProject"
        ></project-export-setting>

        <building-export-setting
            :initialState.sync="visibility.buildingExportSettings"
            :project="cursorProject"
            :building="cursorBuilding"
        >

        </building-export-setting>

        <building-create
            :initialState.sync="visibility.buildingCreateDialog"
            :projectData="cursorProject"
            @requestUpdateReportsList='passEvent'
        ></building-create>

        <building-update
            :initialState.sync="visibility.buildingUpdateDialog"
            :data="cursorBuilding"
            @requestUpdateReportsList='passEvent'
        ></building-update>

        <housing-create
            :initialState.sync="visibility.housingCreateDialog"
            :buildingData="cursorBuilding"
            @requestUpdateReportsList='passEvent'
        ></housing-create>

        <housing-update
            :initialState.sync="visibility.housingUpdateDialog"
            :data="cursorHousing"
            @requestUpdateReportsList='passEvent'
        ></housing-update>


        <project-update
            :initialState.sync="visibility.update"
            :data="cursorProject"
            @requestUpdateReportsList='passEvent'
        ></project-update>

        <project-delete
            :initialState.sync="visibility.delete"
            :data="cursorProject"
            @requestUpdateReportsList='passEvent'
        ></project-delete>


        <building-delete
            :initialState.sync="visibility.deleteBuildingPopUp"
            :data="cursorBuilding"
            @requestUpdateReportsList='passEvent'
        ></building-delete>

        <housing-delete
            :initialState.sync="visibility.deleteHousingPopUp"
            :data="cursorHousing"
            @requestUpdateReportsList='passEvent'
        ></housing-delete>

    </v-layout>
</template>

<script>
    import ProjectImportSetting from "@/components/entities/import_setting/ImportSettingDialog";
    import ProjectExportSetting from "@/components/entities/export_setting/ExportSettingDialog";
    import BuildingExportSetting from "@/components/entities/export_setting/BuildingExportSettingDialog";

    import ProjectUpdateDialog from '@/components/entities/projects/UpdateDialog.vue';
    import ProjectDeleteDialog from '@/components/entities/projects/DeleteDialog.vue';

    import BuildingUpdateDialog from '@/components/entities/building/UpdateDialog.vue';
    import BuildingCreateDialog from '@/components/entities/building/CreateDialog.vue';
    import BuildingDeleteDialog from '@/components/entities/building/DeleteDialog.vue';

    import HousingUpdateDialog from '@/components/entities/housing/UpdateDialog.vue';
    import HousingCreateDialog from '@/components/entities/housing/CreateDialog.vue';
    import HousingDeleteDialog from '@/components/entities/housing/DeleteDialog.vue';


    export default {
        name: 'ObjectsListGroup',
        props: ['projects'],
        components: {
            'project-update': ProjectUpdateDialog,
            'project-delete': ProjectDeleteDialog,

            'building-update': BuildingUpdateDialog,
            'building-create': BuildingCreateDialog,
            'building-delete': BuildingDeleteDialog,

            'housing-update': HousingUpdateDialog,
            'housing-create': HousingCreateDialog,
            'housing-delete': HousingDeleteDialog,

            'project-import-setting': ProjectImportSetting,
            'project-export-setting': ProjectExportSetting,
            'building-export-setting': BuildingExportSetting,
        },
        data() {
            return {
                visibility: {
                    check: true,
                    update: false,
                    delete: false,
                    deleteBuildingPopUp: false,
                    deleteHousingPopUp: false,
                    buildingUpdateDialog: false,
                    buildingCreateDialog: false,
                    housingUpdateDialog: false,
                    housingCreateDialog: false,
                    buildingExportSettings: false,
                    importSetting: false,
                    exportSettings: false,
                },
                cursorProject: {},
                cursorBuilding: {},
                cursorHousing: {},
                expand: [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ]
            }
        },
        methods: {
            passEvent(e) {
                this.$emit(e, e)
            },
            updateProject(p) {
                this.cursorProject = p;
                this.visibility.update = true;
            },
            importData(p) {
                this.cursorProject = p;
                this.visibility.importSetting = true;
            },
            exportData(p) {
                this.cursorProject = p;
                this.visibility.exportSettings = true;
            },
            exportBuildingData(p, b) {
                this.cursorProject = p;
                this.cursorBuilding = b;
                this.visibility.buildingExportSettings = true;
            },
            deleteProject(p) {
                this.cursorProject = p;
                this.visibility.delete = true;
            },
            deleteBuilding(b) {
                this.cursorBuilding = b;
                this.visibility.deleteBuildingPopUp = true;
            },
            deleteHousing(h) {
                this.cursorHousing = h;
                this.visibility.deleteHousingPopUp = true;
            },
            createObjectCopy(e) {
                return JSON.parse(JSON.stringify(e))
            },
            createBuilding(project) {
                this.cursorProject = this.createObjectCopy(project);
                this.visibility.buildingCreateDialog = true;
            },
            updateBuilding(building) {
                this.cursorBuilding = this.createObjectCopy(building);
                this.visibility.buildingUpdateDialog = true;
            },
            updateHousing(housing, building) {
                this.cursorHousing = this.createObjectCopy(housing);
                this.cursorHousing.yandex_building_id = building.yandex_id;
                this.cursorHousing.avito_building_id = building.avito_id;
                this.cursorHousing.cian_building_id = building.cian_id;
                this.cursorHousing.building_type = building.building_type;
                this.cursorHousing.building_category = building.category;
                this.visibility.housingUpdateDialog = true;
            },
            createHousing(building) {
                this.cursorBuilding = this.createObjectCopy(building);
                this.visibility.housingCreateDialog = true;
            },
            copyBuilding(b) {
                const building = this.createObjectCopy(b);
                building.name = `[КОПИЯ] ${building.name}`;
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin
                }
                this.$http.post(this.$store.getters.endpoint + '/buildings',
                    {...requestData, ...building},
                    {
                        headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                    }).then((r) => {
                    let response = r.body;
                    this.indicator = false;
                    if (response.result) {
                        this.$emit('requestUpdateReportsList', 'requestUpdateReportsList');
                    }
                })
            },
            copyHousing(h) {
                const housing = this.createObjectCopy(h);
                housing.name = `[КОПИЯ] ${housing.name}`;
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin
                }
                this.$http.post(this.$store.getters.endpoint + '/housings',
                    {...requestData, ...housing},
                    {
                        headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                    }).then((r) => {
                    let response = r.body;
                    this.indicator = false;
                    if (response.result) {
                        this.$emit('requestUpdateReportsList', 'requestUpdateReportsList');
                    }
                })
            },
            kuku() {
                console.log('kuku')
            }
        }
    }
</script>
