import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
Vue.use(VueResource)

import getters from './getters'
import mutations from './mutations'
import actions from '@/store/actions'

export default new Vuex.Store({
    state: {
        isLoggedIn: false,
        progress: {
            log: false,
            retreive: true,
        },
        errors: {
            login: {
                password: {
                    indicator: false,
                    message: []
                },
                email: {
                    indicator: false,
                    message: []
                },
                yandex: {
                    indicator: false,
                    message: []
                }
            }
        },
        currentReport: {},
        currentProject: {},
        currentHousing: {},
        userData: {
            meta: {
                favorites: [],
                theme: 'light'
            },
            notifications: [],
            notes: [],
            projects: [],
            housings: [],
            buildings: [],
        },
        favObjects: [],
        recentObjects: [],
        headerSecondary: '',
        restrictedHeaders: {},
        breadcrumbs: [],
        sourceTypes: [],
        sourceTypeProviders: [],
        segments: [],
        verticals: [],
        brandingOption: 'artics',
        theme: {
            dark: {
                shadow: 'rgba(51, 51, 51, 0.5)',
                dialogHeader: 'grey darken-4'
            },
            light: {
                shadow: 'rgba(175,175,175,0.5)',
                dialogHeader: 'grey lighten-4'
            }
        },
        designVariant: {
            artics: {
                //logo: 'artics_num.png',
                logo: 'numbers.artics.svg',
                accentColor: '#C62828',
                accentColorIcon: 'red accent-4',
                upic: '@/assets/userpic_undefined_artics.png',
                email: 'press@artics.ru',
                titles: {
                    main: 'Artics.Фид',
                    objects: 'Artics.Фид'
                }
            },
        },
        routing: {
            version: 'production',
            service: 'db-entities',
            project: 'trying-artics-pipeline',
            deploy: document.location.hostname != 'localhost'
        }
    },
    actions,
    mutations,
    getters,
    plugins: [
        createPersistedState({
            paths: [
                'isLoggedIn',
                'userData.user',
                'userData.objects',
                'userData.projects',
                'userData.housings',
                'userData.buildings',
                'restrictedHeaders',
                'userData.meta',
                'recentObjects',
                'currentProject',
                'currentReport',
                'currentHousing'
            ],
            key: 'articsXML'
        })
    ]
});
