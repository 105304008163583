<template>
  <dialog-fullscreen
    :visibility="initialState"
    @update:visibility="handleClose"
  >
    <template v-slot:title>История изменений</template>
    <template v-slot:content>
      <div class="text-left w-100">
        <div>
          <v-data-table
            :headers="headers"
            :items="values"
            :server-items-length="totalFeeds"
            :items-per-page.sync="perPage"
            :footer-props="footerProps"
            :loading="loading"
            loading-text="Данные загружаются..."
            class="elevation-1"
            disable-sort
            @update:items-per-page="getHistory"
          >
            <template v-slot:item.housing>
              {{ housing || 'Не задано' }}
            </template>
            <template v-slot:item.building>
              {{ building || 'Не задано' }}
            </template>
            <template v-slot:item.date_time="{ item }">
              {{ item.date_time ? new Date(item.date_time).toLocaleString("ru-RU") : '-' }}
            </template>
          </v-data-table>
        </div>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="totalPages"
            :total-visible="20"
            circle
            @input="getHistory"
          ></v-pagination>
        </div>
      </div>
    </template>
  </dialog-fullscreen>
</template>

<script>
import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue'

export default {
  name: 'HistoryDialog',
  components: { 'dialog-fullscreen': DialogFullscreen },
  props: {
    initialState: {
      type: Boolean,
      default: false
    },

    feedId: {
      type: Number,
      default: null
    },

    housingId: {
      type: Number,
      default: null
    },

    login: {
      type: String,
      default: ''
    },

    housing: {
      type: String,
      default: ''
    },

    building: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loading: false,
      footerProps: {
        'items-per-page-options': [20, 50, 100],
        'prev-icon': 'false',
        'next-icon': 'false',
        'items-per-page-text': 'На странице'
      },
      page: 1,
      perPage: 20,
      totalPages: 0,
      totalFeeds: 0,
      values: [],
      headers: [
        { text: 'ID', value: 'feed_id' },
        { text: 'Строение', value: 'building' },
        { text: 'Корпус', value: 'housing' },
        {
          text: 'Дата изменения',
          value: 'date_time'
        },
        { text: 'Пользователь, который сделал изменение', value: 'user_name' },
        { text: 'Измененное поле', value: 'field_name' },
        { text: 'Старое значение', value: 'old_value' },
        { text: 'Новое значение', value: 'new_value' }
      ]
    }
  },

  watch: {
    initialState() {
      if (!this.initialState) return
      this.getHistory()
    }
  },

  methods: {
    async getHistory() {
      try {
        this.loading = true
        const { page, perPage, login } = this
        const requestHeaders = {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: 'Bearer ' + String(this.$store.getters.userToken)
        }
        const params = {
          login,
          page,
          per_page: perPage,
          housing_id: this.housingId,
          feed_id: this.feedId
        }
        const { body } = await this.$http.get(
          this.$store.getters.endpoint + '/lots/history',
          {
            params,
            headers: {
              ...this.$store.state.restrictedHeaders,
              ...requestHeaders
            }
          }
        )
        this.values = body.data.feeds
        this.totalPages = body.data.total_pages
        this.totalFeeds = body.data.total_count
      } catch (e) {
      } finally {
        this.loading = false
      }
    },

    handleClose() {
      this.$emit('close')
      this.page = 1
      this.perPage = 20
      this.totalPages = 0
      this.totalFeeds = 0
      this.values = []
    }
  }
}
</script>

<style>
.v-data-footer__icons-before, .v-data-footer__icons-after {
  display: none;
}
</style>