<template>

    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>Параметры экспорта</template>
        <template v-slot:action>
            <v-btn
                text
                color='green darken-2'
                @click="close"
            >
                Выйти
            </v-btn>
        </template>
        <template v-slot:content>


            <v-container style="width: 800px;" v-if="visibility">
                <v-row>
                    <v-col cols="2">
                        <v-subheader>Яндекс</v-subheader>
                    </v-col>
                    <v-col cols="10">
                        <v-text-field
                            :value="settings.yandex"
                            readonly
                        >
                            <!--                            <template slot="append-outer">-->
                            <!--                                <v-icon @click="copyToClipboard('yandex')">mdi-content-copy</v-icon>-->
                            <!--                            </template>-->
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="2">
                        <v-subheader>Avito</v-subheader>
                    </v-col>
                    <v-col cols="10">
                        <v-text-field
                            :value="settings.avito"
                            readonly
                        >
                            <!--                            <template slot="append-outer">-->
                            <!--                                <v-icon @click="copyToClipboard('avito')">mdi-content-copy</v-icon>-->
                            <!--                            </template>-->
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="2">
                        <v-subheader>ЦИАН</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field
                            :value="settings.cian"
                            readonly
                        >
                            <!--                            <template slot="append-outer">-->
                            <!--                                <v-icon @click="copyToClipboard('cian')">mdi-content-copy</v-icon>-->
                            <!--                            </template>-->
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            :items="cianFeedTypes"
                            v-model="cianFeedType"
                            label="Фид"
                        >

                        </v-select>
                    </v-col>
                </v-row>

                <!--                <v-row>-->
                <!--                    <v-col cols="12">-->
                <!--                        <v-autocomplete-->
                <!--                            v-model="buildingName"-->
                <!--                            :items="buildingNames"-->
                <!--                            label="Название ЖК"-->
                <!--                            clearable-->
                <!--                        ></v-autocomplete>-->
                <!--                    </v-col>-->
                <!--                </v-row>-->


            </v-container>
        </template>
    </dialog-fullscreen>

</template>


<script>

    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue';

    export default {
        name: 'ProjectExportSetting',
        props: ['initialState', 'project' , 'building'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                valid: false,
                indicator: false,
                visibility: this.initialState,
                buildingNames: [],
                buildingName: null,
                cianFeedType: 'all',
                cianFeedTypes: [
                    {
                        value: 'all',
                        text: 'Весь фид'
                    },
                    {
                        value: 'basic',
                        text: 'Основной фид'
                    },
                    {
                        value: 'additional',
                        text: 'Дополнительный фид'
                    },
                ],
                settings: {
                    'cian': `${this.$store.getters.endpoint}/project/${this.project.id}/building/${this.building.id}/export_to_cian`,
                    'yandex': `${this.$store.getters.endpoint}/project/${this.project.id}/building/${this.building.id}/export_to_yandex`,
                    'avito': `${this.$store.getters.endpoint}/project/${this.project.id}/building/${this.building.id}/export_to_avito`,
                }
            }
        },
        methods: {
            onCopy: function (e) {
                alert('You just copied: ' + e.text)
            },
            onError: function (e) {
                alert('Failed to copy texts')
            },
            copyToClipboard(platform) {
                const copyText = this.settings[platform];
                // console.log(copyText)
                // document.execCommand("copy")

                this.$copyText(this.settings.cian).then((r) => {
                    console.log('copy operation complete')
                }, (e) => {
                    console.log('copy operation failed');
                })
            },
            close() {
                this.visibility = false;
            },
            setDefaultValues() {
                this.buildingName = null;
                const url = this.$store.getters.endpoint;
                this.settings = {
                    'cian': `${url}/project/${this.project.id}/building/${this.building.id}/export_to_cian`,
                    'yandex': `${url}/project/${this.project.id}/building/${this.building.id}/export_to_yandex`,
                    'avito': `${url}/project/${this.project.id}/building/${this.building.id}/export_to_avito`,
                }
            },
            setBuildingNameToUrl() {
                // if (this.buildingName) {
                //     this.settings = {
                //         'cian': encodeURI(`https://feed.artics.ru/api/project/${this.project.id}/export_to_cian?building_name=${this.buildingName}`),
                //         'yandex': encodeURI(`https://feed.artics.ru/api/project/${this.project.id}/export_to_yandex?building_name=${this.buildingName}`),
                //         'avito': encodeURI(`https://feed.artics.ru/api/project/${this.project.id}/export_to_avito?building_name=${this.buildingName}`),
                //     }
                // } else {
                //     this.settings = {
                //         'cian': `https://feed.artics.ru/api/project/${this.project.id}/export_to_cian`,
                //         'yandex': `https://feed.artics.ru/api/project/${this.project.id}/export_to_yandex`,
                //         'avito': `https://feed.artics.ru/api/project/${this.project.id}/export_to_avito`,
                //     }
                // }
                let url = "https://feed.artics.ru/api";
                if (process.env.hasOwnProperty("VUE_APP_FEED_API_URL"))
                  url = process.env.VUE_APP_FEED_API_URL;

                this.settings = {
                    'cian': `${url}/${this.project.id}/building/${this.building.id}/export_to_cian`,
                    'yandex': `${url}/${this.project.id}/building/${this.building.id}/export_to_yandex`,
                    'avito': `${url}/${this.project.id}/building/${this.building.id}/export_to_avito`,
                }
            },
            getBuildingNames() {
                this.$http.get(
                    this.$store.getters.endpoint + `/projects/${this.project.id}/building_names`, {
                        emulateJSON: true,
                        headers: this.$store.state.restrictedHeaders
                    }
                ).then(
                    r => {
                        let response = r.body;
                        if (response.result) {
                            this.buildingNames = response.data;
                        }
                    }
                )
            }
        },
        watch: {
            initialState(value) {
                if (value) {
                    // this.getBuildingNames();
                    this.setDefaultValues();
                    this.visibility = value;
                }
            },
            project(value) {
                if (value) {
                    this.project = value;
                }
            },
            building(value) {
                if (value) {
                    this.building = value;
                }
            },
            cianFeedType: {
                handler() {
                    console.log(this.cianFeedType)
                    if (['basic', 'additional'].includes(this.cianFeedType)) {
                        this.settings.cian = `${this.$store.getters.endpoint}/project/${this.project.id}/building/${this.building.id}/export_to_cian?feed_type=${this.cianFeedType}`
                    } else {
                        this.settings.cian = `${this.$store.getters.endpoint}/project/${this.project.id}/building/${this.building.id}/export_to_cian`
                    }
                }
            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
            // buildingName(value) {
            //     this.setBuildingNameToUrl();
            // }
        },
    }
</script>
