<template>
    <dialog-generic :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>
            <span>Фильтрация</span>
        </template>
        <template v-slot:content>
            <v-container>
                <v-row v-for="(el, i) in data">
                    <v-col cols="4">
                        <template v-if="el.field == ''">

                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-slot:action>
            <v-btn class='px-0 text--darken-2' :loading='indicator' block depressed>
                Применить фильтрацию
            </v-btn>
        </template>
    </dialog-generic>

</template>

<script>
    import DialogGeneric from "@/components/elements/dialogs/Generic.vue";
    export default {
        name: 'FilterDialog',
        props: ['initialState', 'data'],
        components: {'dialog-generic': DialogGeneric},
        data() {
            return {
                visibility: this.initialState,
                indicator: false,
            }
        },
        watch: {
            initialState(value) {
                this.visibility = value
            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
        },
    }
</script>
