<template>


    <v-layout>

        <v-container fluid>

            <template>

                <v-container fluid>
                    <v-row>
                        <v-col cols="8">
                            <v-btn class="ml-20 mr-20" small rounded outlined @click="getOffers">
                                <v-icon small>mdi-refresh</v-icon>&nbsp;
                                Обновить список
                            </v-btn>
                            <v-btn class="ml-20 mr-20" small rounded outlined @click="addManually">
                                <v-icon small>mdi-plus</v-icon>&nbsp;
                                Добавление лота
                            </v-btn>
                            <v-btn class="ml-20 mr-20" small rounded outlined @click="toggleHistoryModal(null)">
                                История изменений
                            </v-btn>
                            <v-btn class="ml-20 mr-20" small rounded outlined :disabled="!selected.length"
                                   @click="copyLots">
                                <v-icon small>mdi-content-copy</v-icon>&nbsp;
                                Копировать
                            </v-btn>
                            <v-btn class="ml-20 mr-20" small rounded outlined @click="massiveUpdate"
                                   :disabled="!selected.length">
                                <v-icon small>mdi-pencil</v-icon>&nbsp;
                                Массовые изменения
                            </v-btn>
                        </v-col>
                        <v-col cols="4">

                            <v-btn class="ml-20 mr-20" small rounded outlined @click="validateFeeds">
                                Валидировать фиды
                            </v-btn>

<!--                            <v-btn class="ml-20 mr-20" small rounded outlined color="red"-->
<!--                                   v-if="$store.getters.currentUser.superuser">-->
<!--                                <v-icon small>mdi-cloud-download</v-icon>&nbsp;-->
<!--                                Обновить фиды-->
<!--                            </v-btn>-->
                        </v-col>
                    </v-row>
                </v-container>
                <v-container fluid>
                    <table-header
                        :data="{}"
                        :loading="loading"
                        :hasFilters="true"
                        :selectManyFilters.sync="filterOptions"
                        :filtersEnabledCount="filtersEnabledCount"
                        @clearFilters="clearFilters"
                        @clearFilter="clearFilter"
                        @onChangedFilters="onChangedFilters"
                    />
                </v-container>
                <v-data-table
                    :headers="headers"
                    :items="offers"
                    :options.sync="options"
                    :server-items-length="totalFeeds"
                    :items-per-page="itemsPerPage"
                    :footer-props="footerProps"
                    :page.sync="page"
                    dense
                    multi-sort
                    :expanded.sync="expanded"
                    :item-class="itemRowBackground"
                    :loading="loading"
                    item-key="id"
                    v-model="selected"
                    show-select
                    loading-text="Данные загружаются..."
                >
                    <template v-slot:header.data-table-select>
                      <v-select
                          v-show="!loading"
                          v-model="allLotsEditSelectVal"
                          :items="allLotsEditSelectOptions"
                          style="width: 55px;"
                          @change="selectLots"
                      >
                        <template v-slot:selection>
                        </template>
                        <template v-slot:prepend-inner>
                          <v-checkbox
                            :input-value="isAllLotsChecked"
                            value
                            disabled
                          ></v-checkbox>
                        </template>
                      </v-select>
                    </template>
                    <template v-slot:item.external_id="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.external_id"
                            large
                            save-text="Сохранить"
                            cancel-text="Отмена"
                            @save="saveFeedQuick(props.item, 'external_id')"
                        >
                            {{ props.item.external_id}}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="props.item.external_id"
                                    label="Внешний ID"
                                ></v-text-field>

                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.housing_name="props">
                        {{ props.item.housing_name ? props.item.housing_name : 'Не задано'}}
                    </template>

                    <template v-slot:item.price="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.price"
                            large
                            save-text="Сохранить"
                            cancel-text="Отмена"
                            @save="saveFeedQuick(props.item, 'price')"
                        >
                            {{ props.item.price ? new Intl.NumberFormat().format(props.item.price) : 'Н/Д'}}
                            <template v-slot:input>
                                <v-text-field
                                    type="number"
                                    v-model.number="props.item.price"
                                    label="Цена"
                                ></v-text-field>

                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.area="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.area"
                            large
                            save-text="Сохранить"
                            cancel-text="Отмена"
                            @save="saveFeedQuick(props.item, 'area')"
                        >
                            {{ props.item.area ? new Intl.NumberFormat().format(props.item.area) : 'Н/Д'}}
                            <template v-slot:input>
                                <v-text-field
                                    type="number"
                                    v-model.number="props.item.area"
                                    label="Общая площадь"
                                    @blur="setMinValArea(props.item)"
                                    :rules="[rules.checkArea(props.item)]"
                                    :min="setMinArea(props.item)"
                                    clearable
                                ></v-text-field>

                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.living_space="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.living_space"
                            large
                            save-text="Сохранить"
                            cancel-text="Отмена"
                            @save="saveFeedQuick(props.item, 'living_space')"
                        >
                            {{ props.item.living_space ? new Intl.NumberFormat().format(props.item.living_space) :
                            'Н/Д'}}
                            <template v-slot:input>
                                <v-text-field
                                    type="number"
                                    v-model.number="props.item.living_space"
                                    label="Жилая площадь"
                                    clearable
                                ></v-text-field>

                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.kitchen_space="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.kitchen_space"
                            large
                            save-text="Сохранить"
                            cancel-text="Отмена"
                            @save="saveFeedQuick(props.item, 'kitchen_space')"
                        >
                            {{ props.item.kitchen_space ? new Intl.NumberFormat().format(props.item.kitchen_space) :
                            'Н/Д'}}
                            <template v-slot:input>
                                <v-text-field
                                    type="number"
                                    v-model.number="props.item.kitchen_space"
                                    label="Площадь кухни"
                                    clearable
                                ></v-text-field>

                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.rooms="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.rooms"
                            large
                            save-text="Сохранить"
                            cancel-text="Отмена"
                            @save="saveFeedQuick(props.item, 'rooms')"
                        >
                            {{ props.item.rooms ? props.item.rooms: 'Н/Д'}}
                            <template v-slot:input>
                                <v-text-field
                                    type="number"
                                    v-model.number="props.item.rooms"
                                    label="Комнатность"
                                    clearable
                                ></v-text-field>

                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.studio="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.studio"
                            large
                            save-text="Сохранить"
                            cancel-text="Отмена"
                            @save="saveFeedQuick(props.item, 'studio')"
                        >
                            {{ props.item.studio == 1 ? 'Да' : props.item.studio == 0 ? 'Нет' : 'Н/Д' }}
                            <template v-slot:input>
                                <v-select
                                    :items="[{value: 0, text: 'Нет'},{value: 1, text: 'Да'}]"
                                    v-model="props.item.studio"
                                    label="Студия"
                                    clearable
                                ></v-select>
                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.floor="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.floor"
                            large
                            save-text="Сохранить"
                            cancel-text="Отмена"
                            @save="saveFeedQuick(props.item, 'floor')"
                        >
                            {{ props.item.floor ? props.item.floor: 'Н/Д'}}
                            <template v-slot:input>
                                <v-text-field
                                    type="number"
                                    v-model.number="props.item.floor"
                                    label="Этаж"
                                    @blur="setMaxValFloor(props.item)"
                                    :rules="[rules.checkFloor(props.item)]"
                                    :max="setMaxFloor(props.item)"
                                    clearable
                                ></v-text-field>

                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.mortgage="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.mortgage"
                            large
                            save-text="Сохранить"
                            cancel-text="Отмена"
                            @save="saveFeedQuick(props.item, 'mortgage')"
                        >
                            {{ props.item.mortgage == 1 ? 'Да' : props.item.mortgage == 0 ? 'Нет' : 'Н/Д' }}
                            <template v-slot:input>
                                <v-select
                                    :items="[{value: 0, text: 'Нет'},{value: 1, text: 'Да'}]"
                                    v-model="props.item.mortgage"
                                    label="Ипотека"
                                    clearable
                                ></v-select>
                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn class="mr-1" x-small color="primary" @click="toggleHistoryModal(item.id)">
                            История изменений
                        </v-btn>
                        <v-btn class="mr-1" x-small :color="!item.is_yandex_active && 'error' || ''" alt="213131" @click="editYandex(item)">
                            Яндекс
                        </v-btn>
                        <v-btn class="mr-1" x-small :color="!item.is_avito_active && 'error' || ''" @click="editAvito(item)">
                            Авито
                        </v-btn>

                        <v-btn class="mr-1" x-small :color="!item.is_cian_active && 'error' || ''" @click="editCian(item)">
                            ЦИАН
                        </v-btn>

<!--                        <v-btn class='mr-1' x-small-->
<!--                               @click="updateFeedStatus(item.id, item.is_active ? 'disable' : 'enable')">-->
<!--                            <v-icon x-small-->
<!--                                    :color='(item.is_active ? $store.getters.uiAccentIcon : "light-blue darken-1")'>-->
<!--                                mdi-power-->
<!--                            </v-icon>-->
<!--                        </v-btn>-->

                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class='mr-1' x-small
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon small
                                            :color='(item.is_active || item.is_yandex_active || item.is_avito_active || item.is_cian_active ? "green darken-4" : "red darken-2")'>
                                        mdi-power
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="updateFeedStatus(item.id, 'disable', 'all')" v-if="item.is_active">
                                    <v-list-item-title>Выключить везде</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="updateFeedStatus(item.id, 'enable', 'all')" v-if="!item.is_active">
                                    <v-list-item-title>Включить везде</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="updateFeedStatus(item.id, 'disable', 'yandex')" v-if="item.is_yandex_active">
                                    <v-list-item-title>Выключить Яндекс</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="updateFeedStatus(item.id, 'enable', 'yandex')" v-if="!item.is_yandex_active">
                                    <v-list-item-title>Включить Яндекс</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="updateFeedStatus(item.id, 'disable', 'avito')" v-if="item.is_avito_active">
                                    <v-list-item-title>Выключить Авито</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="updateFeedStatus(item.id, 'enable', 'avito')" v-if="!item.is_avito_active">
                                    <v-list-item-title>Включить Авито</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="updateFeedStatus(item.id, 'disable', 'cian')" v-if="item.is_cian_active">
                                    <v-list-item-title>Выключить ЦИАН</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="updateFeedStatus(item.id, 'enable', 'cian')" v-if="!item.is_cian_active">
                                    <v-list-item-title>Включить ЦИАН</v-list-item-title>
                                </v-list-item>

                            </v-list>
                        </v-menu>

                    </template>


                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="totalPages"
                        :total-visible="20"
                        circle
                    ></v-pagination>
                </div>
            </template>

        </v-container>
        <history-dialog 
          :initialState="visibility.historyDialog"
          :feed-id="feedId"
          :housing="currentHousing.building_section"
          :building="currentHousing.building_name"
          :housing-id="currentHousing.id"
          :login="$store.getters.userLogin"
          @close="toggleHistoryModal" 
        />
        <project-yandex-flat-feed-update
            :initialState.sync="visibility.yandexNewBuilding"
            :feed="cursorFeed"
        ></project-yandex-flat-feed-update>
        <project-yandex-commercial-feed-update
            :initialState.sync="visibility.yandexCommercialBuilding"
            :feed="cursorFeed"
        ></project-yandex-commercial-feed-update>
        <project-avito-new-building-feed-update
            :initialState.sync="visibility.avitoNewBuilding"
            :feed="cursorFeed"
        ></project-avito-new-building-feed-update>
        <project-avito-commercial-building-feed-update
            :initialState.sync="visibility.avitoCommercial"
            :feed="cursorFeed"
        ></project-avito-commercial-building-feed-update>
        <project-cian-new-building-feed-update
            :initialState.sync="visibility.cianNewBuilding"
            :feed="cursorFeed"
        ></project-cian-new-building-feed-update>
        <project-cian-commercial-building-feed-update
            :initialState.sync="visibility.cianCommercialBuilding"
            :feed="cursorFeed"
        ></project-cian-commercial-building-feed-update>
        <project-massive-update
            :initialState.sync="visibility.massiveUpdate"
            :feeds="selected"
            :postAction="getOffers"
            :updateAll="allLotsEditSelectVal === 'all'"
            :housing-id="currentHousing.id"
        ></project-massive-update>

        <project-filter
            :initialState.sync="visibility.filterDialog"
            :data="filterParams"
        ></project-filter>

        <manual-dialog
            :initialState.sync="visibility.manual"
            :housingData="currentHousing"
            :postAction="getOffers"
        ></manual-dialog>
    </v-layout>

</template>

<style>
    .style-1 {
        background-color: #A9F4FD;
    }
    .style-2 {
        background-color: #b2fd57;
    }
</style>

<script>
    import HistoryDialog from "@/components/entities/feeds/HistoryDialog";
    import YandexFlatFeedUpdateDialog from "@/components/entities/feeds/YandexFlatUpdateDialog";
    import YandexCommercialFeedUpdateDialog from "@/components/entities/feeds/YandexCommercialUpdateDialog";
    import AvitoNewBuildingUpdateDialog from "@/components/entities/feeds/AvitoNewBuildingUpdateDialog";
    import AvitoCommercialBuildingUpdateDialog from "@/components/entities/feeds/AvitoCommercialBuildingUpdateDialog";
    import CianNewBuildingUpdateDialog from "@/components/entities/feeds/CianNewBuildingUpdateDialog";
    import CianCommercialBuildingUpdateDialog from "@/components/entities/feeds/CianCommercialBuildingUpdateDialog";
    import MassiveUpdateDialog from "@/components/entities/feeds/MassiveUpdateDialog";
    import FilterDialog from "@/components/entities/projects/FilterDialog";
    import header from "../plugins/header.vue";
    import filters from "../store/filters";
    import ManualDialog from "@/components/entities/feeds/ManualDialog";

    export default {
        components: {
            'history-dialog': HistoryDialog,
            'project-yandex-flat-feed-update': YandexFlatFeedUpdateDialog,
            'project-yandex-commercial-feed-update': YandexCommercialFeedUpdateDialog,
            'project-avito-new-building-feed-update': AvitoNewBuildingUpdateDialog,
            'project-avito-commercial-building-feed-update': AvitoCommercialBuildingUpdateDialog,
            'project-cian-new-building-feed-update': CianNewBuildingUpdateDialog,
            'project-cian-commercial-building-feed-update': CianCommercialBuildingUpdateDialog,
            'project-massive-update': MassiveUpdateDialog,
            'project-filter': FilterDialog,
            'table-header': header,
            'manual-dialog': ManualDialog,
        },
        data() {
            return {
                feedId: null,
                allLotsEditSelectVal: null,
                selected: [],
                visibility: {
                    historyDialog: false,
                    importSetting: false,
                    exportSettings: false,
                    update: false,
                    yandexNewBuilding: false,
                    yandexCommercialBuilding: false,
                    avitoNewBuilding: false,
                    avitoCommercial: false,
                    cianNewBuilding: false,
                    cianCommercialBuilding: false,
                    massiveUpdate: false,
                    filterDialog: false,
                    manual: false
                },
                expanded: [],
                building_name: '',
                page: 1,
                totalFeeds: 0,
                totalPages: 0,
                offers: [],
                filterParams: {
                    building_name: {
                        operand: 7,
                        value: null
                    }
                },
                filterOptions: filters.options,
                options: {},
                pagination: {},
                loading: true,
                project: {},
                cursorFeed: {},
                footerProps: {
                    'items-per-page-options': [20, 50, 100],
                    'prev-icon': 'false',
                    'next-icon': 'false',
                    'items-per-page-text': 'На странице'
                },
                itemsPerPage: 20,
                areaError: false,
                rules: {
                    checkArea(item) {
                        let op = item.area
                        let ls = item.living_space
                        let ks = item.kitchen_space
                        let check_op = ls + ks
                        if (check_op > op) {
                            this.areaError = true
                            return 'Общая площадь не должна быть меньше ' + check_op
                        } else {
                            this.areaError = false
                            return true
                        }

                    },
                     checkFloor(item) {
                         let fl = item.floor
                         let fl_total = item.floors_total
                         if (fl_total > 0 && fl > fl_total) {
                             this.floorError = true
                             return '"Этажность" не должна быть больше ' + fl_total
                         } else {
                             this.floorError = false
                             return true
                         }
                     }
                }
            }
        },
        methods: {
            toggleHistoryModal(id = null) {
              this.feedId = id
              this.visibility.historyDialog = !this.visibility.historyDialog
            },
            selectLots(val) {
              this.selected = [...this.offers];
            },
            updateFeedStatus(item, status, type) {
                this.indicator = true;
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin,
                }
                if (type == 'all') {
                    requestData['type'] = 'all'
                } else if (type == 'yandex') {
                    requestData['type'] = 'yandex'
                } else if (type == 'avito') {
                    requestData['type'] = 'avito'
                } else if (type == 'cian') {
                    requestData['type'] = 'cian'
                }
                this.$http.put(
                    this.$store.getters.endpoint + `/feeds/${item}/${status}`, {...requestData},
                    {
                        headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                    }
                ).then(
                    r => {
                        let response = r.body;
                        if (response.result) {
                            this.indicator = false;
                            this.getOffers();
                        }
                    }
                )
            },
            saveFeedQuick(item, field) {
                // if (!this.areaError) {
                    let requestHeaders = {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                    }
                    let requestData = {
                        'login': this.$store.getters.userLogin,
                    }
                    this.$http.put(
                        this.$store.getters.endpoint + `/feeds/${item.id}/quick`,
                        {...requestData, data: [field, item[field]]},
                        {headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}}
                    ).then((r) => {
                        let response = r.body;
                        if (response.result) {
                            this.getOffers();
                            // console.log(response)
                        } else {
                            // console.log(response)
                        }
                    })
                // }
            },
            editYandex(item) {
                this.cursorFeed = item;
                if (item.building_type === 'new_building') {
                    this.visibility.yandexNewBuilding = true;
                }

                if (item.building_type === 'commercial_building') {
                    this.visibility.yandexCommercialBuilding = true;
                }
            },
            editAvito(item) {
                this.cursorFeed = item;
                if (item.building_type === 'new_building') {
                    this.visibility.avitoNewBuilding = true;
                }

                if (item.building_type === 'commercial_building') {
                    this.visibility.avitoCommercial = true;
                }
            },
            editCian(item) {
                this.cursorFeed = item;
                if (item.building_type === 'new_building') {
                    this.visibility.cianNewBuilding = true;
                }

                if (item.building_type === "commercial_building") {
                    this.visibility.cianCommercialBuilding = true;
                }
            },
            massiveUpdate() {
                this.visibility.massiveUpdate = true;
            },
            addManually() {
                this.visibility.manual = true;
            },
            copyLots() {
                let lots = JSON.parse(JSON.stringify(this.selected));
                lots = lots.map(e => e.id);
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin
                }
                this.$http.put(
                    this.$store.getters.endpoint + `/housings/${this.currentHousing.id}/lots`,
                    {...requestData, lots: [...lots]},
                    {
                        headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                    }).then((r) => {
                    let response = r.body;
                    this.getOffers();
                })
            },
            validateFeeds() {
                this.$http.get(
                    this.$store.getters.endpoint + `/housing/${this.currentHousing.id}/validate`, {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin
                        },
                        headers: this.$store.state.restrictedHeaders
                    }
                ).then(
                    r => {
                        this.indicator = false;
                        let response = r.body;
                    }
                )
            },
            getOffers() {
                this.indicator = true;
                this.loading = true;
                this.selected = [];
                this.offers = [];
                const {page, itemsPerPage} = this.options;
                const sortParams = {};
                this.options.sortBy.forEach((f, id) => {
                    sortParams[f] = this.options.sortDesc[id] ? 'DESC' : 'ASC'
                })
                const filterParams = {};
                this.filterOptions.forEach((f, id) => {
                    if (f.value && f.operand) {
                        if (f.name == 'id') {
                            filterParams['f.id'] = {
                                operand: f.operand,
                                value: f.name == 'building_name' ? `%${f.value}%` : f.value
                            }
                        } else if (f.name == 'external_id') {
                            filterParams['f.external_id'] = {
                                operand: f.operand,
                                value: f.name == 'external_id' ? `%${f.value}%` : f.value
                            }
                        } else {
                            filterParams[f.name] = {
                                operand: f.operand,
                                value: f.name == 'building_name' ? `%${f.value}%` : f.value
                            }
                        }

                    }
                });
                const params = {
                    'login': this.$store.getters.userLogin,
                    'page': page,
                    'per_page': itemsPerPage
                };
                if (Object.keys(sortParams).length) {
                    params['sort_params'] = JSON.stringify({...sortParams})
                }
                if (Object.keys(filterParams).length) {
                    params['filter_params'] = JSON.stringify({...filterParams})
                }
                this.$http.get(
                    this.$store.getters.endpoint + `/housings/${this.currentHousing.id}/lots`, {
                        emulateJSON: true,
                        params: params,
                        headers: this.$store.state.restrictedHeaders
                    }
                ).then(
                    r => {
                        let response = r.body;
                        if (response.result) {
                            this.offers = response.data.feeds;
                            this.totalFeeds = response.data.total_count;
                            this.totalPages = response.data.total_pages;
                            this.indicator = false;
                            this.visibility.update = false;

                        }
                    }
                ).finally(() => {
                    this.allLotsEditSelectVal = null
                    this.loading = false;
                })
            },
            requestHousingDetails() {
                document.title = this.$store.getters.pageTitle + ' | ' + this.currentHousing.name;
            },
            onChangedFilters() {
                this.getOffers();
            },
            clearFilters() {
                this.filterOptions.forEach(e => {
                    e.selected = false;
                    if (e.name != 'building_name' /*|| e.name != 'external_id'*/) {
                        e.operand = null;
                    }
                    e.value = null;
                });
                this.getOffers();
            },
            clearFilter(e) {
                this.filterOptions[e].value = null;
                if (this.filterOptions[e].name != 'building_name' /*|| this.filterOptions[e].name != 'external_id'*/) {
                    this.filterOptions[e].operand = null;
                }
                this.getOffers();
            },
            itemRowBackground(item) {
                if (item.cian_feed_format == 'additional') {
                    return 'style-1'
                }
            },
            setMinValArea(item) {
                if (item.living_space + item.kitchen_space > item.area) {
                    item.area = item.living_space + item.kitchen_space
                }
            },
            setMinArea(item) {
                let min_area = item.living_space + item.kitchen_space
                return min_area
            },
            setMaxFloor(item) {
                 if (item.floors_total > 0) {
                     return item.floors_total
                 }
            },
            setMaxValFloor(item) {
                 if (item.floors_total > 0 && item.floor > item.floors_total) {
                     item.floor = item.floors_total
                 }
            },
        },
        watch: {
            '$route'(to, from) {
                if (to.name == 'housing') {
                    if (to && to.params && Object.keys(to.params).length > 1) {
                        this.$store.commit('cursorHousing', to.params);
                    } else {
                        const housing = this.$store.getters.userHousings.filter((h) => h.id == to.params.id)[0]
                        this.$store.commit('cursorHousing', housing);
                    }
                    this.requestHousingDetails()
                }
            },
            options: {
                handler() {
                    setTimeout(() => {
                        this.getOffers();
                    }, 500)
                }
            },
            currentHousing() {
                if (Object.keys(this.currentHousing).length) {
                    this.$store.commit('setBreadcrumbs', [
                        {text: 'Главная', disabled: false, to: '/', ripple: true},
                        {
                            text: this.currentHousing.project_name,
                            disabled: true,
                            ripple: true
                        },
                        {
                            text: this.currentHousing.building_name,
                            disabled: true,
                            ripple: true
                        },
                        {
                            text: this.currentHousing.name,
                            disabled: true,
                            to: '/h/' + this.currentHousing.id,
                            ripple: true
                        }
                    ]);
                }
            },
        },
        computed: {
            isAllLotsChecked() {
              return this.selected.length === this.offers.length || !!this.allLotsEditSelectVal
            },
            allLotsEditSelectOptions() { 
              return [
                { text: 'Выбрать все лоты на текущей странице', value: 'page' },
                { text: `Выбрать все лоты в проекте (${this.totalFeeds})`, value: 'all' }
              ]
            },
            currentHousing() {
                return this.$store.state.currentHousing
            },
            filtersEnabledCount() {
                const enabledFilters = this.filterOptions.filter(e => {
                    return e.operand && e.value || !e.operand && e.value;
                });
                return enabledFilters.length;
            },
            headers() {
                return [
                    {
                        text: 'ID',
                        value: 'id'
                    },
                    {
                        text: 'Внешний ID',
                        value: 'external_id'
                    },
                    {
                        text: 'Название корпуса',
                        value: 'housing_name',
                        align: 'left',
                    },
                    {
                        text: 'Цена',
                        value: 'price',
                        align: 'center',
                    },
                    {
                        text: 'ОП',
                        value: 'area',
                        align: 'center',
                        // width: 85
                    },
                    {
                        text: 'ЖП',
                        value: 'living_space',
                        align: 'center',
                        // width: 105,
                    },
                    {
                        text: 'ПК',
                        value: 'kitchen_space',
                        align: 'center',
                        // width: 15,
                    },
                    {
                        text: 'Комнатность',
                        value: 'rooms',
                        align: 'center',
                        class: 'wb-ba',
                        // width: 80,
                    },
                    {
                        text: 'Студия',
                        value: 'studio',
                        align: 'center',
                        // width: 65
                    },
                    {
                        text: 'Этаж',
                        value: 'floor',
                        align: 'center',
                        // width: 15,
                    },
                    {
                        text: 'Ипотека',
                        value: 'mortgage',
                        align: 'center',
                        // width: 65
                    },
                    // {
                    //     text: 'Год сдачи',
                    //     value: 'built_year',
                    //     align: 'center',
                    // },
                    // {
                    //     text: 'Кв сдачи',
                    //     value: 'ready_quarter',
                    //     align: 'center',
                    //     class: 'wb-bw',
                    //     // width: 79
                    // },
                    // {
                    //     text: 'Стадия строительства',
                    //     value: 'building_state',
                    //     align: 'center',
                    // },
                    {text: 'Действия', value: 'actions', sortable: false},
                ]
            },
            mortgageCompute(item) {
                return item.mortgage ? 'Да' : 'Нет';
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.$store.subscribe((mutation, state) => {

                    if (mutation.type == 'cursorHousing') {
                        if (mutation.payload && Object.keys(mutation.payload).length > 0) {
                            vm.requestHousingDetails()
                        } else {
                            vm.$router.push({'path': '/'});
                        }
                    }
                });
                if (from.name || Object.keys(to.params).includes('id')) {
                    const housing = vm.$store.getters.userHousings.filter((h) => h.id == to.params.id)[0]
                    vm.$store.commit('cursorHousing', housing);
                    // vm.getOffers()
                }
            });
        }
    }
</script>
