export default {
    options: [
        {
            label: 'Название предложения',
            name: 'name',
            value: null,
            selected: false,
            operand: '7',
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Производитель',
            name: 'vendor',
            value: null,
            selected: false,
            operand: '7',
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Внешний ID',
            name: 'external_id',
            value: null,
            selected: false,
            operand: '7',
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]

        },
        {
            label: 'ID',
            name: 'id',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },
        {
            label: 'Цена',
            name: 'price',
            value: null,
            selected: false,
            operand: null,
            operands: [
                {
                    value: '1',
                    text: '='
                },
                {
                    value: '2',
                    text: '>'
                },
                {
                    value: '3',
                    text: '<'
                },
                {
                    value: '5',
                    text: '>='
                },
                {
                    value: '6',
                    text: '<='
                },
            ]
        },

    ]
}
