<template>

    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>Настройка импорта</template>
        <template v-slot:action>
            <v-btn
                text
                :disabled='!valid || !settings.length'
                :loading='indicator'
                color='green darken-2'
                @click="saveImportSetting"
            >
                Сохранить
            </v-btn>
        </template>
        <template v-slot:content>

            <v-form v-model='valid' style='min-width: 320px; width: 800px;'>

                <v-container fluid>
                    <v-row v-for="(s, i) in settings" :key="i">
                        <v-col cols="3">
                            <v-select
                                :items="sourceTypes"
                                v-model="s.source_type"
                                clearable
                                label="Тип источника данных"
                                :rules="[rules.required]"
                                @change="clearUrl(i)"
                                v-if="project.type == 'realty'"
                            ></v-select>

                            <v-select
                                :items="ecomSourceTypes"
                                v-model="s.source_type"
                                clearable
                                label="Тип источника данных"
                                :rules="[rules.required]"
                                @change="clearUrl(i)"
                                v-if="project.type == 'ecom'"
                            ></v-select>
                        </v-col>
                        <v-col cols="9">
                            <v-text-field
                                v-model="s.url"
                                label="URL источника данных"
                                :rules="[rules.required]"
                            >
                                <template slot="append-outer">
                                    <v-icon @click="deleteSource(i)" title="Удалить источник">mdi-delete</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn rounded outlined @click="addSource">
                                Добавить источник
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>



            </v-form>

        </template>
    </dialog-fullscreen>

</template>

<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue';

    export default {
        name: 'ProjectImportSetting',
        props: ['initialState', 'project'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                valid: false,
                indicator: false,
                visibility: this.initialState,
                sourceTypes: [
                    {
                        value: 'yandex_feed',
                        text: 'Яндекс.Недвижимость'
                    },
                    {
                        value: 'cian_feed',
                        text: 'ЦИАН'
                    },
                    {
                        value: 'avito_feed',
                        text: 'Авито'
                    },
                    {
                        value: 'csv',
                        text: 'CSV'
                    },
                    {
                        value: 'xls',
                        text: 'Excel'
                    }
                ],
                ecomSourceTypes: [
                    {
                        value: 'yandex_market_feed',
                        text: 'Яндекс.Маркет'
                    }
                ],
                rules: {
                    required: value => !!value || 'Обязательное поле.'
                },
                settings: []
            }
        },
        methods: {
            addSource() {
                this.settings.push(
                    {
                        id: null,
                        project_id: this.project.id,
                        url: null,
                        source_type: null
                    }
                )
            },
            deleteSource(idx) {
                this.settings.splice(idx, 1)
            },
            clearUrl(idx) {
                this.settings[idx].url = null;
            },
            getSettings() {
                this.indicator = true;
                this.$http.get(
                    this.$store.getters.endpoint + `/projects/${this.project.id}/import_setting`, {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin
                        },
                        headers: this.$store.state.restrictedHeaders
                    }
                ).then(
                    r => {
                        let response = r.body;
                        if (response.result) {
                            if (response.data) {
                                this.settings = response.data;
                            } else {
                                this.fillDefaultSetting()
                            }

                            this.indicator = false;
                        }
                    }
                )
            },
            fillDefaultSetting() {
                this.settings = [{
                    id: null,
                    project_id: this.project.id,
                    url: null,
                    source_type: null
                }]
            },
            saveImportSetting() {
                this.indicator = true;
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin,
                }
                this.$http.post(
                    this.$store.getters.endpoint + `/projects/${this.project.id}/import_setting`, {...requestData, settings: this.settings},
                    {
                        headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                    }
                ).then(
                    r => {
                        let response = r.body;
                        if (response.result) {
                            this.indicator = false;
                            this.visibility = false;
                        }
                    }
                )
            }
        },
        watch: {
            initialState(value) {
                this.visibility = value;
                if (value) {
                    this.getSettings()
                }
            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
        },
        mounted() {

        }
    }
</script>
