<template>

    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>Добавление лота</template>
        <template v-slot:action>
            <v-btn
                text
                color='green darken-2'
                @click="print"
            >
                Добавить
            </v-btn>
        </template>

        <template v-slot:content>

            <v-form v-model="validGeneralInfo" style='min-width: 320px;'>

                <v-select
                    :items="lotTypes"
                    v-model="lotType"
                    clearable
                    :rules="[rules.required]"
                    label="Тип лота"
                >

                </v-select>

                <v-text-field
                    v-model="lot.external_id"
                    clearable
                    :rules="[rules.required]"
                    label="Внешний ID"
                ></v-text-field>

                <v-text-field
                    v-model.number="lot.price"
                    type="number"
                    clearable
                    :rules="[rules.required]"
                    label="Цена"
                ></v-text-field>

                <v-text-field
                    v-model.number="lot.area"
                    type="number"
                    clearable
                    :rules="[rules.required]"
                    label="Общая площадь"
                ></v-text-field>

                <v-text-field
                    v-model.number="lot.living_space"
                    type="number"
                    clearable
                    label="Жилая площадь"
                ></v-text-field>

                <v-text-field
                    v-model.number="lot.kitchen_space"
                    type="number"
                    clearable
                    label="Площадь кухни"
                ></v-text-field>

                <v-text-field
                    v-model.number="lot.rooms"
                    type="number"
                    clearable
                    label="Комнатность"
                ></v-text-field>

                <v-text-field
                    v-model.number="lot.floor"
                    type="number"
                    clearable
                    :rules="[rules.required]"
                    label="Этаж"
                ></v-text-field>

                <v-select
                    :items="yesNoChoices"
                    v-model="lot.mortgage"
                    clearable
                    :rules="[rules.required]"
                    label="Возможность ипотеки"
                ></v-select>


            </v-form>
        </template>
    </dialog-fullscreen>

</template>

<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue';
    import yaVariables from "../../../store/yaVariables";

    export default {
        name: 'ManualAdding',
        props: ['initialState', 'housingData', 'postAction'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                valid: false,
                validGeneralInfo: false,
                indicator: false,
                visibility: this.initialState,
                housing: this.housingData,
                isLoading: false,
                search: null,
                lotType: null,
                lotTypes: [
                    {
                        value: 'sale',
                        text: 'Продажа',
                    },
                    {
                        value: 'rent',
                        text: 'Аренда',
                    },
                ],
                rules: {
                    required: value => !!value || 'Обязательное поле.',
                    email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный электронный адрес'

                },
                yesNoChoices: [
                    ...yaVariables.ya.yesNoChoices
                ],
                lot: {
                    external_id: null,
                    price: null,
                    area: null,
                    living_space: null,
                    kitchen_space: null,
                    rooms: null,
                    floor: null,
                    mortgage: 0,
                    cian_feed_format: 'basic',
                    yandex: {
                        type: "продажа",
                        rooms_type: [],
                        floor_covering: [],
                        window_view: [],
                        window_type: [],
                        description: ''
                    },
                    avito: {
                        operation_type: 'Продам',
                        view_from_windows: [],
                        courtyard: [],
                        parking: [],
                        room_type: [],
                        description: '',
                    },
                    cian: {
                        phones: [],
                        description: '',
                        photos: [],
                    },
                    project_id: null,
                    building_id: null,
                    housing_id: null,
                }
            }
        },
        watch: {
            initialState(value) {
                if (value) {
                    this.visibility = value;
                }

            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
            housingData(value) {
                this.housing = value;
            }
        },
        methods: {
            print() {

                const building = this.$store.getters.userBuildings.filter(b => b.id == this.housing.building_id)[0]
                this.lot.project_id = building.project_id;
                this.lot.building_id = building.id;
                this.lot.housing_id = this.housing.id;
                this.lot.building_type = building.building_type;
                this.lot.cian.category = building.category;

                this.lot.is_active = 1;
                this.lot.is_yandex_active = 1;
                this.lot.is_avito_active = 1;
                this.lot.is_cian_active = 1;
                this.lot.is_valid_yandex = 0;
                this.lot.is_valid_avito = 0;
                this.lot.is_valid_cian = 0;
                this.lot.yandex_case = 0;
                this.lot.cian.phones = [];

                console.log(building)


                if (building.building_type == 'new_building') {
                    this.lot.yandex.category = "квартира";
                    this.lot.yandex.new_flat = "1";
                    this.lot.yandex.deal_status = "первичная продажа";
                    this.lot.yandex.property_type = "жилая";
                    this.lot.yandex.type = 'продажа'

                    this.lot.avito.property_rights = 'Застройщик';
                    this.lot.avito.market_type = 'Новостройка';
                    this.lot.avito.category = 'Квартиры';
                    this.lot.avito.nd_additionally = [];
                    this.lot.avito.view_from_windows = [];
                    this.lot.avito.courtyard = [];
                    this.lot.avito.operation_type = 'Продам'
                }

                if (building.building_type == 'commercial_building') {
                    this.lot.yandex.category = "commercial";
                    this.lot.yandex.commercial_type = [];
                    this.lot.yandex.type = this.lotType == 'sale' ? 'продажа' : 'аренда';

                    this.lot.avito.category = 'Коммерческая недвижимость';
                    this.lot.avito.property_rights = 'Собственник';
                    this.lot.avito.operation_type = this.lotType == 'sale' ? 'Продажа' : 'Сдам';

                    this.lot.cian.category = building.category;
                }

                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin
                }
                this.$http.post(this.$store.getters.endpoint +  `/housings/${this.housing.id}/lots`,
                    {...requestData, lot: {...this.lot}},
                    {
                        headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                    }).then((r) => {
                    let response = r.body;
                    this.indicator = false;
                    if (response.result) {
                        this.postAction && this.postAction();
                        this.visibility = false;
                    } else {
                        this.errorCreate = true;
                        this.errorCreateMessage = ['Идентификатор уже используется в другом объекте']
                    }
                })
            },
            setDefaultLot() {
                this.lot = {
                    external_id: null,
                    price: null,
                    area: null,
                    living_space: null,
                    kitchen_space: null,
                    rooms: null,
                    floor: null,
                    mortgage: 0,
                    cian_feed_format: 'basic',
                    yandex: {},
                    avito: {},
                    cian: {},
                    project_id: null,
                    building_id: null,
                    housing_id: null,
                }
            }
        },
        mounted() {
            this.setDefaultLot();
        }
    }

</script>
