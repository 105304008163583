<template>
    <v-layout>

        <v-container fluid>

            <template>

                <v-container fluid>
                    <v-row>
                        <v-col cols="8">
                            <v-btn class="ml-20 mr-20" small rounded outlined @click="getProducts">
                                <v-icon small>mdi-refresh</v-icon>&nbsp;
                                Обновить список
                            </v-btn>
                            <!--                        <v-btn class="ml-20 mr-20" small rounded outlined @click="addManually">-->
                            <!--                            <v-icon small>mdi-plus</v-icon>&nbsp;-->
                            <!--                            Добавление лота-->
                            <!--                        </v-btn>-->
                            <!--                        <v-btn class="ml-20 mr-20" small rounded outlined :disabled="!selected.length"-->
                            <!--                               @click="copyLots">-->
                            <!--                            <v-icon small>mdi-content-copy</v-icon>&nbsp;-->
                            <!--                            Копировать-->
                            <!--                        </v-btn>-->
                            <v-btn class="ml-20 mr-20" small rounded outlined @click="massiveUpdate"
                                   :disabled="!selected.length">
                                <v-icon small>mdi-pencil</v-icon>&nbsp;
                                Массовые изменения
                            </v-btn>
                        </v-col>
                        <v-col cols="4">

                            <!--                        <v-btn class="ml-20 mr-20" small rounded outlined @click="validateFeeds">-->
                            <!--                            Валидировать фиды-->
                            <!--                        </v-btn>-->

                            <!--                            <v-btn class="ml-20 mr-20" small rounded outlined color="red"-->
                            <!--                                   v-if="$store.getters.currentUser.superuser">-->
                            <!--                                <v-icon small>mdi-cloud-download</v-icon>&nbsp;-->
                            <!--                                Обновить фиды-->
                            <!--                            </v-btn>-->
                        </v-col>
                    </v-row>

                </v-container>

                <v-container fluid>
                    <table-header
                        :data="{}"
                        :loading="loading"
                        :hasFilters="true"
                        :selectManyFilters.sync="filterOptions"
                        :filtersEnabledCount="filtersEnabledCount"
                        @clearFilters="clearFilters"
                        @clearFilter="clearFilter"
                        @onChangedFilters="onChangedFilters"
                    />
                </v-container>

                <v-data-table
                    :headers="headers"
                    :items="products"
                    :options.sync="options"
                    :server-items-length="totalProducts"
                    :items-per-page="itemsPerPage"
                    :footer-props="footerProps"
                    :page.sync="page"
                    dense
                    multi-sort
                    :expanded.sync="expanded"

                    :loading="loading"
                    item-key="id"
                    v-model="selected"
                    show-select
                    loading-text="Данные загружаются..."
                >
                    <template v-slot:item.type="props">
                        {{ getTypeName(props.item.type) }}
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn x-small class='mr-1' @click="editProduct(item)">
                            <v-icon
                                x-small
                            >
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>

                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="totalPages"
                        :total-visible="20"
                        circle
                    ></v-pagination>
                </div>

            </template>

        </v-container>

        <product-update-dialog
            :initialState.sync="visibility.update"
            :data="cursorProduct"
            :postAction="getProducts"
        ></product-update-dialog>

        <product-massive-update
            :initialState.sync="visibility.massiveUpdate"
            :positions="selected"
            :postAction="getProducts"
        ></product-massive-update>

    </v-layout>
</template>

<script>
    import filters from "../store/ecomFilters";
    import header from "../plugins/ecomHeader.vue";

    import ProductUpdateDialog from "@/components/entities/products/UpdateDialog";
    import MassiveUpdateDialog from "@/components/entities/products/MassiveUpdateDialog";

    export default {
        components: {
            'product-update-dialog': ProductUpdateDialog,
            'table-header': header,
            'product-massive-update': MassiveUpdateDialog,
        },
        data() {
            return {
                selected: [],
                visibility: {
                    importSetting: false,
                    exportSettings: false,
                    update: false,
                    yandexNewBuilding: false,
                    yandexCommercialBuilding: false,
                    avitoNewBuilding: false,
                    avitoCommercial: false,
                    cianNewBuilding: false,
                    cianCommercialBuilding: false,
                    massiveUpdate: false,
                    filterDialog: false,
                    manual: false,
                },
                cursorProduct: {},
                expanded: [],
                building_name: '',
                page: 1,
                totalProducts: 0,
                totalPages: 0,
                products: [],
                filterParams: {
                    building_name: {
                        operand: 7,
                        value: null
                    }
                },
                filterOptions: filters.options,
                options: {},
                pagination: {},
                loading: true,
                project: {},
                footerProps: {
                    'items-per-page-options': [20, 50, 100],
                    'prev-icon': 'false',
                    'next-icon': 'false',
                    'items-per-page-text': 'На странице'
                },
                itemsPerPage: 20,
            }
        },
        methods: {
            onChangedFilters() {
                this.getProducts();
            },
            clearFilters() {
                this.filterOptions.forEach(e => {
                    e.selected = false;
                    if (!['name', 'vendor', 'external_id'].includes(e.name)) {
                        e.operand = null;
                    }
                    e.value = null;
                });
                this.getProducts();
            },
            clearFilter(e) {
                this.filterOptions[e].value = null;
                if (!['name', 'vendor', 'external_id'].includes(e.name)) {
                    this.filterOptions[e].operand = null;
                }
                this.getProducts();
            },
            massiveUpdate() {
                this.visibility.massiveUpdate = true;
            },
            getProducts() {
                this.indicator = true;
                this.loading = true;
                this.selected = [];
                this.products = [];
                const {page, itemsPerPage} = this.options;
                const sortParams = {};
                this.options.sortBy.forEach((f, id) => {
                    sortParams[f] = this.options.sortDesc[id] ? 'DESC' : 'ASC'
                })

                const filterParams = {};
                this.filterOptions.forEach((f, id) => {
                    if (f.value && f.operand) {
                        filterParams[f.name] = {
                            operand: f.operand,
                            value: ['name', 'vendor', 'external_id'].includes(f.name) ? `%${f.value}%` : f.value
                        }
                    }
                });
                const params = {
                    'login': this.$store.getters.userLogin,
                    'page': page,
                    'per_page': itemsPerPage
                };
                if (Object.keys(sortParams).length) {
                    params['sort_params'] = JSON.stringify({...sortParams})
                }
                if (Object.keys(filterParams).length) {
                    params['filter_params'] = JSON.stringify({...filterParams})
                }

                this.$http.get(
                    this.$store.getters.endpoint + `/shops/${this.currentShop.id}/lots`, {
                        emulateJSON: true,
                        params: params,
                        headers: this.$store.state.restrictedHeaders
                    }
                ).then(
                    r => {
                        let response = r.body;
                        if (response.result) {
                            this.products = response.data.products;
                            this.totalProducts = response.data.total_count;
                            this.totalPages = response.data.total_pages;
                            this.indicator = false;
                            this.visibility.update = false;

                        }
                    }
                ).finally(() => {
                    this.loading = false;
                })

            },
            editProduct(item) {
                this.cursorProduct = item;
                this.visibility.update = true;
            },
            requestShopDetails() {
                document.title = this.$store.getters.pageTitle + ' | ' + this.currentShop.name;
            },
            getTypeName(productType) {
                if (productType) {
                    switch (productType) {
                        case "vendor.model": return "Произвольный";
                        case "alco": return "Алкоголь";
                        case "audiobook": return "Аудиокнига";
                        case "event-ticket": return "Билет";
                        case "book": return "Книга";
                        case "medicine": return "Лекарство";
                        case "artist.title": return "Музыкальная и видеопродукция";
                        case "tour": return "Тур";
                        default: return "Неизвестный"
                    }
                } else {
                    return "Упрощенный"
                }
            },
        },
        watch: {
            '$route'(to, from) {
                if (to.name == 'shop') {
                    if (to && to.params && Object.keys(to.params).length > 1) {
                        this.$store.commit('cursorProject', to.params);
                    } else {
                        const project = this.$store.getters.userObjects.filter((p) => p.id == to.params.id)[0]
                        this.$store.commit('cursorProject', project);
                    }
                    this.requestShopDetails()
                }
            },
            options: {
                handler() {
                    setTimeout(() => {
                        this.getProducts();
                    }, 500)
                }
            },
            currentShop() {
                if (Object.keys(this.currentShop).length) {
                    this.$store.commit('setBreadcrumbs', [
                        {text: 'Главная', disabled: false, to: '/', ripple: true},
                        {
                            text: this.currentShop.name,
                            disabled: true,
                            to: '/s/' + this.currentShop.id,
                            ripple: true
                        }
                    ]);
                }
            },
        },
        computed: {
            currentShop() {
                return this.$store.state.currentProject
            },
            filtersEnabledCount() {
                const enabledFilters = this.filterOptions.filter(e => {
                    return e.operand && e.value;
                });
                return enabledFilters.length;
            },
            headers() {
                return [
                    {
                        text: 'ID',
                        value: 'id'
                    },
                    {
                        text: 'Внешний ID',
                        value: 'external_id'
                    },
                    {
                        text: 'Тип',
                        value: 'type'
                    },
                    // {
                    //     text: 'URL',
                    //     value: 'url'
                    // },
                    {
                        text: 'Цена',
                        value: 'price',
                        align: 'center',
                    },
                    {
                        text: 'Валюта',
                        value: 'currency_id',
                        align: 'center',
                    },
                    {
                        text: 'Название',
                        value: 'name'
                    },
                    {
                        text: 'Производитель',
                        value: 'vendor'
                    },
                    {
                        text: 'Модель',
                        value: 'model'
                    },
                    {
                        text: 'Тип товара',
                        value: 'type_prefix'
                    },
                    {
                        text: 'Вес',
                        value: 'weight',
                        align: 'center',
                    },

                    {text: 'Действия', value: 'actions', sortable: false},
                ]
            }
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.$store.subscribe((mutation, state) => {

                    if (mutation.type == 'cursorProject') {
                        if (mutation.payload && Object.keys(mutation.payload).length > 0) {
                            vm.requestShopDetails()
                        } else {
                            vm.$router.push({'path': '/'});
                        }
                    }
                });
                if (from.name || Object.keys(to.params).includes('id')) {
                    const project = vm.$store.getters.userObjects.filter((p) => p.id == to.params.id)[0]
                    vm.$store.commit('cursorProject', project);
                    // const housing = vm.$store.getters.userHousings.filter((h) => h.id == to.params.id)[0]
                    // vm.$store.commit('cursorHousing', housing);
                    // vm.getOffers()
                }
            });
        }
    }
</script>
