<template>
    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>Массовое редактирование фида</template>
        <template v-slot:action>
            <v-btn
                text
                color='green darken-2'
                @click="print"
            >
                Обновить
            </v-btn>
        </template>
        <template v-slot:content>

            <v-container style="width: 100%; max-width: 800px;">
                <v-autocomplete
                    :items="operations"
                    v-model="selectedOperation"
                    return-object
                    label="Что сделать с выбранными позициями?"
                    @change="changeOperation"
                >

                </v-autocomplete>

                <v-text-field
                    v-model.number="feed.area"
                    type="number"
                    label="Общая площадь"
                    clearable
                    v-if="show('area')"
                ></v-text-field>

                <v-text-field
                    v-model.number="feed.living_space"
                    type="number"
                    label="Жилая площадь"
                    v-if="show('living_space')"
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model.number="feed.kitchen_space"
                    type="number"
                    label="Площадь кухни"
                    v-if="show('kitchen_space')"
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model.number="feed.price"
                    v-if="show('price')"
                    type="number"
                    label="Цена"
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model.number="feed.rooms"
                    v-if="show('rooms')"
                    type="number"
                    label="Количество комнат"
                    clearable
                ></v-text-field>

                <v-select
                    v-model="feed.studio"
                    :items="[{value: 0, text: 'Нет'}, {value: 1, text: 'Да'}]"
                    v-if="show('studio')"
                    label="Студия"
                    clearable
                ></v-select>

                <v-select
                    v-model="feed.cian_feed_format"
                    :items="[{value: 'basic', text: 'Основной фид'}, {value: 'additional', text: 'Дополнительный фид'}]"
                    v-if="show('cian_feed_format')"
                    label="Фид"
                    clearable
                ></v-select>

                <v-select
                    v-model="feed.mortgage"
                    :items="[{value: 0, text: 'Не доступен по ипотеке'},{value: 1, text: 'Доступен по ипотеке'}]"
                    v-if="show('mortgage')"
                    label="Доступность по ипотеке"
                    clearable
                ></v-select>
                                    <v-select
                                        v-model="feed.is_active"
                                        :items="[{value: '0', text: 'Не активен'}, {value: '1', text: 'Активен'}]"
                                        v-if="show('is_active')"
                                        label="Активность"
                                        clearable
                                    ></v-select>
                                    <v-select
                                        v-model="feed.is_yandex_active"
                                        :items="[{value: '0', text: 'Не активен'}, {value: '1', text: 'Активен'}]"
                                        v-if="show('is_yandex_active')"
                                        label="Активность"
                                        clearable
                                    ></v-select>
                                    <v-select
                                        v-model="feed.is_avito_active"
                                        :items="[{value: '0', text: 'Не активен'}, {value: '1', text: 'Активен'}]"
                                        v-if="show('is_avito_active')"
                                        label="Активность"
                                        clearable
                                    ></v-select>
                                    <v-select
                                        v-model="feed.is_cian_active"
                                        :items="[{value: '0', text: 'Не активен'}, {value: '1', text: 'Активен'}]"
                                        v-if="show('is_cian_active')"
                                        label="Активность"
                                        clearable
                                    ></v-select>

                <v-text-field
                    v-model.number="feed.floor"
                    v-if="show('floor')"
                    type="number"
                    label="Этаж"
                    clearable
                ></v-text-field>

                <template v-if="selectedOperation && selectedOperation.group_id == 'yandex'">

                    <v-textarea
                        v-model="feed.description"
                        v-if="show('description')"
                        label="Текст объявления"
                        :rules="[rules.required]"
                        @keydown="changeDinamicKey"
                    ></v-textarea>

                    <v-autocomplete
                        v-model="feed.renovation"
                        :items="ya.renovationChoices"
                        v-if="show('renovation')"
                        label="Ремонт"
                        clearable
                    ></v-autocomplete>

                    <v-autocomplete
                        v-model="feed.online_show"
                        :items="yesNoChoices"
                        v-if="show('online_show')"
                        label="Показ недвижимости по видео-звонку"
                        clearable
                    ></v-autocomplete>

                    <v-text-field
                        v-model="feed.youtube_url"
                        v-if="show('youtube_url')"
                        label="Прямая ссылка на видео с объектом с Youtube"
                        clearable
                    ></v-text-field>


                    <v-textarea
                        :rules="[rules.required]"
                        v-model="feed.images"
                        v-if="show('images')"
                        label="Фотография объекта и планировки *"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Ссылки на фотографии и планировку<br>
                                Каждая ссылка на отдельной строчке <br>
                                <b>Первой должна быть фотография планировки</b>
                            </div>
                        </v-tooltip>
                    </v-textarea>


                    <v-autocomplete
                        v-model="feed.rooms_type"
                        :items="ya.roomTypeChoices"
                        multiple
                        v-if="show('rooms_type')"
                        label="Тип комнат"
                        clearable
                    ></v-autocomplete>

                    <v-autocomplete
                        v-model="feed.apartments"
                        :items="yesNoChoices"
                        v-if="show('apartments')"
                        label="Апартаменты"
                        clearable
                    ></v-autocomplete>

                    <v-text-field
                        v-model="feed.balcony"
                        clearable
                        v-if="show('balcony')"
                        label="Тип балкона"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Возможные значения: <br>
                                - балкон <br>
                                - лоджия <br>
                                - 2 балкона <br>
                                - 2 лоджии <br>
                                И т.п.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-autocomplete
                        v-model="feed.window_view"
                        :items="ya.windowViewChoices"
                        v-if="show('window_view')"
                        label="Вид из окон"
                        multiple
                        clearable
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="ya.floorCoverChoices"
                        v-model="feed.floor_covering"
                        v-if="show('floor_covering')"
                        clearable
                        multiple
                        label="Покрытие пола"
                    ></v-autocomplete>

                    <v-text-field
                        v-model="feed.bathroom_unit"
                        clearable
                        v-if="show('bathroom_unit')"
                        label="Тип санузла"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Возможные значения: <br>
                                - совмещенный <br>
                                - раздельный <br>
                                - числовое значение (например «2»).
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-autocomplete
                        :items="ya.buildingTypeChoices"
                        v-model="feed.building_type"
                        v-if="show('building_type')"
                        clearable
                        label="Тип дома"
                    ></v-autocomplete>

                    <v-text-field
                        v-model="feed.building_series"
                        v-if="show('building_series')"
                        label="Серия дома"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="feed.building_section"
                        v-if="show('building_section')"
                        label="Корпус дома"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Возможные значения: «корпус 1», «корпус А», «дом 3» и т. п.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model="feed.sales_agent_phone"
                        v-if="show('sales_agent_phone')"
                        label="Номер телефона"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        type="number"
                        v-model.number="feed.ceiling_height"
                        v-if="show('ceiling_height')"
                        label="Высота потолков в метрах."
                        clearable
                    ></v-text-field>
                    <v-autocomplete
                        :items="ya.yesNoChoices"
                        v-model="feed.lift"
                        v-if="show('lift')"
                        clearable
                        label="Наличие лифта"
                    ></v-autocomplete>
                    <v-autocomplete
                        :items="ya.yesNoChoices"
                        v-model="feed.rubbish_chute"
                        v-if="show('rubbish_chute')"
                        clearable
                        label="Наличие мусоропровода"
                    ></v-autocomplete>
                    <v-autocomplete
                        :items="ya.yesNoChoices"
                        v-model="feed.guarded_building"
                        v-if="show('guarded_building')"
                        clearable
                        label="Закрытая территория"
                    ></v-autocomplete>
                    <v-autocomplete
                        :items="ya.yesNoChoices"
                        v-model="feed.parking"
                        v-if="show('parking')"
                        clearable
                        label="Наличие охраняемой парковки"
                    ></v-autocomplete>
                    <v-autocomplete
                        :items="ya.yesNoChoices"
                        v-model="feed.is_elite"
                        v-if="show('is_elite')"
                        clearable
                        label="Элитная недвижимость"
                    ></v-autocomplete>
                </template>

                <!-- AVITO -->
                <template v-if="selectedOperation && selectedOperation.group_id == 'avito'">


                    <v-autocomplete
                        :items="avito.listingFeeChoices"
                        v-model="feed.listing_fee"
                        v-if="show('listing_fee')"
                        clearable
                        label="Вариант платного размещения"
                        :rules="[rules.required]"
                    ></v-autocomplete>
                    <v-autocomplete
                        :items="avito.adStatusChoices"
                        v-model="feed.ad_status"
                        v-if="show('ad_status')"
                        clearable
                        label="Платная услуга, которую нужно применить к объявлению"
                        :rules="[rules.required]"
                    ></v-autocomplete>
                    <div v-if="show('description')">
                      <div class="d-flex mb-2">
                        <span>Текстовое описание объявления в соответствии с правилами Авито &nbsp;</span>
                        <v-tooltip
                          :open-on-hover="true"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                              <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                          </template>
                          <p>
                              Если у вас есть оплаченная Подписка, то поместив описание внутрь CDATA, <br>
                              вы можете использовать дополнительное форматирование с помощью HTML-тегов — строго
                              из указанного списка:
                              <br> p, br, strong, em, ul, ol, li.
                          </p>
                        </v-tooltip>
                      </div>
                      <base-wysiwyg 
                        v-model="feed.description"
                        :max-length="7500"
                        hint="строка не более 7500 символов"
                      />
                    </div>

                    <v-text-field
                        v-model="feed.contact_phone"
                        v-if="show('contact_phone')"
                        clearable
                        label="Конкактный телефон"
                    ></v-text-field>

                    <v-autocomplete
                        :items="avito.houseTypeChoices"
                        v-model="feed.house_type"
                        v-if="show('house_type')"
                        clearable
                        label="Тип дома"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.decorationChoices"
                        v-model="feed.decoration"
                        v-if="show('decoration')"
                        clearable
                        label="Отделка помещения"
                    ></v-autocomplete>

                    <v-text-field
                        v-model="feed.apartment_number"
                        v-if="show('apartment_number')"
                        clearable
                        label="Номер квартиры - число, больше 0 и буква (опционально)"
                    ></v-text-field>

                    <v-autocomplete
                        :items="avito.statusChoices"
                        v-model="feed.status"
                        :rules="[rules.required]"
                        v-if="show('status')"
                        clearable
                        label="Статус недвижимости"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.balconyChoices"
                        v-model="feed.balcony_or_loggia"
                        v-if="show('balcony_or_loggia')"
                        clearable
                        label="Балкон или лоджия"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.viewChoices"
                        v-model="feed.view_from_windows"
                        v-if="show('view_from_windows')"
                        clearable
                        multiple
                        label="Вид из окон"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.passengerElevatorChoices"
                        v-model="feed.passenger_elevator"
                        v-if="show('passenger_elevator')"
                        clearable
                        label="Пассажирский лифт"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.freightElevatorChoices"
                        v-model="feed.freight_elevator"
                        v-if="show('freight_elevator')"
                        clearable
                        label="Грузовой лифт"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.courtyardChoices"
                        v-model="feed.courtyard"
                        v-if="show('courtyard')"
                        clearable
                        multiple
                        label="Двор"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.parkingChoices"
                        v-model="feed.parking"
                        v-if="show('parking')"
                        clearable
                        multiple
                        label="Парковка"
                    ></v-autocomplete>

                    <v-text-field
                        type="number"
                        v-model.number="feed.ceiling_height"
                        v-if="show('ceiling_height')"
                        clearable
                        label="Высота потолков, в метрах"
                    ></v-text-field>

                    <v-autocomplete
                        :items="avito.bathroomChoices"
                        v-model="feed.bathroom"
                        v-if="show('bathroom')"
                        clearable
                        label="Санузел"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.ndAdditionallyChoices"
                        v-model="feed.nd_additionally"
                        v-if="show('nd_additionally')"
                        clearable
                        multiple
                        label="Дополнительно"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.dealTypeChoices"
                        v-model="feed.deal_type"
                        v-if="show('deal_type')"
                        clearable
                        label="Тип сделки"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.roomTypeChoices"
                        v-model="feed.room_type"
                        v-if="show('room_type')"
                        clearable
                        multiple
                        label="Тип комнат"
                    ></v-autocomplete>

                    <v-text-field
                        v-model="feed.video_url"
                        v-if="show('video_url')"
                        label="Видео c YouTube — ссылка"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <p>
                                Формат ссылки на видео должен соответствовать -
                                <b>https://www.youtube.com/watch?v=*** </b>
                                <br>
                                Пример неправильной ссылки - <b>http://www.youtube.com/embed/***</b>
                            </p>
                        </v-tooltip>
                    </v-text-field>

                    <v-textarea
                        v-model="feed.title"
                        v-if="show('title')"
                        clearable
                        label="Заголовок объявления"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <p>
                                В заголовке необходимо указывать только вид объекта и основные параметры. <br>
                                Указание цены, слов, привлекающих внимание, контактной информации, <br>
                                адреса сайта или только слова «продам / куплю» нарушает правила Авито.
                            </p>
                        </v-tooltip>
                    </v-textarea>

                    <v-autocomplete
                        :items="avito.objectTypeChoices"
                        v-model="feed.object_type"
                        v-if="show('object_type')"
                        clearable
                        label="Вид объекта"
                        @change="getAdditionalObjects"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito.buildingClassChoices"
                        v-model="feed.building_class"
                        v-if="buildingClass"
                        clearable
                        label="Класс здания"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="avito._additional"
                        v-model="feed.additional_object_type"
                        v-if="show('object_type')"
                        clearable
                        multiple
                        max-selected="2"
                        label="Вид объекта - дополнительные варианты"
                    ></v-autocomplete>

                    <v-textarea
                        v-model="feed.images"
                        v-if="show('photos')"
                        label="Фотография объекта *"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Ссылки на фотографии<br>
                                Каждая ссылка на отдельной строчке <br>
                            </div>
                        </v-tooltip>
                    </v-textarea>
                </template>

                <!-- AVITO -->

                <template v-if="selectedOperation && selectedOperation.group_id == 'cian'">
                    <!-- ЦИАН -->
                    <v-textarea
                        v-model="feed.description"
                        v-if="show('description')"
                        label="Текст объявления"
                        :rules="[rules.required]"
                        @keydown="changeDinamicKey"
                    ></v-textarea>

                    <v-text-field
                        v-model="feed.auction"
                        v-if="show('auction')"
                        label="Ставка на объявления в аукционе."
                        :rules="[rules.required]"
                    ></v-text-field>


                    <v-textarea
                        v-model="feed.title"
                        v-if="show('title')"
                        label="Заголовок объявления"
                        :rules="[rules.required]"
                    ></v-textarea>

                    <v-select
                        v-model="feed.sale_type"
                        v-if="show('sale_type')"
                        :items="cian.saleTypes"
                        :rules="[rules.required]"
                        label="Тип продажи"
                        clearable
                    ></v-select>

                    <v-textarea
                        v-model="feed.photos"
                        v-if="show('photos')"
                        label="Фотография объекта *"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Ссылки на фотографии<br>
                                Каждая ссылка на отдельной строчке <br>
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="feed.videos"
                        v-if="show('videos')"
                        label="Ссылка на видео"
                        :rules="[rules.required]"
                    ></v-text-field>

                    <v-text-field
                        v-model="feed.address"
                        v-if="show('address')"
                        label="Адрес объявления"
                        :rules="[rules.required]"
                    ></v-text-field>

                    <v-text-field
                        v-model="feed.flat_number"
                        v-if="show('flat_number')"
                        clearable
                        label="Номер на площадке"
                    ></v-text-field>

                    <v-text-field
                        v-model="feed.section_number"
                        v-if="show('section_number')"
                        clearable
                        label="Номер секции"
                    ></v-text-field>

                    <v-text-field
                        v-model="feed.flat_type"
                        v-if="show('flat_type')"
                        clearable
                        label="Тип квартиры"
                    ></v-text-field>

                    <v-autocomplete
                        :items="cian.roomTypeChoices"
                        v-model="feed.room_type"
                        v-if="show('room_type')"
                        clearable
                        label="Тип комнаты (комнат)"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="cian.yesNoChoices"
                        v-model="feed.is_apartments"
                        v-if="show('is_apartments')"
                        clearable
                        label="Апартаменты"
                    ></v-autocomplete>

                    <v-text-field
                        v-model.number="feed.loggias_count"
                        v-if="show('loggias_count')"
                        clearable
                        type="number"
                        label="Количество лоджий"
                    ></v-text-field>

                    <v-text-field
                        v-model.number="feed.balconies_count"
                        v-if="show('balconies_count')"
                        clearable
                        type="number"
                        label="Количество балконов"
                    ></v-text-field>

                    <v-autocomplete
                        :items="cian.decorations"
                        v-model="feed.decoration"
                        v-if="show('decoration')"
                        clearable
                        label="Отделка"
                    ></v-autocomplete>

                    <v-autocomplete
                        :items="cian.windowsViews"
                        v-model="feed.windows_view_type"
                        v-if="show('windows_view_type')"
                        clearable
                        label="Куда выходят окна"
                    ></v-autocomplete>

                    <v-text-field
                        v-model.number="feed.separate_wcs_count"
                        v-if="show('separate_wcs_count')"
                        clearable
                        type="number"
                        label="Количество раздельных санузлов"
                    ></v-text-field>

                    <v-text-field
                        v-model.number="feed.combined_wcs_count"
                        v-if="show('combined_wcs_count')"
                        clearable
                        type="number"
                        label="Количество совместных санузлов"
                    ></v-text-field>

                    <v-autocomplete
                        :items="cian.materialTypeChoices"
                        v-model="feed.material_type"
                        v-if="show('material_type')"
                        clearable
                        label="Тип дома"
                    ></v-autocomplete>

                    <v-text-field
                        v-model.number="feed.ceiling_height"
                        v-if="show('ceiling_height')"
                        clearable
                        type="number"
                        label="Высота потолков, м"
                    ></v-text-field>

                    <v-text-field
                        v-model="feed.code"
                        v-if="show('phones')"
                        clearable
                        label="Код страны"
                    ></v-text-field>

                    <v-text-field
                        v-model="feed.number"
                        v-if="show('phones')"
                        clearable
                        label="Номер"
                    ></v-text-field>

                    <v-text-field
                        v-model.number="feed.passenger_lifts_count"
                        v-if="show('passenger_lifts_count')"
                        clearable
                        type="number"
                        label="Количество пассажирских лифтов"
                    ></v-text-field>

                    <v-text-field
                        v-model.number="feed.cargo_lifts_count"
                        v-if="show('cargo_lifts_count')"
                        clearable
                        type="number"
                        label="Количество грузовых лифтов"
                    ></v-text-field>
                </template>

                <!-- ЦИАН -->
                <div v-if="mKeys">
                      <div class='key-list-item'
                            v-for='(keyItem, index) in keyItems'
                            :key='index'
                            @click="insertKeyInText"
                            :title=keyItem.text
                           >
                                   {{ keyItem.value }}
                      </div>
                </div>
            </v-container>


        </template>


    </dialog-fullscreen>
</template>
<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue';
    import BaseWysiwyg from '@/components/fields/BaseWysiwyg';
    import yaVariables from "../../../store/yaVariables";
    import avitoVariables from "../../../store/avitoVariables";
    import cianVariables from "../../../store/cianVariables";
    import massiveOperations from "../../../store/massiveOperations";
    import feedVariables from "../../../store/feedVariables";

    export default {
        name: 'MassiveUpdateDialog',
        props: ['initialState', 'feeds', 'postAction', 'updateAll', 'housingId'],
        components: {'dialog-fullscreen': DialogFullscreen, BaseWysiwyg},
        data() {
            return {
                indicator: false,
                visibility: this.initialState,
                selectedPlatform: null,
                selectedField: null,
                activeTab: 0,
                activeSubTab: 7,
                validGeneralInfo: false,
                selectedOperation: {},
                _selectedOperation: {},
                ya: yaVariables.ya,
                operations: [...massiveOperations.operations],
                feed: {},
                buildingClass: false,
                avito: {
                    _additional: [],
                    ...avitoVariables.avito
                },
                cian: {
                    ...cianVariables.cian
                },
                yesNoChoices: [
                    {
                        value: 0,
                        text: 'Нет'
                    },
                    {
                        value: 1,
                        text: 'Да'
                    },
                ],

                mKeys: false,
                feedVariables: feedVariables,
                keyItems: [],
                currentCursorPosition: 0,

                rules: {
                    required: value => !!value || 'Обязательное поле.',
                    avito: {
                        email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный электронный адрес',
                        maxLength: v => v.length <= 40 || 'Макс 40 символов',
                        addressMaxLength: v => v.length <= 256 || 'Макс 256 символов',
                        descriptionMaxLength: v => v.length <= 5000 || 'Макс 5000 символов',
                    }
                },
            }
        },
        methods: {
            getAdditionalObjects() {
                if (this.feed.object_type) {
                    this.buildingClass = ["Офисное помещение", "Складское помещение"].includes(this.feed.object_type);
                    const object_type = this.avito.objectTypeChoices.find(el => el.value == this.feed.object_type);
                    this.avito._additional = this.avito.additionalObjectTypeChoices[object_type.typeId]
                } else {
                    this.buildingClass = false;
                    this.avito._additional = []
                }
            },
            print() {

                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                };
                let requestData = {
                    'login': this.$store.getters.userLogin,
                };
                let feed = JSON.parse(JSON.stringify(this.feed));
                // Object.keys(feed).forEach(k => {
                //     console.log(k, typeof k, feed[k])
                //     if (["undefined", "null"].includes(typeof (feed[k]))) {
                //         delete feed[k]
                //     } else if (typeof feed[k] == "object") {
                //         if (feed[k]) {
                //             if (!Object.keys(feed[k]).length) {
                //                 delete feed[k]
                //             }
                //         }
                //
                //     }
                // });
                // console.log(feed, !!Object.keys(feed).length)
                // if (!Object.keys(feed).length) {
                //     return;
                // }
                // console.log('asdfasdf')

                if (this.updateAll) {
                    const params = {
                        ...requestData,
                        update_all: true,
                        housing_id: this.housingId,
                        keys: feed,
                    }
                    if (this._selectedOperation.group_id !== 'base') {
                        params.platform = this._selectedOperation.group_id
                    }
                  this.$http.put(
                      this.$store.getters.endpoint + `/feeds/massive_update`,
                      params,
                      {headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}}
                  ).then((r) => {
                      let response = r.body;
                      if (response.result) {
                          this.visibility = false;
                          this.postAction();
                      }
                  })
                  return
                }
                
                if (this._selectedOperation.group_id == 'base') {
                    this.$http.put(
                        this.$store.getters.endpoint + `/feeds/massive_update`,
                        {...requestData, keys: feed, feeds: this.feeds.map(e => e.id)},
                        {headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}}
                    ).then((r) => {
                        let response = r.body;
                        if (response.result) {
                            this.visibility = false;
                            this.postAction();
                        } else {
                            console.log(response)
                        }
                    })
                } else if (this._selectedOperation.group_id == 'yandex') {
                    const newFeeds = {};
                    this.feeds.forEach(f => {
                        f.yandex = {
                            ...f.yandex,
                            ...feed
                        };
                        newFeeds[f.id] = f.yandex
                    });

                    this.$http.put(
                        this.$store.getters.endpoint + `/feeds/massive_update`,
                        {
                            ...requestData,
                            feeds: this.feeds.map(e => e.id),
                            platform: this._selectedOperation.group_id,
                            data: newFeeds
                        },
                        {headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}}
                    ).then((r) => {
                        let response = r.body;
                        if (response.result) {
                            this.visibility = false;
                            this.postAction();
                        } else {
                            console.log(response)
                        }
                    })
                } else if (this._selectedOperation.group_id == 'avito') {
                    const newFeeds = {};
                    this.feeds.forEach(f => {
                        f.avito = {
                            ...f.avito,
                            ...feed
                        };
                        newFeeds[f.id] = f.avito
                    });

                    this.$http.put(
                        this.$store.getters.endpoint + `/feeds/massive_update`,
                        {
                            ...requestData,
                            feeds: this.feeds.map(e => e.id),
                            platform: this._selectedOperation.group_id,
                            data: newFeeds
                        },
                        {headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}}
                    ).then((r) => {
                        let response = r.body;
                        if (response.result) {
                            this.visibility = false;
                            this.postAction();
                        } else {
                            console.log(response)
                        }
                    })
                } else if (this._selectedOperation.group_id == 'cian') {
                    if (this._selectedOperation.value == 'phones') {
                        feed = {
                            phones: [
                                {
                                    country_code: feed.code,
                                    number: feed.number
                                }
                            ]
                        }
                    }
                    const newFeeds = {};
                    this.feeds.forEach(f => {
                        f.cian = {
                            ...f.cian,
                            ...feed
                        };
                        newFeeds[f.id] = f.cian
                    });

                    this.$http.put(
                        this.$store.getters.endpoint + `/feeds/massive_update`,
                        {
                            ...requestData,
                            feeds: this.feeds.map(e => e.id),
                            platform: this._selectedOperation.group_id,
                            data: newFeeds
                        },
                        {headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}}
                    ).then((r) => {
                        let response = r.body;
                        if (response.result) {
                            this.visibility = false;
                            this.postAction();
                        } else {
                            console.log(response)
                        }
                    })
                }


            },
            fillDefaultValues() {
                this.selectedPlatform = null;
                this.selectedOperation = {};
                this._selectedOperation = {};
                this.fillFeedDefaultValues()
            },
            fillFeedDefaultValues() {
                this.feed = {}
            },
            copy(value) {
                return JSON.parse(JSON.stringify(value))
            },
            changeOperation() {
                this.fillFeedDefaultValues();
                const value = this.copy(this.selectedOperation)
                this._selectedOperation = this.copy(value);
                console.log(this._selectedOperation)
                this._selectedOperation.value = this._selectedOperation.value.replace(
                    `${this.selectedOperation.group_id}_`,
                    ''
                )
                this.feed[this._selectedOperation.value] = this._selectedOperation.defaultValue;
                if (this.feed[this._selectedOperation.value] == 'object_type') {
                    this.feed.building_class = null;
                    this.feed.additional_object_type = [];

                }
            },
            show(field) {
                return this._selectedOperation && this._selectedOperation.value == field;
            },

            changeDinamicKey(key) {
                console.log(this.feed)
                let text_description = this.feed.description
                this.currentCursorPosition = event.path[0].selectionStart
                if (key.keyCode == 192) {
                    if (!this.mKeys) {
                        this.mKeys = true
                    } else {
                        this.mKeys = false
                    }
                }

                this.keyItems = feedVariables.replaceKeys;
            },
            insertKeyInText(e) {
                let F = this.feed.description.substr(0, this.currentCursorPosition)
                let L = this.feed.description.substr(this.currentCursorPosition + 1)
                this.feed.description = F + e.target.textContent.trim() + L

                this.mKeys = false
            },

        },
        watch: {
            initialState(value) {
                if (value) {
                    this.visibility = value;
                    this.fillDefaultValues();
                }

            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
        },
        computed: {
            isSetable() {
                return this.feed.object_type && ["Офисное помещение", "Складское помещение"].includes(this.feed.object_type)
            }
        }
    }
</script>
