<template>
	<v-layout column align-center justify-center fill-height>
		<h2 class = 'font-weight-regular mt-6 text-center' :style = '`color:${$store.getters.uiAccent};`'>Такой страницы у нас нет <br> :(</h2>
		<img :src='require(`@/assets/not_found.gif`)' width = '300'>
		<h2 class = 'font-weight-regular mt-6 text-center'>
			Вернуться на <a href='javascript:void(0)' @click = '$router.push("/")' :style = '`color:${$store.getters.uiAccent};`'>главную</a>.
		</h2>
	</v-layout>
</template>

<script>
	export default {
		data() {
			return {
				'requested': this.$route.path
			}
		}
	}
</script>