<template>
  <div>
    <quill-editor
      ref="myQuillEditor"
      v-model="modelValue"
      :options="editorOption"
      placeholder="Текстовое описание"
      class="editor"
      :class="{ 'border-error': maxLength && count > maxLength }"
    />
    <div class="footer">
      {{ hint }}
      <span v-if="maxLength">{{ count }}/{{ maxLength }}</span>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'BaseWysiwyg',

  components: { quillEditor },

  props: {
    value: {
      type: String,
      default: ''
    },

    maxLength: {
      type: Number,
      default: null
    },

    hint: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    count: 0,
    editorOption: {
      modules: {
        toolbar: [
          ['bold'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      }
    }
  }),

  computed: {
    modelValue: {
      get() {
        return this.value
      },

      set(val) {
        if (this.maxLength) {
          this.getTextSymbolsCount()
        }
        // const textLength = console.log(textLength)
        this.$emit('input', val)
      }
    }
  },

  mounted() {
    this.getTextSymbolsCount()
  },

  methods: {
    getTextSymbolsCount() {
      this.count = this.$refs.myQuillEditor.quill.getText().length
    }
  }
}
</script>

<style>
.ql-container {
  height: 300px;
}

.border-error .ql-container,
.border-error .ql-toolbar {
  border-color: #ff5252;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>