<template>

    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>Массовые изменения позиций</template>
        <template v-slot:action>
            <v-btn
                text
                color='green darken-2'
                :disabled="!valid"
                @click="update"
            >
                Обновить
            </v-btn>
        </template>

        <template v-slot:content>
            <v-form ref="form" v-model='valid' style='min-width: 320px; width: 600px;' v-if="visibility">
                <v-select
                    :items="operations"
                    v-model="selectedOperation"
                    :rules="[rules.required]"
                    return-object
                    label="Выберите операцию"
                    @change="changeOperation"
                ></v-select>

                <v-text-field
                    v-model="position.name"
                    label="Название предложения"
                    :rules="[rules.required]"
                    clearable
                    v-if="show('name')"
                ></v-text-field>

                <v-text-field
                    v-model.number="position.price"
                    type="number"
                    label="Цена"
                    :rules="[rules.required]"
                    clearable
                    v-if="show('price')"
                ></v-text-field>

                <v-text-field
                    v-model.number="position.old_price"
                    type="number"
                    label="Старая цена"
                    :rules="[rules.required]"
                    clearable
                    v-if="show('old_price')"
                ></v-text-field>
                <v-text-field
                    v-model.number="position.purchase_price"
                    type="number"
                    label="Закупочная цена"
                    :rules="[rules.required]"
                    clearable
                    v-if="show('purchase_price')"
                ></v-text-field>

                <v-text-field
                    v-model.number="position.bid"
                    type="number"
                    label="Размер ставки"
                    :rules="[rules.required]"
                    clearable
                    v-if="show('bid')"
                ></v-text-field>

                <v-select
                    v-model="position.currency_id"
                    :items="currencies"
                    label="Валюта"
                    :rules="[rules.required]"
                    clearable
                    v-if="show('currency_id')"
                ></v-select>

                <v-text-field
                    v-model="position.vendor"
                    label="Производитель"
                    :rules="[rules.required]"
                    clearable
                    v-if="show('vendor')"
                ></v-text-field>

                <v-text-field
                    v-model="position.vendor_code"
                    label="Код товара, который ему присвоил производитель."
                    :rules="[rules.required]"
                    clearable
                    v-if="show('vendor_code')"
                ></v-text-field>

                <v-text-field
                    v-model="position.supplier"
                    label="ОГРН или ОГРНИП стороннего продавца."
                    clearable
                    v-if="show('supplier')"
                >
                    <v-tooltip
                        :open-on-hover="true"
                        top
                        slot="append-outer"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                        </template>
                        <div>
                            ОГРН должен содержать 13 символов, ОГРНИП — 15.<br>
                            Указывайте, если вы хотите размещать предложения других продавцов.
                        </div>
                    </v-tooltip>
                </v-text-field>

                <v-textarea
                    v-model="position.sales_notes"
                    label="Условия продажи товара"
                    maxlength="50"
                    clearable
                    v-if="show('sales_notes')"
                >
                    <v-tooltip
                        :open-on-hover="true"
                        top
                        slot="append-outer"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                        </template>
                        <div>
                            Элемент обязателен, если у вас есть ограничения при заказе товара <br>
                            (минимальная сумма заказа, минимальное количество товаров, необходимость предоплаты) <br>
                            и эти ограничения не указаны в личном кабинете.
                        </div>
                    </v-tooltip>
                </v-textarea>

                <template v-if="show('param')">
                    <p>
                        Характеристика товара
                        <v-btn depressed x-small @click="addParam">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>
                    <v-container
                        v-for="(p, i) in position.param"
                        :key="'param_' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-text-field
                            v-model="p.name"
                            label="Параметр"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.value"
                            label="Значение"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.unit"
                            label="Единица измерения"
                        >
                            <template slot="append">
                                <v-icon @click="removeParam(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>
                    </v-container>
                </template>

                <v-checkbox
                    v-model="position['store']"
                    indeterminate
                    label="Возможность купить товар без предварительного заказа"
                    v-if="show('store')"
                ></v-checkbox>

                <v-checkbox
                    v-model="position.manufacturer_warranty"
                    indeterminate
                    label="Официальная гарантия производителя"
                    v-if="show('manufacturer_warranty')"
                ></v-checkbox>

                <v-text-field
                    v-model.number="position.country_of_origin"
                    label="Страна производства товара."
                    clearable
                    v-if="show('country_of_origin')"
                ></v-text-field>

                <v-checkbox
                    v-model="position.adult"
                    indeterminate
                    label="Товар имеет отношение к удовлетворению сексуальных потребностей, либо иным образом эксплуатирует интерес к сексу"
                    v-if="show('adult')"
                ></v-checkbox>

                <v-textarea
                    v-model="position.barcode"
                    label="Штрихкод товара от производителя в одном из форматов: EAN-13, EAN-8, UPC-A, UPC-E."
                    clearable
                    v-if="show('barcode')"
                >
                    <v-tooltip
                        :open-on-hover="true"
                        top
                        slot="append-outer"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                        </template>
                        <div>
                            Данные в barcode влияют на привязку предложения к карточке товара <br>
                            и отображение правильных характеристик, которые соответствуют <br>
                            модификации товара на его карточке. <br>
                            Каждый код на отдельной строчке
                        </div>
                    </v-tooltip>
                </v-textarea>

                <v-text-field
                    v-model="position.expiry"
                    label="Срок годности или службы."
                    v-if="show('expiry')"
                    clearable
                >
                    <v-tooltip
                        :open-on-hover="true"
                        top
                        slot="append-outer"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                        </template>
                        <div>
                            Значение элемента должно соответствовать стандарту ISO 8601. <br>
                            Например: P1Y2M10DT2H — 1 год, 2 месяца, 10 дней, 2 часа.
                        </div>
                    </v-tooltip>
                </v-text-field>

                <v-text-field
                    v-model.number="position.weight"
                    type="number"
                    label="Вес товара в килограммах с учетом упаковки."
                    v-if="show('weight')"
                    clearable
                >
                    <v-tooltip
                        :open-on-hover="true"
                        top
                        slot="append-outer"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                        </template>
                        <div>
                            Для некоторых категорий установлены ограничения <br>
                            по минимальному или максимальному значению веса. <br>
                            В любой категории вес можно указывать с точностью до тысячных <br>
                            (например, 1.001 кг; разделитель целой и дробной части — точка).
                        </div>
                    </v-tooltip>
                </v-text-field>

                <v-text-field
                    v-model.number="position.length"
                    type="number"
                    label="Габариты товара - Длина"
                    v-if="show('length')"
                    clearable
                >
                    <v-tooltip
                        :open-on-hover="true"
                        top
                        slot="append-outer"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                        </template>
                        <div>
                            Размеры укажите в сантиметрах
                        </div>
                    </v-tooltip>
                </v-text-field>

                <v-text-field
                    v-model.number="position.width"
                    type="number"
                    label="Габариты товара - Ширина"
                    v-if="show('width')"
                    clearable
                >
                    <v-tooltip
                        :open-on-hover="true"
                        top
                        slot="append-outer"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                        </template>
                        <div>
                            Размеры укажите в сантиметрах
                        </div>
                    </v-tooltip>
                </v-text-field>

                <v-text-field
                    v-model.number="position.height"
                    type="number"
                    label="Габариты товара - Высота"
                    v-if="show('height')"
                    clearable
                >
                    <v-tooltip
                        :open-on-hover="true"
                        top
                        slot="append-outer"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                        </template>
                        <div>
                            Размеры укажите в сантиметрах
                        </div>
                    </v-tooltip>
                </v-text-field>

            </v-form>
        </template>

    </dialog-fullscreen>
</template>

<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue';
    export default {
        name: 'MassiveUpdateDialog',
        props: ['initialState', 'positions', 'postAction'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                indicator: false,
                valid: false,
                visibility: this.initialState,
                selectedOperation: null,
                indeterminate: true,
                position: {},
                rules: {
                    required: value => !!value || 'Обязательное поле.',
                },
                currencies: [
                    {
                        value: 'RUR',
                        text: 'RUR',
                    },
                    {
                        value: 'USD',
                        text: 'USD',
                    },
                    {
                        value: 'EUR',
                        text: 'EUR',
                    },
                    {
                        value: 'UAH',
                        text: 'UAH',
                    },
                    {
                        value: 'KZT',
                        text: 'KZT',
                    },
                    {
                        value: 'BYN',
                        text: 'BYN',
                    },
                ],
                operations: [
                    {
                        value: 'name',
                        text: 'Изменить название предложения/позиций',
                        defaultValue: '',
                    },
                    {
                        value: 'price',
                        text: 'Изменить цену',
                        defaultValue: null,
                    },
                    {
                        value: 'old_price',
                        text: 'Изменить старую цену',
                        defaultValue: null,
                    },
                    {
                        value: 'purchase_price',
                        text: 'Изменить закупочную цену',
                        defaultValue: null,
                    },
                    {
                        value: 'currency_id',
                        text: 'Изменить валюту',
                        defaultValue: null,
                    },
                    {
                        value: 'vendor',
                        text: 'Изменить производителя',
                        defaultValue: null,
                    },
                    {
                        value: 'vendor_code',
                        text: 'Изменить код товара, который ему присвоил производитель.',
                        defaultValue: null,
                    },
                    {
                        value: 'bid',
                        text: 'Изменить размер ставки',
                        defaultValue: null,
                    },
                    {
                        value: 'supplier',
                        text: 'Изменить ОГРН или ОГРНИП стороннего продавца',
                        defaultValue: null,
                    },
                    {
                        value: 'sales_notes',
                        text: 'Изменить условия продажи товаров',
                        defaultValue: null,
                    },
                    {
                        value: 'store',
                        text: 'Изменить возможность купить товар без предварительного заказа',
                        defaultValue: null,
                    },
                    {
                        value: 'manufacturer_warranty',
                        text: 'Изменить официальную гарантию производителя',
                        defaultValue: null,
                    },
                    {
                        value: 'country_of_origin',
                        text: 'Изменить изменить страну произволителя',
                        defaultValue: null,
                    },
                    {
                        value: 'adult',
                        text: 'Изменить отношение товара к удовлетворению сексуальных потребностей, либо иным образом эксплуатирует интерес к сексу',
                        defaultValue: null,
                    },
                    {
                        value: 'barcode',
                        text: 'Изменить штрихкод товара от производителя',
                        defaultValue: null,
                    },
                    {
                        value: 'expiry',
                        text: 'Изменить срок годности или службы',
                        defaultValue: null,
                    },
                    {
                        value: 'weight',
                        text: 'Изменить вес товаров',
                        defaultValue: null,
                    },
                    {
                        value: 'length',
                        text: 'Изменить длину товаров',
                        defaultValue: null,
                    },
                    {
                        value: 'width',
                        text: 'Изменить ширину товаров',
                        defaultValue: null,
                    },
                    {
                        value: 'height',
                        text: 'Изменить высоту товаров',
                        defaultValue: null,
                    },
                    // {
                    //     value: 'param',
                    //     text: 'Изменить характеристики товаров',
                    //     defaultValue: [],
                    // },
                ]
            }
        },
        methods: {
            addParam() {
                this.$set(this.position, 'param', [
                    ...this.position.param,
                    {
                        name: null,
                        unit: null,
                        value: null
                    }
                ])
                console.log(this.position)
            },
            removeParam(index) {
                this.position.param.splice(index, 1)
            },
            fillDefaultValues() {
                this.selectedOperation = null;
                this.fillPositionDefaultValues();
            },
            fillPositionDefaultValues() {
                this.position = {}
            },
            changeOperation() {
                this.fillPositionDefaultValues();
                this.position[this.selectedOperation.value] = this.selectedOperation.defaultValue
            },
            setDefaultValue() {
                this.indeterminate = false;
                this.position[this.selectedOperation.value] = this.selectedOperation.defaultValue;
                this.indeterminate = true;
                console.log(this.position)
            },
            show(field) {
                return this.selectedOperation && this.selectedOperation.value == field;
            },
            update() {
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                };
                let requestData = {
                    'login': this.$store.getters.userLogin,
                };
                let position = JSON.parse(JSON.stringify(this.position));
                this.$http.put(
                    this.$store.getters.endpoint + `/position/massive_update`,
                    {
                        ...requestData,
                        feeds: this.positions.map(e => e.id.toString()),
                        data: position
                    },
                    {headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}}
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.visibility = false;
                        this.postAction();
                    } else {
                        console.log(response)
                    }
                })
            }
        },
        watch: {
            initialState(value) {
                if (value) {
                    this.visibility = value;
                    this.fillDefaultValues();
                }

            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
        }
    }
</script>
