<template>
    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>

        <template v-slot:title>Редактирование товара</template>

        <template v-slot:action>
            <v-btn
                text
                :loading='indicator'
                color='green darken-2'
                @click='updateProduct'
            >
                Обновить
            </v-btn>
        </template>

        <template v-slot:content>
            <v-form ref="form" v-model='valid' style='min-width: 320px; width: 600px;' v-if="visibility">

                <v-select
                    v-model="product['type']"
                    :items="productTypes"
                    clearable
                    label="Тип предложения"
                    disabled
                >
                </v-select>

                <template v-if="!product['type']">
                    <v-text-field
                        v-model="product.name"
                        label="Полное название предложения"
                        :rules="[rules.required]"
                        clearable
                    >
                    </v-text-field>

                    <v-text-field
                        v-model="product.vendor"
                        label="Название производителя."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.vendor_code"
                        label="Код товара, который ему присвоил производитель."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.bid"
                        type="number"
                        label="Размер ставки."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.url"
                        label="URL страницы товара на сайте магазина."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.price"
                        type="number"
                        label="Актуальная цена товара."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.old_price"
                        type="number"
                        label="Старая цена товара, должна быть выше текущей."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.purchase_price"
                        type="number"
                        label="Закупочная цена товара."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Она нужна для расчета наценки и настройки стратегии по маржинальности в PriceLabs.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.enable_auto_discounts"
                        label="Автоматический расчет и показ скидок для предложения."
                    ></v-checkbox>

                    <v-select
                        v-model="product.currency_id"
                        :items="currencies"
                        label="Валюта, в которой указана цена товара"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Цена и валюта должны соответствовать друг другу. <br>
                                Например, вместе с USD надо указывать цену в долларах, а не в рублях.
                            </div>
                        </v-tooltip>
                    </v-select>

                    <v-text-field
                        v-model.number="product.count"
                        type="number"
                        label="Count"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>


                    <v-select
                        v-model="product.category_id"
                        label="Идентификатор категории товара"
                        :items="categories"
                        clearable
                    ></v-select>

                    <v-textarea
                        v-model="product.picture"
                        label="URL-ссылка на картинку товара."
                        :rules="[rules.required]"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Ссылки на картинку<br>
                                Каждая ссылка на отдельной строчке <br>
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.supplier"
                        label="ОГРН или ОГРНИП стороннего продавца."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                ОГРН должен содержать 13 символов, ОГРНИП — 15.<br>
                                Указывайте, если вы хотите размещать предложения других продавцов.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.delivery"
                        label="Возможность курьерской доставки (по всем регионам, в которые доставляет магазин)."
                    ></v-checkbox>

                    <v-checkbox
                        v-model="product.pickup"
                        label="Возможность самовывоза из пунктов выдачи (во всех регионах, в которые доставляет магазин)."
                        clearable
                    ></v-checkbox>

                    <v-checkbox
                        v-model="product['store']"
                        label="Возможность купить товар без предварительного заказа."
                        clearable
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.description"
                        label="Описание предложения"
                        clearable
                    >
                    </v-textarea>

                    <v-textarea
                        v-model="product.sales_notes"
                        label="Условия продажи товара"
                        maxlength="50"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Элемент обязателен, если у вас есть ограничения при заказе товара <br>
                                (минимальная сумма заказа, минимальное количество товаров, необходимость предоплаты) <br>
                                и эти ограничения не указаны в личном кабинете.
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.min_quantity"
                        label="Минимальное количество одинаковых товаров в заказе"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Минимальное количество одинаковых товаров в заказе <br>
                                (для случаев, когда покупка возможна только комплектом, а не поштучно). <br>
                                Элемент используется только в категориях Автошины, Грузовые шины, Мотошины, Диски. <br>
                                Если элемент не указан, используется значение по умолчанию — 1.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.manufacturer_warranty"
                        label="Официальная гарантия производителя"
                    ></v-checkbox>

                    <v-text-field
                        v-model.number="product.country_of_origin"
                        label="Страна производства товара."
                        clearable
                    ></v-text-field>

                    <v-checkbox
                        v-model="product.adult"
                        label="Товар имеет отношение к удовлетворению сексуальных потребностей, либо иным образом эксплуатирует интерес к сексу."
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.barcode"
                        label="Штрихкод товара от производителя в одном из форматов: EAN-13, EAN-8, UPC-A, UPC-E."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Данные в barcode влияют на привязку предложения к карточке товара <br>
                                и отображение правильных характеристик, которые соответствуют <br>
                                модификации товара на его карточке. <br>
                                Каждый код на отдельной строчке
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.vat"
                        label="VAT"
                        clearable
                    ></v-text-field>

                    <p>
                        Характеристика товара
                        <v-btn depressed x-small @click="addParam">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        v-for="(p, i) in product.param"
                        :key="'param_' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-text-field
                            v-model="p.name"
                            label="Параметр"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.value"
                            label="Значение"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.unit"
                            label="Единица измерения"
                        >
                            <template slot="append">
                                <v-icon @click="removeParam(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>
                    </v-container>

                    <p>
                        Состояние товара
                        <v-btn depressed x-small @click="addCondition">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        fluid
                        v-for="(c, i) in product.condition"
                        :key="'condition' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-select
                            v-model="c['type']"
                            :items="conditionTypes"
                            label="Состояние товара"
                        ></v-select>

                        <v-text-field
                            v-model="c.reason"
                            label="Причина уценки"
                        >
                            <template slot="append">
                                <v-icon @click="removeCondition(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>

                    </v-container>

                    <v-text-field
                        v-model="product.expiry"
                        label="Срок годности или службы."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Значение элемента должно соответствовать стандарту ISO 8601. <br>
                                Например: P1Y2M10DT2H — 1 год, 2 месяца, 10 дней, 2 часа.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.weight"
                        type="number"
                        label="Вес товара в килограммах с учетом упаковки."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Для некоторых категорий установлены ограничения <br>
                                по минимальному или максимальному значению веса. <br>
                                В любой категории вес можно указывать с точностью до тысячных <br>
                                (например, 1.001 кг; разделитель целой и дробной части — точка).
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.length"
                        type="number"
                        label="Габариты товара - Длина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.width"
                        type="number"
                        label="Габариты товара - Ширина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.height"
                        type="number"
                        label="Габариты товара - Высота"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.downloadable"
                        label="Продукт можно скачать только с предоплатой."
                    ></v-checkbox>

                    <v-text-field
                        v-model="product.group_id"
                        label="Group id"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Элемент объединяет все предложения, которые являются вариациями <br>
                                одной модели и должен иметь одинаковое значение. <br>
                                Значение должно быть целым числом, максимум 9 знаков.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                </template>

                <template v-if="product['type'] == 'vendor.model'">

                    <v-text-field
                        v-model="product.model"
                        label="Модель и название товара."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.vendor"
                        label="Производитель"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.type_prefix"
                        label="Тип / категория товара"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.vendor_code"
                        label="Код товара, который ему присвоил производитель."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.bid"
                        type="number"
                        label="Размер ставки."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.url"
                        label="URL страницы товара на сайте магазина."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.price"
                        type="number"
                        label="Актуальная цена товара."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.old_price"
                        type="number"
                        label="Старая цена товара, должна быть выше текущей."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.purchase_price"
                        type="number"
                        label="Закупочная цена товара."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Она нужна для расчета наценки и настройки стратегии по маржинальности в PriceLabs.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.enable_auto_discounts"
                        label="Автоматический расчет и показ скидок для предложения."
                    ></v-checkbox>

                    <v-select
                        v-model="product.currency_id"
                        :items="currencies"
                        label="Валюта, в которой указана цена товара"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Цена и валюта должны соответствовать друг другу. <br>
                                Например, вместе с USD надо указывать цену в долларах, а не в рублях.
                            </div>
                        </v-tooltip>
                    </v-select>

                    <v-text-field
                        v-model.number="product.count"
                        type="number"
                        label="Count"
                        clearable
                    ></v-text-field>

                    <v-select
                        v-model="product.category_id"
                        label="Идентификатор категории товара"
                        :items="categories"
                        clearable
                    ></v-select>

                    <v-textarea
                        v-model="product.picture"
                        label="URL-ссылка на картинку товара."
                        :rules="[rules.required]"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Ссылки на картинку<br>
                                Каждая ссылка на отдельной строчке <br>
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.supplier"
                        label="ОГРН или ОГРНИП стороннего продавца."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                ОГРН должен содержать 13 символов, ОГРНИП — 15.<br>
                                Указывайте, если вы хотите размещать предложения других продавцов.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.delivery"
                        label="Возможность курьерской доставки (по всем регионам, в которые доставляет магазин)."
                    ></v-checkbox>

                    <v-checkbox
                        v-model="product.pickup"
                        label="Возможность самовывоза из пунктов выдачи (во всех регионах, в которые доставляет магазин)."
                        clearable
                    ></v-checkbox>

                    <v-checkbox
                        v-model="product['store']"
                        label="Возможность купить товар без предварительного заказа."
                        clearable
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.description"
                        label="Описание предложения"
                        clearable
                    ></v-textarea>

                    <v-textarea
                        v-model="product.sales_notes"
                        label="Условия продажи товара"
                        maxlength="50"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Элемент обязателен, если у вас есть ограничения при заказе товара <br>
                                (минимальная сумма заказа, минимальное количество товаров, необходимость предоплаты) <br>
                                и эти ограничения не указаны в личном кабинете.
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.min_quantity"
                        label="Минимальное количество одинаковых товаров в заказе"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Минимальное количество одинаковых товаров в заказе <br>
                                (для случаев, когда покупка возможна только комплектом, а не поштучно). <br>
                                Элемент используется только в категориях Автошины, Грузовые шины, Мотошины, Диски. <br>
                                Если элемент не указан, используется значение по умолчанию — 1.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.manufacturer_warranty"
                        label="Официальная гарантия производителя"
                    ></v-checkbox>

                    <v-text-field
                        v-model.number="product.country_of_origin"
                        label="Страна производства товара."
                        clearable
                    ></v-text-field>

                    <v-checkbox
                        v-model="product.adult"
                        label="Товар имеет отношение к удовлетворению сексуальных потребностей, либо иным образом эксплуатирует интерес к сексу."
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.barcode"
                        label="Штрихкод товара от производителя в одном из форматов: EAN-13, EAN-8, UPC-A, UPC-E."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Данные в barcode влияют на привязку предложения к карточке товара <br>
                                и отображение правильных характеристик, которые соответствуют <br>
                                модификации товара на его карточке. <br>
                                Каждый код на отдельной строчке
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.vat"
                        label="VAT"
                        clearable
                    ></v-text-field>

                    <p>
                        Характеристика товара
                        <v-btn depressed x-small @click="addParam">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        v-for="(p, i) in product.param"
                        :key="'param_' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-text-field
                            v-model="p.name"
                            label="Параметр"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.value"
                            label="Значение"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.unit"
                            label="Единица измерения"
                        >
                            <template slot="append">
                                <v-icon @click="removeParam(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>
                    </v-container>

                    <p>
                        Состояние товара
                        <v-btn depressed x-small @click="addCondition">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        fluid
                        v-for="(c, i) in product.condition"
                        :key="'condition' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-select
                            v-model="c['type']"
                            :items="conditionTypes"
                            label="Состояние товара"
                        ></v-select>

                        <v-text-field
                            v-model="c.reason"
                            label="Причина уценки"
                        >
                            <template slot="append">
                                <v-icon @click="removeCondition(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>

                    </v-container>

                </template>

                <template v-if="product['type'] == 'alco'">
                    <v-text-field
                        v-model="product.name"
                        label="Полное название предложения"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.vendor"
                        label="Название производителя."
                        clearable
                    ></v-text-field>

                    <v-checkbox
                        v-model="product.pickup"
                        label="Наличие товара"
                        clearable
                        :rules="[rules.required]"
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.barcode"
                        label="Штрихкод товара от производителя в одном из форматов: EAN-13, EAN-8, UPC-A, UPC-E."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Данные в barcode влияют на привязку предложения к карточке товара <br>
                                и отображение правильных характеристик, которые соответствуют <br>
                                модификации товара на его карточке. <br>
                                Каждый код на отдельной строчке
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <p>
                        Характеристика товара
                        <v-btn depressed x-small @click="addParam">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        v-for="(p, i) in product.param"
                        :key="'param_' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-text-field
                            v-model="p.name"
                            label="Параметр"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.value"
                            label="Значение"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.unit"
                            label="Единица измерения"
                        >
                            <template slot="append">
                                <v-icon @click="removeParam(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>
                    </v-container>

                    <p>
                        Состояние товара
                        <v-btn depressed x-small @click="addCondition">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        fluid
                        v-for="(c, i) in product.condition"
                        :key="'condition' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-select
                            v-model="c['type']"
                            :items="conditionTypes"
                            label="Состояние товара"
                        ></v-select>

                        <v-text-field
                            v-model="c.reason"
                            label="Причина уценки"
                        >
                            <template slot="append">
                                <v-icon @click="removeCondition(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>

                    </v-container>

                    <v-text-field
                        v-model.number="product.bid"
                        type="number"
                        label="Размер ставки."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.url"
                        label="URL страницы товара на сайте магазина."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-select
                        v-model="product.currency_id"
                        :items="currencies"
                        label="Валюта, в которой указана цена товара"
                        :rules="[rules.required]"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Цена и валюта должны соответствовать друг другу. <br>
                                Например, вместе с USD надо указывать цену в долларах, а не в рублях.
                            </div>
                        </v-tooltip>
                    </v-select>

                    <v-select
                        v-model="product.category_id"
                        label="Идентификатор категории товара"
                        :items="categories"
                        :rules="[rules.required]"
                        clearable
                    ></v-select>

                    <v-textarea
                        v-model="product.picture"
                        label="URL-ссылка на картинку товара."
                        :rules="[rules.required]"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Ссылки на картинку<br>
                                Каждая ссылка на отдельной строчке <br>
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model.number="product.price"
                        type="number"
                        label="Актуальная цена товара."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.old_price"
                        type="number"
                        label="Старая цена товара, должна быть выше текущей."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.purchase_price"
                        type="number"
                        label="Закупочная цена товара."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Она нужна для расчета наценки и настройки стратегии по маржинальности в PriceLabs.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model="product.vendor_code"
                        label="Код товара, который ему присвоил производитель."
                        clearable
                    ></v-text-field>

                    <v-checkbox
                        v-model="product.enable_auto_discounts"
                        label="Автоматический расчет и показ скидок для предложения."
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.description"
                        label="Описание предложения"
                        clearable
                    ></v-textarea>

                    <v-textarea
                        v-model="product.sales_notes"
                        label="Условия продажи товара"
                        maxlength="50"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Элемент обязателен, если у вас есть ограничения при заказе товара <br>
                                (минимальная сумма заказа, минимальное количество товаров, необходимость предоплаты) <br>
                                и эти ограничения не указаны в личном кабинете.
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model.number="product.country_of_origin"
                        label="Страна производства товара."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.expiry"
                        label="Срок годности или службы."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Значение элемента должно соответствовать стандарту ISO 8601. <br>
                                Например: P1Y2M10DT2H — 1 год, 2 месяца, 10 дней, 2 часа.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.weight"
                        type="number"
                        label="Вес товара в килограммах с учетом упаковки."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Для некоторых категорий установлены ограничения <br>
                                по минимальному или максимальному значению веса. <br>
                                В любой категории вес можно указывать с точностью до тысячных <br>
                                (например, 1.001 кг; разделитель целой и дробной части — точка).
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.length"
                        type="number"
                        label="Габариты товара - Длина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.width"
                        type="number"
                        label="Габариты товара - Ширина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.height"
                        type="number"
                        label="Габариты товара - Высота"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>
                </template>

                <template v-if="product['type'] == 'audiobook'">
                    <v-text-field
                        v-model="product.name"
                        label="Полное название предложения"
                        :rules="[rules.required]"
                        clearable
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="product.publisher"
                        label="Издательство"
                        :rules="[rules.required]"
                        clearable
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="product.isbn"
                        label="International Standard Book Number"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.author"
                        label="Автор произведения"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.series"
                        label="Серия"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model.number="product.year"
                        type="number"
                        label="Год издания"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model.number="product.volume"
                        type="number"
                        label="Общее количество томов, если издание состоит из нескольких томов"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model.number="product.part"
                        type="number"
                        label="Укажите номер тома, если издание состоит из нескольких томов"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.language"
                        label="Язык, на котором издано произведение."
                        clearable
                    ></v-text-field>
                    <v-textarea
                        v-model="product.table_of_contents"
                        label="Оглавление"
                        clearable
                    ></v-textarea>
                    <v-text-field
                        v-model="product.performed_by"
                        label="Исполнитель. Если их несколько, перечисляются через запятую"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.performance_type"
                        label="Тип аудиокниги (радиоспектакль, «произведение начитано» и т. п.)."
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.storage"
                        label="Носитель аудиокниги."
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product['format']"
                        label="Формат аудиокниги."
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.recording_length"
                        label="Время звучания, задается в формате mm.ss (минуты.секунды)."
                        clearable
                    ></v-text-field>
                   <!--  age -->

                    <v-text-field
                        v-model.number="product.bid"
                        type="number"
                        label="Размер ставки."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.url"
                        label="URL страницы товара на сайте магазина."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.price"
                        type="number"
                        label="Актуальная цена товара."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.old_price"
                        type="number"
                        label="Старая цена товара, должна быть выше текущей."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.purchase_price"
                        type="number"
                        label="Закупочная цена товара."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Она нужна для расчета наценки и настройки стратегии по маржинальности в PriceLabs.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.enable_auto_discounts"
                        label="Автоматический расчет и показ скидок для предложения."
                    ></v-checkbox>

                    <v-select
                        v-model="product.currency_id"
                        :items="currencies"
                        label="Валюта, в которой указана цена товара"
                        clearable
                        :rules="[rules.required]"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Цена и валюта должны соответствовать друг другу. <br>
                                Например, вместе с USD надо указывать цену в долларах, а не в рублях.
                            </div>
                        </v-tooltip>
                    </v-select>

                    <v-text-field
                        v-model.number="product.count"
                        type="number"
                        label="Count"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>


                    <v-select
                        v-model="product.category_id"
                        label="Идентификатор категории товара"
                        :items="categories"
                        clearable
                    ></v-select>

                    <v-textarea
                        v-model="product.picture"
                        label="URL-ссылка на картинку товара."
                        :rules="[rules.required]"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Ссылки на картинку<br>
                                Каждая ссылка на отдельной строчке <br>
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.supplier"
                        label="ОГРН или ОГРНИП стороннего продавца."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                ОГРН должен содержать 13 символов, ОГРНИП — 15.<br>
                                Указывайте, если вы хотите размещать предложения других продавцов.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.delivery"
                        label="Возможность курьерской доставки (по всем регионам, в которые доставляет магазин)."
                    ></v-checkbox>

                    <v-checkbox
                        v-model="product.pickup"
                        label="Возможность самовывоза из пунктов выдачи (во всех регионах, в которые доставляет магазин)."
                        clearable
                    ></v-checkbox>

                    <v-checkbox
                        v-model="product['store']"
                        label="Возможность купить товар без предварительного заказа."
                        clearable
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.description"
                        label="Описание предложения"
                        clearable
                    >
                    </v-textarea>

                    <v-textarea
                        v-model="product.sales_notes"
                        label="Условия продажи товара"
                        maxlength="50"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Элемент обязателен, если у вас есть ограничения при заказе товара <br>
                                (минимальная сумма заказа, минимальное количество товаров, необходимость предоплаты) <br>
                                и эти ограничения не указаны в личном кабинете.
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-checkbox
                        v-model="product.manufacturer_warranty"
                        label="Официальная гарантия производителя"
                    ></v-checkbox>

                    <v-text-field
                        v-model.number="product.country_of_origin"
                        label="Страна производства товара."
                        clearable
                    ></v-text-field>

                    <v-checkbox
                        v-model="product.adult"
                        label="Товар имеет отношение к удовлетворению сексуальных потребностей, либо иным образом эксплуатирует интерес к сексу."
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.barcode"
                        label="Штрихкод товара от производителя в одном из форматов: EAN-13, EAN-8, UPC-A, UPC-E."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Данные в barcode влияют на привязку предложения к карточке товара <br>
                                и отображение правильных характеристик, которые соответствуют <br>
                                модификации товара на его карточке. <br>
                                Каждый код на отдельной строчке
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <p>
                        Характеристика товара
                        <v-btn depressed x-small @click="addParam">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        v-for="(p, i) in product.param"
                        :key="'param_' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-text-field
                            v-model="p.name"
                            label="Параметр"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.value"
                            label="Значение"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.unit"
                            label="Единица измерения"
                        >
                            <template slot="append">
                                <v-icon @click="removeParam(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>
                    </v-container>

                    <p>
                        Состояние товара
                        <v-btn depressed x-small @click="addCondition">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        fluid
                        v-for="(c, i) in product.condition"
                        :key="'condition' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-select
                            v-model="c['type']"
                            :items="conditionTypes"
                            label="Состояние товара"
                        ></v-select>

                        <v-text-field
                            v-model="c.reason"
                            label="Причина уценки"
                        >
                            <template slot="append">
                                <v-icon @click="removeCondition(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>

                    </v-container>

                    <v-text-field
                        v-model="product.expiry"
                        label="Срок годности или службы."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Значение элемента должно соответствовать стандарту ISO 8601. <br>
                                Например: P1Y2M10DT2H — 1 год, 2 месяца, 10 дней, 2 часа.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.weight"
                        type="number"
                        label="Вес товара в килограммах с учетом упаковки."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Для некоторых категорий установлены ограничения <br>
                                по минимальному или максимальному значению веса. <br>
                                В любой категории вес можно указывать с точностью до тысячных <br>
                                (например, 1.001 кг; разделитель целой и дробной части — точка).
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.length"
                        type="number"
                        label="Габариты товара - Длина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.width"
                        type="number"
                        label="Габариты товара - Ширина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.height"
                        type="number"
                        label="Габариты товара - Высота"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.downloadable"
                        label="Продукт можно скачать только с предоплатой."
                    ></v-checkbox>

                </template>

                <template v-if="product['type'] == 'event-ticket'">
                    <v-text-field
                        v-model="product.name"
                        label="Название мероприятия"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.place"
                        label="Место проведения"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.hall"
                        label="Зал"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.hall_part"
                        label="Ряд и место в зале"
                        clearable
                    ></v-text-field>
                </template>

                <template v-if="product['type'] == 'book'">
                    <v-text-field
                        v-model="product.name"
                        label="Полное название предложения"
                        :rules="[rules.required]"
                        clearable
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="product.publisher"
                        label="Издательство"
                        :rules="[rules.required]"
                        clearable
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="product.isbn"
                        label="International Standard Book Number"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.author"
                        label="Автор произведения"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.series"
                        label="Серия"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model.number="product.year"
                        type="number"
                        label="Год издания"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model.number="product.volume"
                        type="number"
                        label="Общее количество томов, если издание состоит из нескольких томов"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model.number="product.part"
                        type="number"
                        label="Укажите номер тома, если издание состоит из нескольких томов"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.language"
                        label="Язык, на котором издано произведение."
                        clearable
                    ></v-text-field>
                    <v-textarea
                        v-model="product.table_of_contents"
                        label="Оглавление"
                        clearable
                    ></v-textarea>
                    <v-text-field
                        v-model="product.binding"
                        label="Формат"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model.number="product.page_extent"
                        type="number"
                        label="Количество страниц в книге, должно быть целым положительным числом."
                        clearable
                    ></v-text-field>
                    <!--  age  -->

                    <v-text-field
                        v-model.number="product.bid"
                        type="number"
                        label="Размер ставки."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.url"
                        label="URL страницы товара на сайте магазина."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.price"
                        type="number"
                        label="Актуальная цена товара."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.old_price"
                        type="number"
                        label="Старая цена товара, должна быть выше текущей."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.purchase_price"
                        type="number"
                        label="Закупочная цена товара."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Она нужна для расчета наценки и настройки стратегии по маржинальности в PriceLabs.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.enable_auto_discounts"
                        label="Автоматический расчет и показ скидок для предложения."
                    ></v-checkbox>

                    <v-select
                        v-model="product.currency_id"
                        :items="currencies"
                        label="Валюта, в которой указана цена товара"
                        clearable
                        :rules="[rules.required]"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Цена и валюта должны соответствовать друг другу. <br>
                                Например, вместе с USD надо указывать цену в долларах, а не в рублях.
                            </div>
                        </v-tooltip>
                    </v-select>

                    <v-text-field
                        v-model.number="product.count"
                        type="number"
                        label="Count"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>


                    <v-select
                        v-model="product.category_id"
                        label="Идентификатор категории товара"
                        :items="categories"
                        :rules="[rules.required]"
                        clearable
                    ></v-select>

                    <v-textarea
                        v-model="product.picture"
                        label="URL-ссылка на картинку товара."
                        :rules="[rules.required]"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Ссылки на картинку<br>
                                Каждая ссылка на отдельной строчке <br>
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.supplier"
                        label="ОГРН или ОГРНИП стороннего продавца."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                ОГРН должен содержать 13 символов, ОГРНИП — 15.<br>
                                Указывайте, если вы хотите размещать предложения других продавцов.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.delivery"
                        label="Возможность курьерской доставки (по всем регионам, в которые доставляет магазин)."
                    ></v-checkbox>

                    <v-checkbox
                        v-model="product.pickup"
                        label="Возможность самовывоза из пунктов выдачи (во всех регионах, в которые доставляет магазин)."
                        clearable
                    ></v-checkbox>

                    <v-checkbox
                        v-model="product['store']"
                        label="Возможность купить товар без предварительного заказа."
                        clearable
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.description"
                        label="Описание предложения"
                        clearable
                    >
                    </v-textarea>

                    <v-textarea
                        v-model="product.sales_notes"
                        label="Условия продажи товара"
                        maxlength="50"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Элемент обязателен, если у вас есть ограничения при заказе товара <br>
                                (минимальная сумма заказа, минимальное количество товаров, необходимость предоплаты) <br>
                                и эти ограничения не указаны в личном кабинете.
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-checkbox
                        v-model="product.manufacturer_warranty"
                        label="Официальная гарантия производителя"
                    ></v-checkbox>

                    <v-text-field
                        v-model.number="product.country_of_origin"
                        label="Страна производства товара."
                        clearable
                    ></v-text-field>

                    <v-checkbox
                        v-model="product.adult"
                        label="Товар имеет отношение к удовлетворению сексуальных потребностей, либо иным образом эксплуатирует интерес к сексу."
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.barcode"
                        label="Штрихкод товара от производителя в одном из форматов: EAN-13, EAN-8, UPC-A, UPC-E."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Данные в barcode влияют на привязку предложения к карточке товара <br>
                                и отображение правильных характеристик, которые соответствуют <br>
                                модификации товара на его карточке. <br>
                                Каждый код на отдельной строчке
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <p>
                        Характеристика товара
                        <v-btn depressed x-small @click="addParam">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        v-for="(p, i) in product.param"
                        :key="'param_' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-text-field
                            v-model="p.name"
                            label="Параметр"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.value"
                            label="Значение"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.unit"
                            label="Единица измерения"
                        >
                            <template slot="append">
                                <v-icon @click="removeParam(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>
                    </v-container>

                    <p>
                        Состояние товара
                        <v-btn depressed x-small @click="addCondition">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        fluid
                        v-for="(c, i) in product.condition"
                        :key="'condition' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-select
                            v-model="c['type']"
                            :items="conditionTypes"
                            label="Состояние товара"
                        ></v-select>

                        <v-text-field
                            v-model="c.reason"
                            label="Причина уценки"
                        >
                            <template slot="append">
                                <v-icon @click="removeCondition(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>

                    </v-container>

                    <v-text-field
                        v-model="product.expiry"
                        label="Срок годности или службы."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Значение элемента должно соответствовать стандарту ISO 8601. <br>
                                Например: P1Y2M10DT2H — 1 год, 2 месяца, 10 дней, 2 часа.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.weight"
                        type="number"
                        label="Вес товара в килограммах с учетом упаковки."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Для некоторых категорий установлены ограничения <br>
                                по минимальному или максимальному значению веса. <br>
                                В любой категории вес можно указывать с точностью до тысячных <br>
                                (например, 1.001 кг; разделитель целой и дробной части — точка).
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.length"
                        type="number"
                        label="Габариты товара - Длина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.width"
                        type="number"
                        label="Габариты товара - Ширина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.height"
                        type="number"
                        label="Габариты товара - Высота"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.downloadable"
                        label="Продукт можно скачать только с предоплатой."
                    ></v-checkbox>

                </template>

                <template v-if="product['type'] == 'medicine'">
                    <v-checkbox
                        v-model="product.pickup"
                        label="Возможность забрать товар в пункте выдачи (самовывоз)."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Для рецептурных лекарств значение обязательно должно быть <b>включено</b>
                            </div>
                        </v-tooltip>
                    </v-checkbox>

                    <v-checkbox
                        v-model="product.delivery"
                        label="Возможность курьерской доставки товара."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Для рецептурных лекарств значение обязательно должно быть <b>выключено</b>
                            </div>
                        </v-tooltip>
                    </v-checkbox>

                    <p>
                        Характеристика товара
                        <v-btn depressed x-small @click="addParam">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        v-for="(p, i) in product.param"
                        :key="'param_' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-text-field
                            v-model="p.name"
                            label="Параметр"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.value"
                            label="Значение"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.unit"
                            label="Единица измерения"
                        >
                            <template slot="append">
                                <v-icon @click="removeParam(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>
                    </v-container>

                    <v-text-field
                        v-model="product.name"
                        label="Полное название предложения"
                        :rules="[rules.required]"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Тип товара (БАД, лекарственный препарат, медицинское изделие и т. п.) + торговое название препарата.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model="product.vendor"
                        label="Название производителя."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.vendor_code"
                        label="Код товара, который ему присвоил производитель."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.bid"
                        type="number"
                        label="Размер ставки."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.url"
                        label="URL страницы товара на сайте магазина."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.price"
                        type="number"
                        label="Актуальная цена товара."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.old_price"
                        type="number"
                        label="Старая цена товара, должна быть выше текущей."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.purchase_price"
                        type="number"
                        label="Закупочная цена товара."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Она нужна для расчета наценки и настройки стратегии по маржинальности в PriceLabs.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.enable_auto_discounts"
                        label="Автоматический расчет и показ скидок для предложения."
                    ></v-checkbox>

                    <v-select
                        v-model="product.currency_id"
                        :items="currencies"
                        label="Валюта, в которой указана цена товара"
                        :rules="[rules.required]"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Цена и валюта должны соответствовать друг другу. <br>
                                Например, вместе с USD надо указывать цену в долларах, а не в рублях.
                            </div>
                        </v-tooltip>
                    </v-select>
                    <v-select
                        v-model="product.category_id"
                        label="Идентификатор категории товара"
                        :items="categories"
                        :rules="[rules.required]"
                        clearable
                    ></v-select>
                    <v-textarea
                        v-model="product.picture"
                        label="URL-ссылка на картинку товара."
                        :rules="[rules.required]"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Ссылки на картинку<br>
                                Каждая ссылка на отдельной строчке <br>
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-checkbox
                        v-model="product['store']"
                        label="Возможность купить товар без предварительного заказа."
                        clearable
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.description"
                        label="Описание предложения"
                        clearable
                    >
                    </v-textarea>

                    <v-textarea
                        v-model="product.sales_notes"
                        label="Условия продажи товара"
                        maxlength="50"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Элемент обязателен, если у вас есть ограничения при заказе товара <br>
                                (минимальная сумма заказа, минимальное количество товаров, необходимость предоплаты) <br>
                                и эти ограничения не указаны в личном кабинете.
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.country_of_origin"
                        label="Страна производства товара."
                        clearable
                    ></v-text-field>

                    <v-textarea
                        v-model="product.barcode"
                        label="Штрихкод товара от производителя в одном из форматов: EAN-13, EAN-8, UPC-A, UPC-E."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Данные в barcode влияют на привязку предложения к карточке товара <br>
                                и отображение правильных характеристик, которые соответствуют <br>
                                модификации товара на его карточке. <br>
                                Каждый код на отдельной строчке
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.expiry"
                        label="Срок годности или службы."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Значение элемента должно соответствовать стандарту ISO 8601. <br>
                                Например: P1Y2M10DT2H — 1 год, 2 месяца, 10 дней, 2 часа.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.weight"
                        type="number"
                        label="Вес товара в килограммах с учетом упаковки."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Для некоторых категорий установлены ограничения <br>
                                по минимальному или максимальному значению веса. <br>
                                В любой категории вес можно указывать с точностью до тысячных <br>
                                (например, 1.001 кг; разделитель целой и дробной части — точка).
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.length"
                        type="number"
                        label="Габариты товара - Длина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.width"
                        type="number"
                        label="Габариты товара - Ширина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.height"
                        type="number"
                        label="Габариты товара - Высота"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                </template>

                <template v-if="product['type'] == 'artist.title'">
                    <v-text-field
                        v-model="product.artist"
                        label="Исполнитель"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.title"
                        label="Название"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model.number="product.year"
                        type="number"
                        label="Год выпуска"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.media"
                        label="Носитель"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.starring"
                        label="Актеры"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.director"
                        label="Режиссер"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.original_name"
                        label="Режиссер"
                        clearable
                    ></v-text-field>
                    <v-text-field
                        v-model="product.country"
                        label="Страна исполнителя"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.bid"
                        type="number"
                        label="Размер ставки."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model="product.url"
                        label="URL страницы товара на сайте магазина."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.price"
                        type="number"
                        label="Актуальная цена товара."
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.old_price"
                        type="number"
                        label="Старая цена товара, должна быть выше текущей."
                        clearable
                    ></v-text-field>

                    <v-text-field
                        v-model.number="product.purchase_price"
                        type="number"
                        label="Закупочная цена товара."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Она нужна для расчета наценки и настройки стратегии по маржинальности в PriceLabs.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.enable_auto_discounts"
                        label="Автоматический расчет и показ скидок для предложения."
                    ></v-checkbox>

                    <v-select
                        v-model="product.currency_id"
                        :items="currencies"
                        label="Валюта, в которой указана цена товара"
                        :rules="[rules.required]"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Цена и валюта должны соответствовать друг другу. <br>
                                Например, вместе с USD надо указывать цену в долларах, а не в рублях.
                            </div>
                        </v-tooltip>
                    </v-select>

                    <v-text-field
                        v-model.number="product.count"
                        type="number"
                        label="Count"
                        :rules="[rules.required]"
                        clearable
                    ></v-text-field>

                    <v-select
                        v-model="product.category_id"
                        label="Идентификатор категории товара"
                        :items="categories"
                        :rules="[rules.required]"
                        clearable
                    ></v-select>
                    <v-textarea
                        v-model="product.picture"
                        label="URL-ссылка на картинку товара."
                        :rules="[rules.required]"
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Ссылки на картинку<br>
                                Каждая ссылка на отдельной строчке <br>
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.supplier"
                        label="ОГРН или ОГРНИП стороннего продавца."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                ОГРН должен содержать 13 символов, ОГРНИП — 15.<br>
                                Указывайте, если вы хотите размещать предложения других продавцов.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.delivery"
                        label="Возможность курьерской доставки (по всем регионам, в которые доставляет магазин)."
                    ></v-checkbox>

                    <v-checkbox
                        v-model="product.pickup"
                        label="Возможность самовывоза из пунктов выдачи (во всех регионах, в которые доставляет магазин)."
                        clearable
                    ></v-checkbox>

                    <v-checkbox
                        v-model="product['store']"
                        label="Возможность купить товар без предварительного заказа."
                        clearable
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.description"
                        label="Описание предложения"
                        clearable
                    >
                    </v-textarea>

                    <v-textarea
                        v-model="product.sales_notes"
                        label="Условия продажи товара"
                        maxlength="50"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Элемент обязателен, если у вас есть ограничения при заказе товара <br>
                                (минимальная сумма заказа, минимальное количество товаров, необходимость предоплаты) <br>
                                и эти ограничения не указаны в личном кабинете.
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <v-text-field
                        v-model="product.min_quantity"
                        label="Минимальное количество одинаковых товаров в заказе"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Минимальное количество одинаковых товаров в заказе <br>
                                (для случаев, когда покупка возможна только комплектом, а не поштучно). <br>
                                Элемент используется только в категориях Автошины, Грузовые шины, Мотошины, Диски. <br>
                                Если элемент не указан, используется значение по умолчанию — 1.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.manufacturer_warranty"
                        label="Официальная гарантия производителя"
                    ></v-checkbox>

                    <v-text-field
                        v-model="product.country_of_origin"
                        label="Страна производства товара."
                        clearable
                    ></v-text-field>

                    <v-checkbox
                        v-model="product.adult"
                        label="Товар имеет отношение к удовлетворению сексуальных потребностей, либо иным образом эксплуатирует интерес к сексу."
                    ></v-checkbox>

                    <v-textarea
                        v-model="product.barcode"
                        label="Штрихкод товара от производителя в одном из форматов: EAN-13, EAN-8, UPC-A, UPC-E."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Данные в barcode влияют на привязку предложения к карточке товара <br>
                                и отображение правильных характеристик, которые соответствуют <br>
                                модификации товара на его карточке. <br>
                                Каждый код на отдельной строчке
                            </div>
                        </v-tooltip>
                    </v-textarea>

                    <p>
                        Характеристика товара
                        <v-btn depressed x-small @click="addParam">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        v-for="(p, i) in product.param"
                        :key="'param_' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-text-field
                            v-model="p.name"
                            label="Параметр"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.value"
                            label="Значение"
                        ></v-text-field>

                        <v-text-field
                            v-model="p.unit"
                            label="Единица измерения"
                        >
                            <template slot="append">
                                <v-icon @click="removeParam(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>
                    </v-container>

                    <p>
                        Состояние товара
                        <v-btn depressed x-small @click="addCondition">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                    </p>

                    <v-container
                        fluid
                        v-for="(c, i) in product.condition"
                        :key="'condition' + i"
                        style="display: flex; flex-wrap: nowrap;"
                    >
                        <v-select
                            v-model="c['type']"
                            :items="conditionTypes"
                            label="Состояние товара"
                        ></v-select>

                        <v-text-field
                            v-model="c.reason"
                            label="Причина уценки"
                        >
                            <template slot="append">
                                <v-icon @click="removeCondition(i)">mdi-minus</v-icon>
                            </template>
                        </v-text-field>

                    </v-container>

                    <v-text-field
                        v-model="product.expiry"
                        label="Срок годности или службы."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Значение элемента должно соответствовать стандарту ISO 8601. <br>
                                Например: P1Y2M10DT2H — 1 год, 2 месяца, 10 дней, 2 часа.
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.weight"
                        type="number"
                        label="Вес товара в килограммах с учетом упаковки."
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Для некоторых категорий установлены ограничения <br>
                                по минимальному или максимальному значению веса. <br>
                                В любой категории вес можно указывать с точностью до тысячных <br>
                                (например, 1.001 кг; разделитель целой и дробной части — точка).
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.length"
                        type="number"
                        label="Габариты товара - Длина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.width"
                        type="number"
                        label="Габариты товара - Ширина"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-text-field
                        v-model.number="product.height"
                        type="number"
                        label="Габариты товара - Высота"
                        clearable
                    >
                        <v-tooltip
                            :open-on-hover="true"
                            top
                            slot="append-outer"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <div>
                                Размеры укажите в сантиметрах
                            </div>
                        </v-tooltip>
                    </v-text-field>

                    <v-checkbox
                        v-model="product.downloadable"
                        label="Продукт можно скачать только с предоплатой."
                    ></v-checkbox>
                    <!-- age -->
                </template>
            </v-form>

        </template>


    </dialog-fullscreen>

</template>

<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue'

    export default {
        name: 'ProductUpdateDialog',
        props: ['initialState', 'data', 'postAction'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                visibility: this.initialState,
                product: this.data,
                indicator: false,
                valid: false,
                rules: {
                    required: value => !!value || 'Обязательное поле.',
                    email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный электронный адрес',
                    maxLength: v => v.length <= 40 || 'Макс 40 символов',
                },
                currencies: [
                    {
                        value: 'RUR',
                        text: 'RUR',
                    },
                    {
                        value: 'USD',
                        text: 'USD',
                    },
                    {
                        value: 'EUR',
                        text: 'EUR',
                    },
                    {
                        value: 'UAH',
                        text: 'UAH',
                    },
                    {
                        value: 'KZT',
                        text: 'KZT',
                    },
                    {
                        value: 'BYN',
                        text: 'BYN',
                    },
                ],
                productTypes: [
                    {
                        value: null,
                        text: 'Упрощенный тип',
                    },
                    {
                        value: 'vendor.model',
                        text: 'Произвольный тип',
                    },
                    {
                        value: 'alco',
                        text: 'Алкогольные напитки',
                    },
                    {
                        value: 'audiobook',
                        text: 'Аудиокниги',
                    },
                    {
                        value: 'event-ticket',
                        text: 'Билеты на мероприятие',
                    },
                    {
                        value: 'book',
                        text: 'Книги',
                    },
                    {
                        value: 'medicine',
                        text: 'Лекарства',
                    },
                    {
                        value: 'artist.title',
                        text: 'Музыкальная и видеопродукция',
                    },
                    {
                        value: 'tour',
                        text: 'Туры',
                    },
                ],
                categories: [],
                conditionTypes: [
                    {
                        value: 'likenew',
                        text: 'Как новый'
                    },
                    {
                        value: 'used',
                        text: 'Подержанный'
                    },
                ],
            }
        },
        watch: {
            initialState(value) {
                if (value) {
                    this.visibility = true;
                }
            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
            data(value) {
                this.product = value
                this.getCategories();
            }
        },
        methods: {
            addParam() {
                if (this.product.param && this.product.param.length) {
                    this.product.param.push({
                        name: null,
                        unit: null,
                        value: null
                    })
                } else {
                    this.product.param = [];
                    this.product.param.push({
                        name: null,
                        unit: null,
                        value: null
                    })
                }
            },
            removeParam(index) {
                this.product.param.splice(index, 1)
            },
            addCondition() {
                if (this.product.condition && this.product.condition.length) {
                    this.product.condition.push({
                        type: null,
                        reason: null,
                    })
                } else {
                    this.product.condition = [];
                    this.product.param.push({
                        type: null,
                        reason: null,
                    })
                }
            },
            removeCondition(index) {
                this.product.condition.splice(index, 1)
            },
            updateProduct() {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return
                }
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin,
                }
                // const product = JSON.parse(JSON.stringify(this.product))
                this.$http.put(
                    this.$store.getters.endpoint + `/products/${this.product.id}`, {
                        ...requestData,
                        product: {
                            ...this.product
                        },
                    }, {
                        headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                    }
                ).then(r => {
                    let response = r.body;
                    if (response.result) {
                        this.visibility = false;
                        this.postAction();
                    } else {
                        console.log(response)
                    }
                })
            },
            getCategories() {
                this.categories = [];
                const params = {
                    'login': this.$store.getters.userLogin,
                };
                console.log(this.product)
                this.$http.get(
                    this.$store.getters.endpoint + `/shops/${this.product.project_id}/categories`, {
                        emulateJSON: true,
                        params: params,
                        headers: this.$store.state.restrictedHeaders
                    }
                ).then(
                    r => {
                        let response = r.body;
                        console.log(response)
                        if (response.result) {
                            this.categories = response.data;
                        }
                    }
                )
            }
        }
    }
</script>
