import { render, staticRenderFns } from "./BaseWysiwyg.vue?vue&type=template&id=270e825f"
import script from "./BaseWysiwyg.vue?vue&type=script&lang=js"
export * from "./BaseWysiwyg.vue?vue&type=script&lang=js"
import style0 from "./BaseWysiwyg.vue?vue&type=style&index=0&id=270e825f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports