<template>

    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>Редактирование фида Avito</template>
        <template v-slot:action>
            <v-btn
                text
                color='green darken-2'
                @click="saveFeed"
            >
                Обновить
            </v-btn>
        </template>
        <template v-slot:content>
            <v-card flat>
                <v-tabs
                    v-model='activeTab'
                    :slider-color='$store.getters.uiAccent'
                    :color='$store.getters.uiAccent'
                    grow
                    show-arrows
                    input='boundTabEvent'
                    :value='activeTab'
                >

                    <v-tab key='0' ripple>Общие элементы</v-tab>
                    <v-tab key='1' ripple>Контактная информация</v-tab>
                    <v-tab key='2' ripple>Описание</v-tab>
                    <v-tab key='3' ripple>Параметры недвижимости</v-tab>
                    <v-tab key='4' ripple>Фото и видео</v-tab>
                </v-tabs>
            </v-card>

            <v-tabs-items v-model='activeTab' v-if="visibility">

                <v-tab-item key="0">
                    <v-form v-model="validGeneralInfo" style='min-width: 320px;'>

                        <v-autocomplete
                            :items="avito.listingFeeChoices"
                            v-model="feed.avito.listing_fee"
                            clearable
                            label="Вариант платного размещения"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                        <v-autocomplete
                            :items="avito.adStatusChoices"
                            v-model="feed.avito.ad_status"
                            clearable
                            label="Платная услуга, которую нужно применить к объявлению"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                        <v-text-field
                            v-model="feed.avito.avito_id"
                            clearable
                            label="Номер объявления на Авито"
                        ></v-text-field>

                    </v-form>
                </v-tab-item>


                <v-tab-item key="1">

                    <v-form style='min-width: 320px; width: 500px;'>

                        <v-autocomplete
                            :items="avito.yesNoChoices"
                            v-model="feed.avito.allow_email"
                            clearable
                            label="Возможность написать сообщение по объявлению через сайт"
                        ></v-autocomplete>
                        <v-text-field
                            v-model="feed.avito.manager_name"
                            :rules="[rules.maxLength]"
                            counter
                            maxlength="40"
                            hint="строка не более 40 символов"
                            label="Имя менеджера, контактного лица компании по данному объявлению"
                        ></v-text-field>
                        <v-text-field
                            v-model="feed.avito.contact_phone"
                            label="Контактный телефон"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Только один российский номер телефона;<br>
                                    Должен быть обязательно указан код города или мобильного оператора. <br>
                                    Корректные примеры:
                                    <br>
                                    - +7 (495) 777-10-66, <br>
                                    - (81374) 4-55-75, <br>
                                    - 8 905 207 04 90, <br>
                                    - +7 905 2070490, <br>
                                    - 88123855085, <br>
                                    - 9052070490
                                </div>
                            </v-tooltip>
                        </v-text-field>

                    </v-form>

                </v-tab-item>

                <v-tab-item key="2">
                    <v-form style='min-width: 320px; width: 600px; text-align: left; padding-top: 20px;'>
                        <span>Текстовое описание объявления в соответствии с правилами Авито &nbsp;</span>
                        <v-tooltip
                            :open-on-hover="true"
                            top
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                            </template>
                            <p>
                                Если у вас есть оплаченная Подписка, то поместив описание внутрь CDATA, <br>
                                вы можете использовать дополнительное форматирование с помощью HTML-тегов — строго
                                из указанного списка:
                                <br> p, br, strong, em, ul, ol, li.
                            </p>
                        </v-tooltip>
                        <base-wysiwyg 
                          v-model="feed.avito.description"
                          :max-length="7500"
                          hint="строка не более 7500 символов"
                        />
                        <div v-if="mKeys">
                          <div class='key-list-item'
                                v-for='(keyItem, index) in keyItems'
                                :key='index'
                                @click="insertKeyInText"
                                :title=keyItem.text
                               >
                                       {{ keyItem.value }}
                          </div>
                        </div>
                    </v-form>
                </v-tab-item>

                <v-tab-item key="3">
                    <v-form style='min-width: 320px; width: 500px;'>

                        <v-text-field
                            v-model="feed.avito.property_rights"
                            label="Право собственности"
                            disabled
                        ></v-text-field>


                        <v-autocomplete
                            :items="avito.decorationChoices"
                            v-model="feed.avito.decoration"
                            clearable
                            label="Отделка помещения"
                        ></v-autocomplete>

                        <v-text-field
                            v-model="feed.avito.apartment_number"
                            clearable
                            label="Номер квартиры - число, больше 0 и буква (опционально)"
                        ></v-text-field>

                        <v-autocomplete
                            :items="avito.statusChoices"
                            v-model="feed.avito.status"
                            :rules="[rules.required]"
                            clearable
                            label="Статус недвижимости"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="avito.balconyChoices"
                            v-model="feed.avito.balcony_or_loggia"
                            clearable
                            label="Балкон или лоджия"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="avito.viewChoices"
                            v-model="feed.avito.view_from_windows"
                            clearable
                            multiple
                            label="Вид из окон"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="avito.courtyardChoices"
                            v-model="feed.avito.courtyard"
                            clearable
                            multiple
                            label="Двор"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="avito.parkingChoices"
                            v-model="feed.avito.parking"
                            clearable
                            multiple
                            label="Парковка"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="avito.bathroomChoices"
                            v-model="feed.avito.bathroom"
                            clearable
                            label="Санузел"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="avito.ndAdditionallyChoices"
                            v-model="feed.avito.nd_additionally"
                            clearable
                            multiple
                            label="Дополнительно"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="avito.dealTypeChoices"
                            v-model="feed.avito.deal_type"
                            clearable
                            label="Тип сделки"
                        ></v-autocomplete>

                        <v-autocomplete
                            :items="avito.roomTypeChoices"
                            v-model="feed.avito.room_type"
                            clearable
                            multiple
                            label="Тип комнат"
                        ></v-autocomplete>

                    </v-form>

                </v-tab-item>

                <v-tab-item key="4">
                    <v-form style='min-width: 320px; width: 500px;'>
                        <v-text-field
                            v-model="feed.avito.layout_photo"
                            label="Изображение планировки"
                        ></v-text-field>
                        <v-text-field
                            v-model="feed.avito.floor_plan_photo"
                            label="Изображение поэтажного плана"
                        ></v-text-field>
                        <v-textarea
                            v-model="feed.avito.images"
                            label="Фотографии"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <p>
                                    Каждая ссылка на отдельной строчке <br>
                                    <b>Максимальное количество фотографий - 40</b>
                                </p>
                            </v-tooltip>

                        </v-textarea>
                        <v-text-field
                            v-model="feed.avito.video_url"
                            label="Видео c YouTube — ссылка"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <p>
                                    Формат ссылки на видео должен соответствовать - <b>https://www.youtube.com/watch?v=*** </b>
                                    <br>
                                    Пример неправильной ссылки - <b>http://www.youtube.com/embed/***</b>
                                </p>
                            </v-tooltip>
                        </v-text-field>
                    </v-form>
                </v-tab-item>


            </v-tabs-items>
        </template>
    </dialog-fullscreen>

</template>

<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue';
    import BaseWysiwyg from "@/components/fields/BaseWysiwyg.vue";
    import avitoVariables from "../../../store/avitoVariables";
    import feedVariables from "../../../store/feedVariables";

    export default {
        name: 'AvitoNewBuildingUpdateDialog',
        props: ['initialState', 'feed'],
        components: {'dialog-fullscreen': DialogFullscreen, BaseWysiwyg},
        data() {
            return {
                valid: false,
                validGeneralInfo: false,
                indicator: false,
                isLoading: false,
                visibility: this.initialState,
                activeTab: 0,
                mKeys: false,
                feedVariables: feedVariables,
                keyItems: [],
                currentCursorPosition: 0,
                rules: {
                    required: value => !!value || 'Обязательное поле.',
                    email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный электронный адрес',
                    maxLength: v => v && v.length <= 40 || 'Макс 40 символов',
                    descriptionMaxLength: v => v && v.length <= 5000 || 'Макс 5000 символов',
                },
                avito: {
                    buildings: [],
                    ...avitoVariables.avito
                }
            }
        },
        methods: {
            print() {
                console.log(this.feed.avito)
            },
            saveFeed() {
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin,
                }
                this.$http.put(this.$store.getters.endpoint + `/feeds/${this.feed.id}/avito`, {
                    ...requestData,
                    avito: this.feed.avito
                }, {
                    headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                }).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.visibility = false;
                        console.log(response)
                    } else {
                        console.log(response)
                    }
                })
            },
             changeDinamicKey(key) {
                 let text_description = this.feed.avito.description
                 this.currentCursorPosition = event.path[0].selectionStart
                 if (key.keyCode == 192) {
                     if (!this.mKeys) {
                         this.mKeys = true
                     } else {
                         this.mKeys = false
                     }
                 }
                 this.keyItems = feedVariables.replaceKeys;
             },
             insertKeyInText(e) {
                 let F = this.feed.avito.description.substr(0, this.currentCursorPosition)
                 let L = this.feed.avito.description.substr(this.currentCursorPosition + 1)
                 this.feed.avito.description = F + e.target.textContent.trim() + L

                 this.mKeys = false
             }
        },
        watch: {
            initialState(value) {
                if (value) {
                    this.visibility = value;
                }

            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
        },
    }
</script>
