const conditionTypes = [
    {
        value: 'cosmeticRepairsRequired',
        text: 'Требуется косметический ремонт',
    },
    {
        value: 'finishing',
        text: 'Под чистовую отделку',
    },
    {
        value: 'majorRepairsRequired',
        text: 'Требуется капитальный ремонт',
    },
    {
        value: 'office',
        text: 'Офисная отделка',
    },
];
export default {
    cian: {
        extinguishingSystemTypes: [
            {
                value: 'alarm',
                text: 'Сигнализация',
            },
            {
                value: 'gas',
                text: 'Газовая',
            },
            {
                value: 'hydrant',
                text: 'Гидрантная',
            },
            {
                value: 'no',
                text: 'Нет',
            },
            {
                value: 'powder',
                text: 'Порошковая',
            },
            {
                value: 'sprinkler',
                text: 'Спринклерная',
            },
        ],
        publishTerms: [
            {
                value: 'free',
                text: 'Бесплатное',
            },
            {
                value: 'highlight',
                text: 'Выделение цветом',
            },
            {
                value: 'paid',
                text: 'Платное',
            },
            {
                value: 'premium',
                text: 'Премиум-объявление',
            },
            {
                value: 'top3',
                text: 'Топ',
            },
        ],
        saleTypes: [
            {
                value: 'dupt',
                text: 'Договор уступки права требования',
            },
            {
                value: 'dzhsk',
                text: 'Договор ЖСК',
            },
            {
                value: 'free',
                text: 'Свободная продажа',
            },
            {
                value: 'fz214',
                text: '214-ФЗ',
            },
            {
                value: 'investment',
                text: 'Договор инвестирования',
            },
            {
                value: 'pdkp',
                text: 'Предварительный договор купли-продажи',
            },
        ],
        categories: [
            {
                value: 'newBuildingFlatSale',
                text: 'Квартира в новостройке (продажа)',
            },
            {
                value: 'officeSale',
                text: 'Офис (продажа)',
            },
            {
                value: 'freeAppointmentObjectSale',
                text: 'Помещение свободного назначения (продажа)',
            },
            {
                value: 'freeAppointmentObjectRent',
                text: 'Помещение свободного назначения (аренда)',
            },
            // {
            //     value: 'shoppingAreaSale',
            //     text: 'Торговая площадь',
            // },
        ],
        parkingChoices: [
            {
                value: 'ground',
                text: 'Наземная',
            },
            {
                value: 'multilevel',
                text: 'Многоуровневая',
            },
            {
                value: 'open',
                text: 'Открытая',
            },
            {
                value: 'roof',
                text: 'На крыше',
            },
            {
                value: 'underground',
                text: 'Подземная',
            },
        ],
        materialTypeChoices: [
            {
                value: 'brick',
                text: 'Кирпичный',
            },
            {
                value: 'monolith',
                text: 'Монолитный',
            },
            {
                value: 'panel',
                text: 'Панельный',
            },


            {
                value: 'aerocreteBlock',
                text: 'Газобетонный блок',
            },
            {
                value: 'block',
                text: 'Блочный',
            },
            {
                value: 'boards',
                text: 'Щитовой',
            },
            {
                value: 'foamConcreteBlock',
                text: 'Пенобетонный блок',
            },
            {
                value: 'gasSilicateBlock',
                text: 'Газосиликатный блок',
            },
            {
                value: 'monolithBrick',
                text: 'Монолитно-кирпичный',
            },
            {
                value: 'old',
                text: 'Старый фонд',
            },
            {
                value: 'stalin',
                text: 'Сталинский',
            },
            {
                value: 'wireframe',
                text: 'Каркасный',
            },
            {
                value: 'wood',
                text: 'Деревянный',
            },
        ],
        windowsViews: [
            {
                value: 'street',
                text: 'На улицу',
            },
            {
                value: 'yard',
                text: 'Во двор',
            },
            {
                value: 'yardAndStreet',
                text: 'На улицу и двор',
            },
        ],
        decorations: [
            {
                value: 'fine',
                text: 'Чистовая',
            },
            {
                value: 'rough',
                text: 'Черновая',
            },
            {
                value: 'without',
                text: 'Без отделки',
            },
        ],
        yesNoChoices: [
            {
                value: false,
                text: 'Нет',
            },
            {
                value: true,
                text: 'Да',
            },
        ],
        roomTypeChoices: [
            {
                value: 'both',
                text: 'Оба варианта',
            },
            {
                value: 'combined',
                text: 'Совмещенная',
            },
            {
                value: 'separate',
                text: 'Изолированная',
            },
        ],
        metroTransportTypeChoices: [
            {
                value: 'transport',
                text: 'На транспорте',
            },
            {
                value: 'walk',
                text: 'Пешком',
            },
        ],
        regions: [
            {
                value: 'moscow',
                text: 'Москва',
            },
            {
                value: 'petersburg',
                text: 'Санкт-Петербург',
            },
            {
                value: 'moscow2',
                text: 'Московская область',
            },
            {
                value: 'lenin',
                text: 'Ленинградская область',
            },
        ],
        heatingTypes: [
            {
                value: 'autonomous',
                text: 'Автономное',
            },
            {
                value: 'central',
                text: 'Центральное',
            },
            {
                value: 'no',
                text: 'Нет',
            },
            


            {
                value: 'autonomousGas',
                text: 'Автономное газовое',
            },
            {
                value: 'boiler',
                text: 'Котел',
            },
            {
                value: 'centralCoal',
                text: 'Центральное угольное',
            },
            {
                value: 'centralGas',
                text: 'Центральное газовое',
            },
            {
                value: 'diesel',
                text: 'Дизельное',
            },
            {
                value: 'electric',
                text: 'Электрическое',
            },
            {
                value: 'fireplace',
                text: 'Камин',
            },
            {
                value: 'other',
                text: 'Другое',
            },
            {
                value: 'solidFuelBoiler',
                text: 'Твердотопливный котел',
            },
            {
                value: 'stove',
                text: 'Печь',
            },
        ],
        placementTypes: [
            {
                value: 'shoppingMall',
                text: 'Помещение в торговом комплексе',
            },
            {
                value: 'streetRetail',
                text: 'Street retail',
            },
        ],
        conditionTypeForOffice: [
            ...conditionTypes
        ],
        conditionTypeForFreeAppointmentAndShopping: [
            ...conditionTypes,
            {
                value: 'design',
                text: 'Дизайнерский ремонт',
            },
            {
                value: 'typical',
                text: 'Типовой ремонт',
            },
        ],
        inputTypes: [
            {
                value: 'commonFromStreet',
                text: 'Общий с улицы',
            },
            {
                value: 'commonFromYard',
                text: 'Общий со двора',
            },
            {
                value: 'separateFromStreet',
                text: 'Отдельный с улицы',
            },
            {
                value: 'separateFromYard',
                text: 'Отдельный со двора',
            },
        ],
        layouts: [
            {
                value: 'cabinet',
                text: 'Кабинетная',
            },
            {
                value: 'corridorplan',
                text: 'Коридорная',
            },
            {
                value: 'mixed',
                text: 'Смешанная',
            },
            {
                value: 'openSpace',
                text: 'Открытая',
            },
        ],
        specialties: [{"value": "spaSaloon", "text": "Spa салон"}, {
            "value": "fillingStation",
            "text": "АЗС"
        }, {"value": "aviaBookingOffice", "text": "Авиа кассы"}, {
            "value": "carSpareParts",
            "text": "Автозапчасти"
        }, {"value": "carWash", "text": "Автомойка"}, {
            "value": "automobileCenter",
            "text": "Автосалон"
        }, {"value": "carService", "text": "Автосервис"}, {
            "value": "alcholicBeveragesStore",
            "text": "Алкомаркет"
        }, {"value": "anticafe", "text": "Антикафе"}, {
            "value": "pharmacy",
            "text": "Аптека"
        }, {"value": "rentalBusiness", "text": "Арендный бизнес"}, {
            "value": "clothesStudio",
            "text": "Ателье одежды"
        }, {"value": "center", "text": "База"}, {
            "value": "recreationalCenter",
            "text": "База отдыха"
        }, {"value": "bank", "text": "Банк"}, {"value": "bathingComplex", "text": "Банный комплекс"}, {
            "value": "bar",
            "text": "Бар"
        }, {"value": "underwear", "text": "Белье"}, {"value": "bijouterie", "text": "Бижутерия"}, {
            "value": "poolhall",
            "text": "Бильярдная"
        }, {"value": "hospitalComplex", "text": "Больничный комплекс"}, {
            "value": "bowling",
            "text": "Боулинг"
        }, {"value": "bookmakerSOffice", "text": "Букмекерская контора"}, {
            "value": "boutique",
            "text": "Бутик"
        }, {"value": "snackBar", "text": "Буфет"}, {
            "value": "householdAppliances",
            "text": "Бытовая техника"
        }, {"value": "domesticServices", "text": "Бытовые услуги"}, {
            "value": "bakeryProducts",
            "text": "Выпечка"
        }, {"value": "exhibition", "text": "Выставка"}, {
            "value": "gallery",
            "text": "Галерея"
        }, {"value": "hypermarket", "text": "Гипермаркет"}, {
            "value": "guestHouse",
            "text": "Гостевой дом"
        }, {"value": "hotel", "text": "Гостиница"}, {
            "value": "readyMadeBusiness",
            "text": "Готовый бизнес"
        }, {"value": "goodsForChildren", "text": "Детские товары"}, {
            "value": "childClub",
            "text": "Детский клуб"
        }, {"value": "childStore", "text": "Детский магазин"}, {
            "value": "daycare",
            "text": "Детский сад"
        }, {"value": "childCenter", "text": "Детский центр"}, {
            "value": "rentalHouse",
            "text": "Доходный дом"
        }, {"value": "other", "text": "Другое"}, {"value": "factory", "text": "Завод"}, {
            "value": "lending",
            "text": "Займы"
        }, {"value": "hall", "text": "Зал"}, {"value": "petShop", "text": "Зоомагазин"}, {
            "value": "petProducts",
            "text": "Зоотовары"
        }, {"value": "dentalPolyclinic", "text": "Зубная поликлиника"}, {
            "value": "internetShop",
            "text": "Интернет магазин"
        }, {"value": "joga", "text": "Йога"}, {"value": "hookah", "text": "Кальянная"}, {
            "value": "cafe",
            "text": "Кафе/ресторан"
        }, {"value": "quest", "text": "Квест"}, {
            "value": "customerRelatedOffice",
            "text": "Клиентский офис"
        }, {"value": "clinic", "text": "Клиника"}, {"value": "club", "text": "Клуб"}, {
            "value": "trade",
            "text": "Коммерция"
        }, {"value": "confectionery", "text": "Кондитерская"}, {
            "value": "cosmetics",
            "text": "Косметика"
        }, {"value": "cosmeticMedicine", "text": "Косметология"}, {
            "value": "coffeeHouse",
            "text": "Кофейня"
        }, {"value": "cookery", "text": "Кулинария"}, {"value": "pawnshop", "text": "Ломбард"}, {
            "value": "shop",
            "text": "Магазин"
        }, {"value": "smallProductionEnterprice", "text": "Малое производство"}, {
            "value": "manicure",
            "text": "Маникюр"
        }, {"value": "massageSalon", "text": "Массажный салон"}, {
            "value": "workshop",
            "text": "Мастерская"
        }, {"value": "furniture", "text": "Мебель"}, {
            "value": "medicalCenter",
            "text": "Медицинский центр"
        }, {"value": "miniHotel", "text": "Мини-отель"}, {"value": "meet", "text": "Мясо"}, {
            "value": "notarySOffice",
            "text": "Нотариальная контора"
        }, {"value": "nightClub", "text": "Ночной клуб"}, {
            "value": "currencyExchange",
            "text": "Обмен валюты"
        }, {"value": "shoes", "text": "Обувь"}, {"value": "publicCatering", "text": "Общепит"}, {
            "value": "clothers",
            "text": "Одежда"
        }, {"value": "optics", "text": "Оптика"}, {"value": "office", "text": "Офис"}, {
            "value": "recreationCenter",
            "text": "Пансионат"
        }, {"value": "barbershop", "text": "Парикмахерская"}, {
            "value": "perfumery",
            "text": "Парфюмерия"
        }, {"value": "bakery", "text": "Пекарня"}, {"value": "pizzeria", "text": "Пиццерия"}, {
            "value": "dishes",
            "text": "Посуда"
        }, {"value": "representativeOffice", "text": "Представительство"}, {
            "value": "products",
            "text": "Продукты"
        }, {"value": "production", "text": "Производство"}, {
            "value": "collectionPoint",
            "text": "Пункт выдачи"
        }, {"value": "workplace", "text": "Рабочeе место"}, {
            "value": "workRoom",
            "text": "Рабочий кабинет"
        }, {"value": "restaurant", "text": "Ресторан"}, {
            "value": "fish",
            "text": "Рыба"
        }, {"value": "vahicleServiceCenter", "text": "СТО"}, {
            "value": "salon",
            "text": "Салон"
        }, {"value": "beautySaloon", "text": "Салон красоты"}, {
            "value": "phoneShop",
            "text": "Салон связи"
        }, {"value": "healthResort", "text": "Санаторий"}, {
            "value": "sauna",
            "text": "Сауна"
        }, {"value": "flexiblePurpose", "text": "Свободное назначение"}, {
            "value": "service",
            "text": "Сервис"
        }, {"value": "warehouse", "text": "Склад"}, {"value": "gym", "text": "Спортзал"}, {
            "value": "gymHall",
            "text": "Спортивный зал"
        }, {"value": "canteen", "text": "Столовая"}, {
            "value": "stomatology",
            "text": "Стоматология"
        }, {"value": "streetRetail", "text": "Стрит ритейл"}, {
            "value": "constructionMaterials",
            "text": "Стройматериалы"
        }, {"value": "dancingStudio", "text": "Студия танцев"}, {
            "value": "souvenirs",
            "text": "Сувениры"
        }, {"value": "bagStore", "text": "Сумки"}, {"value": "supermarket", "text": "Супермаркет"}, {
            "value": "sushi",
            "text": "Суши"
        }, {"value": "consumerGoods", "text": "ТНП"}, {
            "value": "tatooSaloon",
            "text": "Тату салон"
        }, {"value": "printingOffice", "text": "Типография"}, {
            "value": "householdGoods",
            "text": "Товары для дома"
        }, {"value": "shoppingFloorSpace", "text": "Торговая площадь"}, {
            "value": "trading",
            "text": "Торговля"
        }, {"value": "shopEquipment", "text": "Торговое"}, {
            "value": "shoppingComplex",
            "text": "Торговый комплекс"
        }, {"value": "tradingCenter", "text": "Торговый центр"}, {
            "value": "travelAgency",
            "text": "Турагенство"
        }, {"value": "services", "text": "Услуги"}, {
            "value": "educationalCenter",
            "text": "Учебный центр"
        }, {"value": "fastFood", "text": "Фастфуд"}, {"value": "farm", "text": "Ферма"}, {
            "value": "fitnessCentre",
            "text": "Фитнес"
        }, {"value": "photoStudio", "text": "Фото студия"}, {
            "value": "studio",
            "text": "Фотостудия"
        }, {"value": "fruit", "text": "Фрукты"}, {
            "value": "dryCleaning",
            "text": "Химчистка"
        }, {"value": "bakeryComplex", "text": "Хлебокомбинат"}, {
            "value": "hostel",
            "text": "Хостел"
        }, {"value": "flowers", "text": "Цветы"}, {"value": "guild", "text": "Цех"}, {
            "value": "privatePractice",
            "text": "Частная практика"
        }, {"value": "shawarma", "text": "Шаурма"}, {
            "value": "sewingShop",
            "text": "Швейный цех"
        }, {"value": "mounting", "text": "Шиномонтаж"}, {"value": "school", "text": "Школа"}, {
            "value": "showroom",
            "text": "Шоурум"
        }, {"value": "electronicCigarette", "text": "Электронные сигареты"}, {
            "value": "jewerlyShop",
            "text": "Ювелирный"
        }],
        types: [
            {
                value: 'administrativeBuilding',
                text: 'Административное здание',
            },
            {
                value: 'businessCenter',
                text: 'Бизнес-центр',
            },
            {
                value: 'businessCenter2',
                text: 'Деловой центр',
            },
            {
                value: 'businessHouse',
                text: 'Деловой дом',
            },
            {
                value: 'businessPark',
                text: 'Бизнес-парк',
            },
            {
                value: 'businessQuarter',
                text: 'Бизнес-квартал',
            },
            {
                value: 'businessQuarter2',
                text: 'Деловой квартал',
            },
            {
                value: 'free',
                text: 'Объект свободного назначения',
            },
            {
                value: 'industrialComplex',
                text: 'Производственный комплекс',
            },
            {
                value: 'industrialPark',
                text: 'Индустриальный парк',
            },
            {
                value: 'industrialSite',
                text: 'Промплощадка',
            },
            {
                value: 'industrialWarehouseComplex',
                text: 'Производственно-складской комплекс',
            },
            {
                value: 'logisticsCenter',
                text: 'Логистический центр',
            },
            {
                value: 'logisticsComplex',
                text: 'Логистический комплекс',
            },
            {
                value: 'logisticsPark',
                text: 'Логистический парк',
            },
            {
                value: 'mansion',
                text: 'Особняк',
            },
            {
                value: 'manufactureBuilding',
                text: 'Производственное здание',
            },
            {
                value: 'manufacturingFacility',
                text: 'Производственный цех',
            },
            {
                value: 'modular',
                text: 'Модульное здание',
            },
            {
                value: 'multifunctionalComplex',
                text: 'Многофункциональный комплекс',
            },
            {
                value: 'officeAndHotelComplex',
                text: 'Офисно-гостиничный комплекс',
            },
            {
                value: 'officeAndResidentialComplex',
                text: 'Офисно-жилой комплекс',
            },
            {
                value: 'officeAndWarehouse',
                text: 'Офисно-складское здание',
            },
            {
                value: 'officeAndWarehouseComplex',
                text: 'Офисно-складской комплекс',
            },
            {
                value: 'officeBuilding',
                text: 'Офисное здание',
            },
            {
                value: 'officeCenter',
                text: 'Офисный центр',
            },
            {
                value: 'officeComplex',
                text: 'Офисный комплекс',
            },
            {
                value: 'officeIndustrialComplex',
                text: 'Офисно-производственный комплекс',
            },
            {
                value: 'officeQuarter',
                text: 'Офисный квартал',
            },
            {
                value: 'old',
                text: 'Старый фонд',
            },
            {
                value: 'other',
                text: 'Другое',
            },
            {
                value: 'outlet',
                text: 'Аутлет',
            },
            {
                value: 'propertyComplex',
                text: 'Имущественный комплекс',
            },
            {
                value: 'residentialComplex',
                text: 'Жилой комплекс',
            },
            {
                value: 'residentialHouse',
                text: 'Жилой дом',
            },
            {
                value: 'shoppingAndBusinessComplex',
                text: 'Торгово-деловой комплекс',
            },
            {
                value: 'shoppingAndCommunityCenter',
                text: 'Торгово-общественный центр',
            },
            {
                value: 'shoppingAndEntertainmentCenter',
                text: 'Торгово-развлекательный центр',
            },
            {
                value: 'shoppingAndWarehouseComplex',
                text: 'Торгово-складской комплекс',
            },
            {
                value: 'shoppingCenter',
                text: 'Торговый центр',
            },
            {
                value: 'shoppingComplex',
                text: 'Торговый комплекс',
            },
            {
                value: 'specializedShoppingCenter',
                text: 'Специализированный торговый центр',
            },
            {
                value: 'standaloneBuilding',
                text: 'Отдельно стоящее здание',
            },
            {
                value: 'technopark',
                text: 'Технопарк',
            },
            {
                value: 'tradeAndExhibitionComplex',
                text: 'Торгово-выставочный комплекс',
            },
            {
                value: 'tradingHouse',
                text: 'Торговый дом',
            },
            {
                value: 'tradingOfficeComplex',
                text: 'Торгово-офисный комплекс',
            },
            {
                value: 'warehouse',
                text: 'Склад',
            },
            {
                value: 'warehouseComplex',
                text: 'Складской комплекс',
            },
        ],
        classTypes: [
            {
                value: 'a',
                text: 'A',
            },
            {
                value: 'aPlus',
                text: 'A+',
            },
            {
                value: 'b',
                text: 'B',
            },
            {
                value: 'bMinus',
                text: 'B-',
            },
            {
                value: 'bPlus',
                text: 'B+',
            },
            {
                value: 'c',
                text: 'C',
            },
        ],
        ventilationTypes: [
            {
                value: 'forced',
                text: 'Приточная',
            },
            {
                value: 'natural',
                text: 'Естественная',
            },
            {
                value: 'no',
                text: 'Нет',
            },
        ],
        conditioningTypes: [
            {
                value: 'central',
                text: 'Центральное',
            },
            {
                value: 'local',
                text: 'Местное',
            },
            {
                value: 'no',
                text: 'Нет',
            },
        ],
        statusTypes: [
            {
                value: 'operational',
                text: 'Действующее',
            },
            {
                value: 'project',
                text: 'Проект',
            },
            {
                value: 'underConstruction',
                text: 'Строящееся',
            },
        ],
        accessTypes: [
            {
                value: 'free',
                text: 'Свободный',
            },
            {
                value: 'passSystem',
                text: 'Пропускная система',
            },
        ],
        infrastructures: [
            {
                value: 'HasCarWash',
                text: 'Автомойка'
            },
            {
                value: 'HasBuffet',
                text: 'Буфет',
            },
            {
                value: 'HasCarService',
                text: 'Автосервис',
            },
            {
                value: 'HasCanteen',
                text: 'Столовая',
            },
            {
                value: 'HasCentralReception',
                text: 'Центральная рецепция',
            },
            {
                value: 'HasHotel',
                text: 'Гостиница',
            },
            {
                value: 'HasAtm',
                text: 'Банкомат',
            },
            {
                value: 'HasExhibitionAndWarehouseComplex',
                text: 'Выставочно-складской комплекс',
            },
            {
                value: 'HasPharmacy',
                text: 'Аптека',
            },
            {
                value: 'HasBankDepartment',
                text: 'Отделение банка',
            },
            {
                value: 'HasCinema',
                text: 'Кинотеатр',
            },
            {
                value: 'HasCafe',
                text: 'Кафе',
            },
            {
                value: 'HasMedicalCenter',
                text: 'Медицинский центр',
            },
            {
                value: 'HasBeautyShop',
                text: 'Салон красоты',
            },
            {
                value: 'HasStudio',
                text: 'Фотосалон',
            },
            {
                value: 'HasNotaryOffice',
                text: 'Нотариальная контора',
            },
            {
                value: 'HasPool',
                text: 'Бассейн',
            },
            {
                value: 'HasClothesStudio',
                text: 'Ателье одежды',
            },
            {
                value: 'HasWarehouse',
                text: 'Складские помещения',
            },
            {
                value: 'HasPark',
                text: 'Парк',
            },
            {
                value: 'HasRestaurant',
                text: 'Ресторан',
            },
            {
                value: 'HasFitnessCentre',
                text: 'Фитнес-центр',
            },
            {
                value: 'HasSupermarket',
                text: 'Супермаркет',
            },
            {
                value: 'HasMinimarket',
                text: 'Минимаркет',
            },
            {
                value: 'HasShoppingArea',
                text: 'Торговая зона',
            },
            {
                value: 'HasConferenceRoom',
                text: 'Конференц-зал',
            },
        ]
    }
}
