import { orderBy } from 'lodash-es'

export default {
    notes: (state, getters) => (kind) => {
        let notes = []
        switch (kind) {
            case 'personal':
                notes = getters.personalNotes
                break;
        }
        return notes
    },
    personalNotes: (state) => {
        return state.userData.notes || []
    },
    cursorReport: state => {
        return state.currentReport
    },
    secureHeaders: state => {
        return state.restrictedHeaders
    },
    logProgress: state => {
        return state.progress.log
    },
    objectsProgress: state => {
        return state.progress.retreive
    },
    baseUrl: state => {
        let production = process.env.hasOwnProperty('VUE_APP_FEED_API_URL') ? process.env.VUE_APP_FEED_API_URL : 'https://feed.artics.ru';
        if (process.env.hasOwnProperty('VUE_APP_XML')) {
            production = 'https://xml.service.artics.ru'
        }
        return state.routing.deploy ? production : 'http://localhost:8080'
    },
    authHandler: state => {
        let production = process.env.hasOwnProperty('VUE_APP_FEED_API_URL') ? process.env.VUE_APP_FEED_API_URL : 'https://feed.artics.ru';
        if (process.env.hasOwnProperty('VUE_APP_XML')) {
            production = 'https://xml.service.artics.ru/api'
        }
        return state.routing.deploy ? production : 'http://127.0.0.1:5400/api'
    },
    endpoint: state => {
        let production = process.env.hasOwnProperty('VUE_APP_FEED_API_URL') ? process.env.VUE_APP_FEED_API_URL : 'https://feed.artics.ru';
        if (process.env.hasOwnProperty('VUE_APP_XML')) {
            production = 'https://xml.service.artics.ru/api'
        }
        return state.routing.deploy ? production : 'http://127.0.0.1:5400/api'
    },
    groupedReports: (state, getters) => (archive_p, archive_r, q) => {
        //let q = text.replace(/[^0-9A-Z]+/gi, '').toLowerCase()
        let objects = getters.userObjects
            .filter((o) => o.active != archive_p)
            .filter((o) => o.project_name.toLowerCase().includes(q.toLowerCase()) || o.reports.filter(
                (r) => r.name.toLowerCase().includes(q.toLowerCase())).length > 0
            )
        if (!archive_r) {
            objects = objects.map( (o) => ({...o, 'reports': o.reports.filter( (r) => r.active != 0)}))
        }
        return orderBy(objects, 'project_name', 'asc');
    },
    favReports: (state, getters) => {
        let fav = (state.userData.meta && state.userData.meta.favorites) || []
        let fr = getters.userObjects.filter( (o) => (o.reports || []).filter((r) => fav.includes(r.report_id)).length > 0);
        /*fr.forEach( (o) => {
            o.reports = o.reports.filter((r) => fav.includes(r.report_id))
        });*/
        return orderBy(fr.map( (o) => {
            return {...o, reports: o.reports.filter((r) => fav.includes(r.report_id))}
        }), 'project_name', 'asc');
    },
    recentReports: (state, getters) => {
        //let rr = getters.userObjects.filter( (o) => o.reports.filter((r) => state.recentObjects.includes(r.report_id)).length > 0);
        //return orderBy(rr.length? rr : getters.userObjects.slice(0,5), 'project_name', 'desc');
        let objects = getters.userObjects
        return orderBy(objects, 'project_name', 'asc');
    },
    grantUsers: state => {
        return parseInt(state.userData && state.userData.user && state.userData.user.mode_users)
    },
    currentUser: state => {
        return state.userData && state.userData.user
    },
    isSuper: state => {
        return state.userData && state.userData.user && state.userData.user.superuser
    },
    userReportNotes: state => {
        return (state.userData.meta && state.userData.meta.notes && state.userData.meta.notes.reports) || {}
    },
    userObjects: state => {
        return (state.userData && state.userData.objects) || []
    },
    userBuildings: state => {
        return (state.userData && state.userData.buildings) || []
    },
    userHousings: state => {
        return (state.userData && state.userData.housings) || []
    },
    userReports: state => {
        return (state.userData && state.userData.reports) || []
    },
    userProjects: state => {
        return (state.userData && state.userData.projects) || []
    },
    userNotifications: state => {
        return (state.userData && state.userData.notifications) || []
    },
    userToken: state => {
        return state.userData && state.userData.user && state.userData.user.token
    },
    userLogin: state => {
        return state.userData && state.userData.user && state.userData.user.login
    },
    userId: state => {
        return state.userData && state.userData.user && state.userData.user.uid
    },
    headerLogo: state => {
        return state.designVariant[state.brandingOption].logo
    },
    userAvatar: (state, getters) => {
        return getters.currentUser.photo.replace('s96', 's192') || require(`@/assets/userpic_undefined_${state.brandingOption}.png`)
    },
    uiAccent: state => {
        return state.designVariant[state.brandingOption].accentColor
    },
    uiAccentIcon: state => {
        return state.designVariant[state.brandingOption].accentColorIcon
    },
    pageTitle: state => {
        return state.designVariant[state.brandingOption].titles.objects
    },
    appTheme: state => {
        return state.theme[state.userData.meta.theme] || state.theme.light
    }
}
