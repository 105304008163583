import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

import Vue from 'vue'
import Vuetify from 'vuetify'
import VueResource from 'vue-resource'
import App from './App.vue'
import router from '@/router'
import store from './store';
import vuetify from './plugins/vuetify';
import VueClipboard from 'vue-clipboard2';

Vue.use(Vuetify);
Vue.use(VueResource);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  components: {App},
  template: '<App/>',
  created: function() {
    if (this.$store.state.isLoggedIn) {
      this.$store.dispatch('authorizeUser', {flow: 'resume', user: {persistance: true}})
    }
  }
});
