export default {
    avito: {
        listingFeeChoices: [
            {
                value: "Package",
                text: "Package"
            },
            {
                value: "PackageSingle",
                text: "PackageSingle"
            },
            {
                value: "Single",
                text: "Single"
            },
        ],
        adStatusChoices: [
            {
                value: "Free",
                text: "Free",
            },
            {
                value: "Highlight",
                text: "Highlight",
            },
            {
                value: "XL",
                text: "XL",
            },
            {
                value: "x2_1",
                text: "x2_1",
            },
            {
                value: "x2_7",
                text: "x2_7",
            },
            {
                value: "x5_1",
                text: "x5_1",
            },
            {
                value: "x5_7",
                text: "x5_7",
            },
            {
                value: "x10_1",
                text: "x10_1",
            },
            {
                value: "x10_7",
                text: "x10_7",
            },
        ],
        houseTypeChoices: [
            {
                value: 'Кирпичный',
                text: 'Кирпичный',
            },
            {
                value: 'Панельный',
                text: 'Панельный',
            },
            {
                value: 'Блочный',
                text: 'Блочный',
            },
            {
                value: 'Монолитный',
                text: 'Монолитный',
            },
            {
                value: 'Деревянный',
                text: 'Деревянный',
            },
        ],
        yesNoChoices: [
            {
                value: 'Нет',
                text: 'Нет'
            },
            {
                value: 'Да',
                text: 'Да'
            },
        ],
        decorationChoices: [
            {
                value: 'Без отделки',
                text: 'Без отделки',
            },
            {
                value: 'Черновая',
                text: 'Черновая',
            },
            {
                value: 'Чистовая',
                text: 'Чистовая',
            }
        ],
        statusChoices: [
            {
                value: 'Квартира',
                text: 'Квартира',
            },
            {
                value: 'Апартаменты',
                text: 'Апартаменты',
            }
        ],
        balconyChoices: [
            {
                value: 'Балкон',
                text: 'Балкон',
            },
            {
                value: 'Лоджия',
                text: 'Лоджия',
            },
            {
                value: 'Нет',
                text: 'Нет',
            },
        ],
        viewChoices: [
            {
                value: 'На улицу',
                text: 'На улицу',
            },
            {
                value: 'Во двор',
                text: 'Во двор',
            },
        ],
        passengerElevatorChoices: [
            {
                value: 'Нет',
                text: 'Нет',
            },
            {
                value: '1',
                text: '1',
            },
            {
                value: '2',
                text: '2',
            },
            {
                value: '2',
                text: '2',
            },
            {
                value: '3',
                text: '3',
            },
            {
                value: '4',
                text: '4',
            },
        ],
        freightElevatorChoices: [
            {
                value: 'Нет',
                text: 'Нет',
            },
            {
                value: '1',
                text: '1',
            },
            {
                value: '2',
                text: '2',
            },
            {
                value: '2',
                text: '2',
            },
            {
                value: '3',
                text: '3',
            },
            {
                value: '4',
                text: '4',
            },
        ],
        courtyardChoices: [
            {
                value: 'Закрытая территория',
                text: 'Закрытая территория',
            },
            {
                value: 'Детская площадка',
                text: 'Детская площадка',
            },
            {
                value: 'Спортивная площадка',
                text: 'Спортивная площадка',
            },
        ],
        parkingChoices: [
            {
                value: 'Подземная',
                text: 'Подземная',
            },
            {
                value: 'Наземная многоуровневая',
                text: 'Наземная многоуровневая',
            },
            {
                value: 'За шлагбаумом во дворе',
                text: 'За шлагбаумом во дворе',
            },
            {
                value: 'Открытая во дворе',
                text: 'Открытая во дворе',
            },
        ],
        bathroomChoices: [
            {
                value: 'Совмещенный',
                text: 'Совмещенный',
            },
            {
                value: 'Раздельный',
                text: 'Раздельный',
            },
            {
                value: 'Несколько',
                text: 'Несколько',
            },
        ],
        ndAdditionallyChoices: [
            {
                value: 'Гардеробная',
                text: 'Гардеробная',
            },
            {
                value: 'Панорамные окна',
                text: 'Панорамные окна',
            },
        ],
        dealTypeChoices: [
            {
                value: 'Прямая продажа',
                text: 'Прямая продажа',
            },
            {
                value: 'Альтернативная',
                text: 'Альтернативная',
            },
        ],
        roomTypeChoices: [
            {
                value: 'Смежные',
                text: 'Смежные',
            },
            {
                value: 'Изолированные',
                text: 'Изолированные',
            },
        ],
        objectTypeChoices: [
            {
                value: 'Офисное помещение',
                text: 'Офисное помещение',
                typeId: 'office',
            },
            {
                value: 'Помещение свободного назначения',
                text: 'Помещение свободного назначения',
                typeId: 'free',
            },
            {
                value: 'Торговое помещение',
                text: 'Торговое помещение',
                typeId: 'commercial',
            },
        ],
        additionalObjectTypeChoices: {
            office: [
                {
                    value: 'Торговое помещение',
                    text: 'Торговое помещение',
                },
            ],
            free: [
                {
                    value: 'Офис',
                    text: 'Офис',
                },
                {
                    value: 'Торговое помещение',
                    text: 'Торговое помещение',
                },
                {
                    value: 'Производство',
                    text: 'Производство',
                },
                {
                    value: 'Склад',
                    text: 'Склад',
                },
                {
                    value: 'Общепит',
                    text: 'Общепит',
                },
                {
                    value: 'Гостиница',
                    text: 'Гостиница',
                },
            ],
            commercial: [
                {
                    value: 'Офис',
                    text: 'Офис',
                },
                {
                    value: 'Общепит',
                    text: 'Общепит',
                },
            ]
        },
        buildingClassChoices: [
            {
                value: 'A',
                text: 'A',
            },
            {
                value: 'B',
                text: 'B',
            },
            {
                value: 'C',
                text: 'C',
            },
            {
                value: 'D',
                text: 'D',
            },
        ]
    }
}
