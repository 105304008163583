<!--<template>-->
<!--  <v-app class='font-weight-light'>-->
<!--    <v-toolbar fixed app flat>-->
<!--      <v-toolbar-title>-->
<!--        <span class = 'headline'>XML</span><span class = 'headline red&#45;&#45;text text&#45;&#45;darken-4'>.art</span>-->
<!--      </v-toolbar-title>-->
<!--    </v-toolbar>-->
<!--    <v-content>-->
<!--      <v-container fluid fill-height my-0 mx-auto pa-0 style = 'width: 90%;'>-->
<!--        <router-view />-->
<!--      </v-container>-->
<!--    </v-content>-->
<!--    <v-footer class = 'px-2'>-->
<!--      <div><a href="mailto:support@artics.ru">support@artics.ru</a></div>-->
<!--      <v-spacer></v-spacer>-->
<!--      <div>Artics LLC &copy; {{ new Date().getFullYear() }}</div>-->
<!--    </v-footer>-->
<!--  </v-app>-->
<!--</template>-->

<template>
    <v-app class='font-weight-light'>
        <v-app-bar app fixed
                   :color='this.$vuetify.theme.dark? `default`:`grey lighten-3`'
                   :style='`box-shadow: 0 0 40px 2px ${$store.getters.appTheme.shadow};`'
                   class='px-3'
        >
            <v-toolbar-title class='mx-2'>
                <router-link
                    :to="{ name: 'projects' }"
                    tag="span"
                    :style='{backgroundImage: `url(${require(`@/assets/feed_logo.svg`)})`}'
                    class='title font-weight-light company_logo'
                >
                </router-link>
            </v-toolbar-title>
            <v-divider vertical class='ml-2 mr-4 py-2' inset
                       v-if='$store.state.isLoggedIn && !$store.getters.objectsProgress'></v-divider>
            <v-toolbar-items v-if='$store.state.isLoggedIn && !$store.getters.objectsProgress'>
                <main-menu></main-menu>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <span
                class='body-1 font-weight-light px-12 mx-2'
                v-if='$vuetify.breakpoint.smAndUp && $route.name != "auth" && $route.name != "about"'
                v-html='$store.state.headerSecondary'
            ></span>
            <user-menu v-if='$store.state.isLoggedIn' class='mr-0'></user-menu>


        </v-app-bar>

        <v-content>
            <!--            <v-layout fill-height column align-center justify-center v-if='$store.getters.objectsProgress'>-->
            <!--                <v-progress-circular indeterminate :color='$store.getters.uiAccent' size='32'-->
            <!--                                     width='1'></v-progress-circular>-->
            <!--            </v-layout>-->
            <v-layout fill-height column align-center justify-center v-if='$store.getters.objectsProgress'>
                <v-progress-circular indeterminate :color='$store.getters.uiAccent' size='32'
                                     width='1'></v-progress-circular>
            </v-layout>
            <router-view v-if='!$store.getters.objectsProgress || !$route.meta.requiresAuth'/>
        </v-content>

        <v-footer :color='this.$vuetify.theme.dark? `default`:`grey lighten-3`'
                  :style='`box-shadow: 0 0 40px 2px ${$store.getters.appTheme.shadow};`'>
            <v-layout row class='mx-3' justify-space-between>
                <span class='caption'>Artics Internet Solutions &copy; {{ new Date().getFullYear() }}</span>
            </v-layout>
        </v-footer>

    </v-app>
</template>

<style>
    html, .theme--light.v-application {
        background: #ECECEC;
    }

    a.header__link__black {
        color: black !important;
        text-decoration: none !important;
    }

    a.header__link__black:hover {
        text-decoration: underline !important;
    }

    .theme--light.v-menu__content {
        border-radius: 4px !important;
        box-shadow: 0px 0px 30px 1px rgba(217, 217, 217, 0.9) !important;
    }

    .theme--dark.v-menu__content {
        border-radius: 4px !important;
        box-shadow: 0px 0px 30px 1px rgba(51, 51, 51, 0.5) !important;
    }

    .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }

    .theme--light .v-text-field--outline > .v-input__control > .v-input__slot {
        border-width: 1px !important;
    }

    .theme--light .v-expansion-panel__header {
        padding: 0 16px !important;
    }

    .nav_menu_toggle {
        border-radius: 0 !important;
        text-decoration: none !important;
        font-weight: normal !important;
        font-size: 18px !important;
        user-select: none !important;
    }

    .theme--light .nav_menu_toggle {
        color: #212121 !important;
    }

    .theme--dark .nav_menu_toggle {
        color: #b8b8b8 !important;
    }

    .theme--light .nav_menu_toggle:hover {
        color: #757575 !important;
    }

    .theme--dark .nav_menu_toggle:hover {
        color: #ebebeb !important;
    }

    .company_logo {
        display: flex;
        align-items: center;
        order: 1;
        cursor: pointer;
        height: 100%;
        min-width: 112px;
        min-height: 64px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .header_title {
        order: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        font-size: 26px;
    }

    .v-breadcrumbs__item {
        font-size: 12px;
    }

    .theme--light .v-breadcrumbs__item {
        color: #424242 !important;
    }

    .theme--dark .v-breadcrumbs__item {
        color: #ebebeb !important;
    }

    .v-breadcrumbs__item:hover {
        text-decoration: underline;
    }

    .theme--light .v-breadcrumbs li:nth-child(even) {
        padding: 0 0 !important;
    }

    .v-toolbar__content {
        padding: 0 !important;
    }
</style>

<script>
    import UserMenu from '@/components/elements/complex/UserMenu.vue'
    import MainMenu from '@/components/elements/complex/MainMenu.vue'

    export default {
        name: 'App',
        components: {
            'user-menu': UserMenu,
            'main-menu': MainMenu
        },
        data: () => ({}),
        mounted() {
            this.$vuetify.theme.dark = this.isUiDark == 'dark'
        },
        watch: {
            isUiDark(value) {
                this.$vuetify.theme.dark = value == 'dark'
            }
        },
    }
</script>
