export default {
    ya: {

        dealStatuses: [
            {
                value: 'direct rent',
                text: 'Прямая аренда',
            },
            {
                value: 'subrent',
                text: 'Субаренда',
            },
            {
                value: 'sale of lease rights',
                text: 'Продажа права аренды',
            },
        ],
        offerTypes: [
            {
                value: 'residential',
                text: 'Жилая'
            },
            // {
            //     value: 'commercial',
            //     text: 'Коммерческая'
            // }
        ],
        categories: {
            residential: [
                {
                    value: 'flat',
                    text: 'Квартира'
                }
            ],
            commercial: [
                {
                    value: 'commercial',
                    text: 'Коммерческая'
                }
            ]
        },
        vasChoices: [
            {
                value: 'premium',
                text: 'Premium'
            },
            {
                value: 'raise',
                text: 'Raise'
            },
            {
                value: 'promotion',
                text: 'Promotion'
            }
        ],
        regions: [
            {}
        ],
        renovationChoices: [
            {
                value: 'чистовая отделка',
                text: 'Чистовая отделка',
            },
            {
                value: 'под ключ',
                text: 'Под ключ',
            },
            {
                value: 'черновая отделка',
                text: 'Черновая отделка',
            },
        ],
        renovationCommercialChoices: [
            {
                value: 'дизайнерский',
                text: 'Дизайнерский',
            },
            {
                value: 'евро',
                text: 'Евро',
            },
            {
                value: 'с отделкой',
                text: 'С отделкой',
            },
            {
                value: 'требует ремонта',
                text: 'Требует ремонта',
            },
            {
                value: 'хороший',
                text: 'Хороший',
            },
            {
                value: 'частичный ремонт',
                text: 'Частичный ремонт',
            },
            {
                value: 'черновая отделка',
                text: 'Черновая отделка',
            },
        ],
        onlineShowChoices: [
            {
                value: 0,
                text: 'Нет'
            },
            {
                value: 1,
                text: 'Да'
            }
        ],
        roomTypeChoices: [
            {
                value: 'смежные',
                text: 'Смежные',
            },
            {
                value: 'раздельные',
                text: 'Раздельные'
            }
        ],
        apartmentChoices: [
            {
                value: 0,
                text: 'Нет'
            },
            {
                value: 1,
                text: 'Да'
            },
        ],
        windowViewChoices: [
            {
                value: 'во двор',
                text: 'Во двор'
            },
            {
                value: 'на улицу',
                text: 'На улицу'
            },
        ],
        floorCoverChoices: [
            {
                value: 'ковролин',
                text: 'Ковролин'
            },
            {
                value: 'ламинат',
                text: 'Ламинат'
            },
            {
                value: 'линолеум',
                text: 'Линолеум'
            },
            {
                value: 'паркет',
                text: 'Паркет'
            },
        ],
        buildingStateChoices: [
            {
                value: 'built',
                text: 'Дом построен, но не сдан'
            },
            {
                value: 'hand-over',
                text: 'Сдан в эксплуатацию'
            },
            {
                value: 'unfinished',
                text: 'Дом строится'
            },
        ],
        quarterChoices: [
            {
                value: 1,
                text: 1
            },
            {
                value: 2,
                text: 2
            },
            {
                value: 3,
                text: 3
            },
            {
                value: 4,
                text: 4
            },
        ],
        buildingTypeChoices: [
            {
                value: "кирпичный",
                text: "Кирпичный"
            },
            {
                value: "монолит",
                text: "Монолит"
            },
            {
                value: "панельный",
                text: "Панельный"
            },
        ],
        yesNoChoices: [
            {
                value: 0,
                text: 'Нет'
            },
            {
                value: 1,
                text: 'Да'
            },
        ],
        objectStates: [
            {
                value: 'отличное',
                text: 'отличное',
            },
            {
                value: 'хорошее',
                text: 'хорошее',
            },
            {
                value: 'нормальное',
                text: 'нормальное',
            },
            {
                value: 'плохое',
                text: 'плохое',
            },
        ],
        entranceTypes: [
            {
                value: 'common',
                text: 'Общий',
            },
            {
                value: 'separate',
                text: 'Отдельный',
            },
        ],
        windowTypes: [
            {
                value: 'витринные',
                text: 'Витринные',
            },
            {
                value: 'маленькие',
                text: 'Маленькие',
            },
            {
                value: 'обычные',
                text: 'Обычные',
            },
        ],
        officeClasses: [
            {
                value: 'A',
                text: 'A',
            },
            {
                value: 'A+',
                text: 'A+',
            },
            {
                value: 'B',
                text: 'B',
            },
            {
                value: 'B+',
                text: 'B+',
            },
            {
                value: 'C',
                text: 'C',
            },
            {
                value: 'C+',
                text: 'C+',
            },
        ]
    }
}
