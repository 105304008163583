<template>
    <v-layout column align-center justify-center mt-5>
        <v-slide-x-transition key='0' mode='out-in' hide-on-leave>
            <v-container class='py-0 mb-2'>
                <v-layout d-flex row wrap class='my-1'
                          v-bind:style='"width: " + ($vuetify.breakpoint.smAndUp? 75: 100 ) + "%; margin: auto;"'>
                    <v-list-item>
                        <v-list-item-action style="margin: 0 auto">
                            <v-btn @click.stop.prevent="visibility.create = true">
                                <v-icon small>mdi-plus</v-icon>
                                Добавить новый проект
                            </v-btn>
                        </v-list-item-action>
                        <v-list-item-action style="margin: 0 auto">
                            <v-btn @click.stop.prevent="getProjects">
                                <v-icon small>mdi-refresh</v-icon>
                                Обновить список
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-layout>

            </v-container>

        </v-slide-x-transition>

        <v-slide-x-transition key='2' mode='out-in' hide-on-leave>
            <v-container fluid class='py-0 mb-2'>
                <v-layout d-flex row wrap class='my-1'
                          v-bind:style='"width: " + ($vuetify.breakpoint.smAndUp? 75: 100 ) + "%; margin: auto;"'>
                    <v-list
                        :style='`width: 100%; box-shadow: 0px 0px 30px 2px ${$store.getters.appTheme.shadow}; border-radius: 4px; overflow:hidden;`'
                        class='pa-0'>
                        <v-list-group class='pt-0' no-action append-icon='' :value="true">
                            <template v-slot:activator>
                                <v-subheader style='width: 100%;' class='pa-0'>
                                    <span :class='`font-weight-regular ${!$vuetify.theme.dark? "black--text":""}`'
                                          style='font-size: 18px; user-select: none;'>Доступные проекты</span>
                                    <v-icon class='ml-auto' :color='$store.getters.uiAccent'>mdi-history</v-icon>
                                </v-subheader>
                            </template>
                            <template>
                                <v-list class='ma-0 pa-0'>
                                    <v-divider :color='$store.getters.uiAccent' class='my-0'></v-divider>
                                    <o-group :projects='projects' @requestUpdateReportsList='getProjects'></o-group>
                                </v-list>
                            </template>
                        </v-list-group>
                    </v-list>
                </v-layout>
            </v-container>
        </v-slide-x-transition>
        <project-create
            :initialState.sync='visibility.create'
            :postAction="getProjects"
        >

        </project-create>
    </v-layout>


</template>


<script>
    import ObjectsListGroup from '@/components/elements/groups/ObjectsListGroup.vue';
    import CreateDialog from "@/components/entities/projects/CreateDialog";

    export default {
        components: {
            'o-group': ObjectsListGroup,
            'project-create': CreateDialog
        },

        data() {
            return {
                indicator: false,
                projects: [],
                visibility: {
                    create: false,
                    check: true,
                    update: false,
                    delete: false
                },
            }
        },
        methods: {
            getProjects() {
                this.indicator = true;
                this.projects = null;
                this.$http.get(
                    this.$store.getters.endpoint + '/projects', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin
                        },
                        headers: this.$store.state.restrictedHeaders
                    }
                ).then(
                    r => {
                        this.indicator = false;
                        let response = r.body;
                        if (response.result) {
                            this.projects = response.data;
                            this.$store.commit('authorize2', {objects: response.data || []})
                            this.$store.commit('updateUserProjects', this.projects.map(e => {
                                return {
                                    id: e.id,
                                    name: e.name
                                }
                            }))
                            let buildings = [];
                            let housings = [];
                            this.projects.forEach(p => {
                                if (p.buildings && p.buildings.length) {
                                    buildings.push(...p.buildings)
                                }
                                p.buildings.forEach(b => {
                                    if (b.housings && b.housings.length) {
                                        housings.push(...b.housings)
                                    }
                                })

                            });
                            this.$store.commit('updateUserBuildings', buildings);
                            this.$store.commit('updateUserHousings', housings);
                        }
                    }
                )
            },
        },
        mounted() {
            this.getProjects();
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.$store.commit('setBreadcrumbs',
                    [
                        {text: 'Главная', disabled: false, href: '/'},
                        {
                            text: 'Проекты',
                            disabled: true,
                            ripple: true
                        }
                    ]
                )
            });
        },
    }
</script>
