<template>
    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>

        <template v-slot:title>Добавление ЖК</template>
        <template v-slot:action>
            <v-btn
                text
                :disabled='!valid'
                :loading='indicator'
                color='green darken-2'
                @click="createBuilding"
            >
                Создать
            </v-btn>
        </template>

        <template v-slot:content>

            <v-form v-model='valid' style='min-width: 320px; width: 600px;' v-if="visibility">

                <v-text-field
                    v-model="building.name"
                    label="Название жилого комплекса"
                    :rules="[rules.required]"
                ></v-text-field>

                <v-select
                    v-model="building.building_type"
                    :items="categories"
                    label="Выберите категорию ЖК"
                    @change="setCategoryForCian"
                ></v-select>

                <v-card flat>
                    <v-tabs
                        v-model='activeTab'
                        :slider-color='$store.getters.uiAccent'
                        :color='$store.getters.uiAccent'
                        grow
                        show-arrows
                        input='boundTabEvent'
                        :value='activeTab'
                    >

                        <v-tab key='0' ripple>Яндекс</v-tab>
                        <v-tab key='1' ripple>Авито</v-tab>
                        <v-tab key='2' ripple>ЦИАН</v-tab>
                    </v-tabs>
                </v-card>

                <v-tabs-items v-model='activeTab' v-if="visibility">
                    <v-tab-item key="0">
                        <br>

                        <v-autocomplete
                            :items="ya.buildings"
                            v-model="building.yandex_id"
                            label="Идентификатор жилого комплекса в базе Яндекса"
                            :loading="isLoadingYandex"
                            :filter="filterHousing"
                            clearable
                        >
                            <template slot='append-outer'>
                                <v-btn small icon @click="getYABuilding">
                                    <v-icon>mdi-reload</v-icon>
                                </v-btn>
                            </template>
                        </v-autocomplete>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="building.yandex.guarded_building"
                            clearable
                            label="Закрытая территория"
                        ></v-select>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="building.yandex.parking"
                            clearable
                            label="Наличие охраняемой парковки"
                        ></v-select>

                        <template v-if="building.building_type == 'new_building'">



                            <v-select
                                :items="ya.buildingTypeChoices"
                                v-model="building.yandex.building_type"
                                clearable
                                label="Тип дома"
                            ></v-select>

<!--                            <v-text-field-->
<!--                                v-model="building.yandex.building_series"-->
<!--                                label="Серия дома"-->
<!--                                clearable-->
<!--                            ></v-text-field>-->

<!--                            <v-select-->
<!--                                :items="ya.yesNoChoices"-->
<!--                                v-model="building.yandex.is_elite"-->
<!--                                clearable-->
<!--                                label="Элитная недвижимость"-->
<!--                            ></v-select>-->

                        </template>

                        <template v-if="building.building_type == 'commercial_building'">

                            <v-text-field
                                v-model.number="building.yandex.parking_places"
                                type="number"
                                label="Количество предоставляемых парковочных мест"
                            ></v-text-field>

                            <v-text-field
                                v-model.number="building.yandex.parking_place_price"
                                type="number"
                                label="Стоимость парковочного места"
                                placeholder="Указывается стоимость одного места в месяц в рублях"
                            ></v-text-field>

                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="building.yandex.parkin_guest"
                                label="Наличие гостевых парковочных мест"
                                clearable
                            ></v-select>

                            <v-text-field
                                v-model.number="building.yandex.parking_guest_places"
                                type="number"
                                label="Количество предоставляемых парковочных мест"
                                clearable
                            ></v-text-field>

                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="building.yandex.air_conditioner"
                                label="Наличие системы кондиционирования"
                                clearable
                            ></v-select>

                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="building.yandex.ventilation"
                                label="Наличие вентиляции"
                                clearable
                            ></v-select>

                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="building.yandex.fire_alarm"
                                label="Наличие пожарной сигнализации"
                                clearable
                            ></v-select>

                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="building.yandex.water_supply"
                                label="Наличие водопровода"
                                clearable
                            ></v-select>

                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="building.yandex.sewerage_supply"
                                label="Наличие канализации"
                                clearable
                            ></v-select>

                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="building.yandex.electricity_supply"
                                label="Наличие электроснабжения"
                                clearable
                            ></v-select>

                            <v-text-field
                                v-model.number="building.yandex.electric_capacity"
                                type="number"
                                clearable
                                label="Выделенная электрическая мощность"
                            ></v-text-field>

                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="building.yandex.gas_supply"
                                label="Подключение к газовым сетям"
                                clearable
                            ></v-select>

                            <v-select
                                :items="ya.yesNoChoices"
                                v-model="building.yandex.heating_supply"
                                label="Наличие отопления"
                                clearable
                            ></v-select>

                        </template>

                    </v-tab-item>

                    <v-tab-item key="1">

                        <br>


                        <template v-if="building.building_type == 'new_building'">
                            <v-autocomplete
                                :items="avito.buildings"
                                v-model="building.avito_id"
                                label="Объект новостройки из справочника Avito"
                                :loading="isLoadingAvito"
                                :filter="filterHousing"
                                clearable
                            >
                                <template slot='append-outer'>
                                    <v-btn small icon @click="getAvitoBuilding">
                                        <v-icon>mdi-reload</v-icon>
                                    </v-btn>
                                </template>
                            </v-autocomplete>
                        </template>

                        <template v-if="building.building_type == 'commercial_building'">
<!--                            <v-autocomplete-->
<!--                                :items="avito.buildingClassChoices"-->
<!--                                v-model="building.avito.building_class"-->
<!--                                clearable-->
<!--                                label="Класс здания"-->
<!--                            ></v-autocomplete>-->

<!--                            <v-autocomplete-->
<!--                                :items="avito.objectTypeChoices"-->
<!--                                v-model="building.avito.object_type"-->
<!--                                clearable-->
<!--                                label="Вид объекта"-->
<!--                                @change="getAdditionalObjects"-->
<!--                            ></v-autocomplete>-->

<!--                            <v-autocomplete-->
<!--                                :items="avito._additional"-->
<!--                                v-model="building.avito.additional_object_type"-->
<!--                                clearable-->
<!--                                multiple-->
<!--                                max-selected="2"-->
<!--                                label="Вид объекта - дополнительные варианты"-->
<!--                            ></v-autocomplete>-->


                        </template>

                    </v-tab-item>

                    <v-tab-item key="2">

                        <br>

                        <v-autocomplete
                            :items="cian.buildings"
                            v-model="building.cian_id"
                            label="ID ЖК в базе CIAN"
                            :loading="isLoadingCian"
                            :filter="filterHousing"
                            clearable
                        >
                            <template slot='append-outer'>
                                <v-btn small icon @click="getCianBuildings">
                                    <v-icon>mdi-reload</v-icon>
                                </v-btn>
                            </template>
                        </v-autocomplete>

                        <v-select
                            :items="cian.categories"
                            :rules="[rules.required]"
                            v-model="building.category"
                            label="Категория объявления *"
                            @change="changeCategory"
                            clearable
                            v-if="building.building_type == 'commercial_building'"
                        ></v-select>


                        <!-- Новостройка -->
                        <template v-if="isNewBuildingFlatSale()">

                            <v-select
                                :items="cian.materialTypeChoices.slice(0, 3)"
                                v-model="building.cian.material_type"
                                clearable
                                label="Тип дома"
                            ></v-select>

                            <v-select
                                :items="cian.parkingChoices"
                                v-model="building.cian.parking_type"
                                clearable
                                label="Тип парковки"
                            ></v-select>

                        </template>
                        <!-- Новостройка -->

                        <!-- Офис -->
                        <template v-if="isOfficeSale()">

                            <v-select
                                :items="cian.materialTypeChoices.slice(0,3)"
                                v-model="building.cian.material_type"
                                clearable
                                label="Тип дома"
                            ></v-select>

                            <v-select
                                :items="cian.heatingTypes"
                                v-model="building.cian.heating_type"
                                clearable
                                label="Отопление"
                            ></v-select>

                            <v-select
                                :items="cian.parkingChoices"
                                v-model="building.cian.parking_type"
                                clearable
                                label="Тип парковки"
                            ></v-select>

                            <v-text-field
                                v-model="building.cian.developer"
                                label="Застройщик"
                            ></v-text-field>
                            <v-text-field
                                v-model="building.cian.management_company"
                                label="Управляющая компания"
                            ></v-text-field>

                        </template>
                        <!-- Офис -->

                        <!-- Торговая площадь -->
                        <template v-if="isShoppingAreaSale()">
                            <v-select
                                :items="cian.heatingTypes"
                                v-model="building.cian.heating_type"
                                clearable
                                label="Отопление"
                            ></v-select>
                        </template>
                        <!-- Торговая площадь -->

                        <!-- Помещение свободного назначения -->
                        <template v-if="isFreeAppointmentObjectSale()">
                            <v-select
                                :items="cian.heatingTypes.slice(0,3)"
                                v-model="building.cian.heating_type"
                                clearable
                                label="Отопление"
                            ></v-select>

                            <v-select
                                :items="cian.ventilationTypes"
                                v-model="building.cian.ventilation_type"
                                clearable
                                label="Вентиляция"
                            ></v-select>

                            <v-select
                                :items="cian.conditioningTypes"
                                v-model="building.cian.conditioning_type"
                                clearable
                                label="Кондиционирование"
                            ></v-select>

                            <v-select
                                :items="cian.extinguishingSystemTypes"
                                v-model="building.cian.extinguishing_system"
                                clearable
                                multiple
                                label="Системы пожаротушения"
                            ></v-select>
                        </template>
                        <!-- Помещение свободного назначения -->


                    </v-tab-item>
                </v-tabs-items>

            </v-form>

        </template>

    </dialog-fullscreen>

</template>

<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue'
    import yaVariables from "../../../store/yaVariables";
    import cianVariables from "../../../store/cianVariables";
    import avitoVariables from "../../../store/avitoVariables";

    export default {
        name: 'BuildingCreateDialog',
        props: ['initialState', 'postAction', 'projectData'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                valid: false,
                indicator: false,
                visibility: this.initialState,
                project: this.projectData,
                errorCreate: false,
                errorCreateMessage: '',
                activeTab: 0,
                isLoadingYandex: false,
                isLoadingCian: false,
                isLoadingAvito: false,
                rules: {
                    required: value => !!value || 'Обязательное поле.',
                    email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный электронный адрес',
                    maxLength: v => v && v.length <= 40 || 'Макс 40 символов',
                    descriptionMaxLength: v => v && v.length <= 5000 || 'Макс 5000 символов',
                },
                categories: [
                    {
                        value: 'new_building',
                        text: 'Новостройка'
                    },
                    {
                        value: 'commercial_building',
                        text: 'Коммерческая недвижимость'
                    },
                ],
                ya: {
                    buildings: [],
                    ...yaVariables.ya
                },
                cian: {
                    buildings: [],
                    ...cianVariables.cian
                },
                avito: {
                    building: [],
                    ...avitoVariables.avito
                },
                building: {
                    project_id: this.projectId,
                    name: null,
                    building_type: null,
                    yandex: {},
                    avito: {},
                    cian: {},
                    category: null,
                    yandex_id: null,
                    cian_id: null,
                    avito_id: null
                }
            }
        },
        methods: {
            isNewBuildingFlatSale() {
                return this.building.category == 'newBuildingFlatSale';
            },
            isOfficeSale() {
                return this.building.category == 'officeSale';
            },
            isShoppingAreaSale() {
                return this.building.category == 'shoppingAreaSale';
            },
            isFreeAppointmentObjectSale() {
                return this.building.category == 'freeAppointmentObjectSale';
            },
            filterHousing(item, queryText, itemText) {
                return (item.text.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
                    item.value.toString().toLocaleLowerCase().includes(queryText.toLocaleLowerCase()))
            },
            getYABuilding() {
                this.isLoadingYandex = true;
                this.$http.get(
                    this.$store.getters.endpoint + '/yandex/buildings', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin,
                        },
                        headers: this.$store.state.restrictedHeaders
                    },
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.ya.buildings = response.data;
                        this.isLoadingYandex = false;
                    } else {
                        console.log(response)
                    }
                })
            },
            getCianBuildings() {
                this.isLoadingCian = true;
                this.cian.buildings = [];
                this.$http.get(
                    this.$store.getters.endpoint + '/cian/buildings', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin,
                        },
                        headers: this.$store.state.restrictedHeaders
                    },
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.cian.buildings = response.data;
                    } else {
                        console.log(response)
                    }
                    this.isLoadingCian = false;
                })
            },
            getAvitoBuilding() {
                this.isLoadingAvito = true;
                this.$http.get(
                    this.$store.getters.endpoint + '/avito/buildings', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin,
                        },
                        headers: this.$store.state.restrictedHeaders
                    },
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.avito.buildings = response.data

                    } else {
                        console.log(response)
                    }
                    this.isLoadingAvito = false;
                })
            },
            changeCategory() {
                if (this.building.category != 'shoppingAreaSale') {
                    delete this.building.cian.placement_type
                }
            },
            setDefaultBuilding() {
                this.building = {
                    name: null,
                    building_type: null,
                    yandex: {},
                    avito: {},
                    cian: {},
                    category: null,
                    yandex_id: null,
                    cian_id: null,
                    avito_id: null
                }
            },
            createBuilding() {
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin
                }
                this.building.project_id = this.project.id;
                this.$http.post(this.$store.getters.endpoint + '/buildings',
                    {...requestData, ...this.building},
                    {
                        headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                    }).then((r) => {
                    let response = r.body;
                    this.indicator = false;
                    if (response.result) {
                        this.$emit('requestUpdateReportsList', 'requestUpdateReportsList');
                        this.visibility = false;
                    } else {
                        this.errorCreate = true;
                        this.errorCreateMessage = ['Идентификатор уже используется в другом объекте']
                    }
                })
            },
            setCategoryForCian() {
                if (this.building.building_type == 'new_building') {
                    this.building.category = 'newBuildingFlatSale'
                } else {
                    this.building.category = null;
                }
            }
        },
        watch: {
            initialState(value) {
                if (value) {
                    this.getYABuilding();
                    this.getCianBuildings();
                    this.getAvitoBuilding();
                    this.visibility = value;
                    this.setDefaultBuilding();
                }
            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
            projectData(value) {
                this.project = value;
            }

        },
        mounted() {
            this.setDefaultBuilding();
        }
    }
</script>
