import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import GoogleIcon from '@/components/elements/misc/GoogleIcon.vue'
import ru from 'vuetify/es5/locale/ru'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  options: {
    customProperties: true
  },
  lang: {
    locales: { ru },
    current: 'ru'
  },
  icons: {
    iconfont: 'mdi',
    values: {
      'gcolorized': {
        component: GoogleIcon
      }
    }
  }
})
