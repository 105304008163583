export default {
    replaceKeys: [
        {
            value: '~общая_площадь~',
            text: 'Общая площадь (Вывод например: 23.3 кв. м)',
        },
        {
            value: '~жилая_площадь~',
            text: 'Жилая площадь (Вывод например: 23.3 кв. м)',
        },
        {
            value: '~площадь_кухни~',
            text: 'Площадь кухни (Вывод например: 23.3 кв. м)',
        },
        {
            value: '~комнатность~',
            text: 'Комнатность (Вывод например: 2)',
        },
        {
            value: '~студия~',
            text: 'Студия (Вывод например: студия / [пусто] )',
        },
        {
            value: '~этаж~',
            text: 'Этаж (Вывод например: 14)',
        },
        {
            value: '~ипотека~',
            text: 'Ипотека (Вывод например: с ипотекой / без ипотеки)',
        },
        {
            value: '~цена~',
            text: 'Цена (Вывод например: 29 614 008 руб.)',
        },
    ]
}
