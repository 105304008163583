<template>
    <dialog-fullscreen :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>Редактирование фида Яндекс</template>
        <template v-slot:action>
            <v-btn
                text
                color='green darken-2'
                @click="print"
            >
                Обновить
            </v-btn>
        </template>
        <template v-slot:content>

            <v-card flat>
                <v-tabs
                    v-model='activeTab'
                    :slider-color='$store.getters.uiAccent'
                    :color='$store.getters.uiAccent'
                    grow
                    show-arrows
                    input='boundTabEvent'
                    :value='activeTab'
                >

                    <v-tab key='0' ripple>Общая информация</v-tab>
                    <v-tab key='1' ripple>Информация о продавце</v-tab>
                    <v-tab key='2' ripple>Информация об условиях сделки</v-tab>
                    <v-tab key='3' ripple>Информация об объекте</v-tab>
                    <v-tab key='4' ripple>Описание нежилого помещения</v-tab>
                </v-tabs>

            </v-card>
            <v-tabs-items v-model='activeTab' v-if="visibility">
                <v-tab-item key='0'>
                    <v-form v-model="validGeneralInfo" style='min-width: 320px;'>
                        <v-text-field
                            v-model="feed.yandex.type"
                            disabled
                            label="Тип сделки"
                        ></v-text-field>

<!--                        <v-text-field-->
<!--                            v-model="feed.yandex.property_type"-->
<!--                            disabled-->
<!--                            label="Тип недвижимости"-->
<!--                        ></v-text-field>-->

<!--                        <v-text-field-->
<!--                            v-model="feed.yandex.category"-->
<!--                            disabled-->
<!--                            label="Категория объекта"-->
<!--                        >-->

<!--                        </v-text-field>-->

                        <v-text-field
                            v-model="feed.yandex.url"
                            clearable
                            label="URL страницы с объявлением"
                        >

                        </v-text-field>

                        <v-text-field
                            v-model="feed.yandex.location_country"
                            :rules="[rules.required]"
                            clearable
                            label="Страна, в которой расположен объект *"
                            disabled
                        >

                        </v-text-field>

                        <v-text-field
                            v-model="feed.yandex.location_region"
                            clearable
                            label="Название субъекта РФ"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Необязательный элемент для объектов в Москве и Санкт-Петербурге.
                                </div>
                            </v-tooltip>

                        </v-text-field>

                        <v-text-field
                            v-model="feed.yandex.location_district"
                            clearable
                            label="Название района субъекта РФ"
                        ></v-text-field>

                        <v-text-field
                            v-model="feed.yandex.location_locality_name"
                            clearable
                            label="Название населенного пункта"
                        ></v-text-field>

                        <v-text-field
                            v-model="feed.yandex.location_sub_locality_name"
                            clearable
                            label="Район населенного пункта"
                        ></v-text-field>

                        <v-text-field
                            v-model="feed.yandex.location_address"
                            clearable
                            label="Улица и номер дома"
                        ></v-text-field>

                        <v-autocomplete
                            :items="ya.vasChoices"
                            v-model="feed.yandex.vas"
                            clearable
                            label="Дополнительная услуга по продвижению объявления"
                        ></v-autocomplete>

                    </v-form>
                </v-tab-item>
                <v-tab-item key='1'>
                    <v-form style='min-width: 320px;'>
                        <v-text-field
                            v-model="feed.yandex.sales_agent_name"
                            label="Имя продавца, арендодателя или агента"
                        ></v-text-field>
                        <v-text-field
                            v-model="feed.yandex.sales_agent_phone"
                            label="Номер телефона *"
                            :rules="[rules.required]"
                            required
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Номер указывается в международном формате; +74951234567
                                </div>
                            </v-tooltip>
                        </v-text-field>
                        <v-text-field
                            v-model="feed.yandex.sales_agent_organization"
                            label="Название организации"
                        ></v-text-field>
                        <v-text-field
                            v-model="feed.yandex.sales_agent_url"
                            label="Сайт агентства или застройщика"
                        ></v-text-field>
                        <v-text-field
                            v-model="feed.yandex.sales_agent_email"
                            label="Электронный адрес продавца"
                            :rules="[rules.email]"
                        ></v-text-field>
                        <v-text-field
                            v-model="feed.yandex.sales_agent_photo"
                            label="Ссылка на фотографию агента или логотип компании"
                        ></v-text-field>
                    </v-form>
                </v-tab-item>

                <v-tab-item key="2">

                    <v-form style='min-width: 320px;'>

                        <v-select
                            :items="[{value: 'НДС', text: 'НДС (арендодатель — плательщик НДС)'}, {value: 'УСН', text: 'УСН (арендодатель работает по упрощенной системе налогообложения)'}]"
                            v-model="feed.yandex.taxation_form"
                            label="Форма налогообложения арендодателя для аренды"
                            clearable
                        ></v-select>

                        <v-select
                            :items="[{value: 'day', text: 'День'}, {value: 'month', text: 'Месяц'}]"
                            v-model="feed.yandex.period"
                            label="Длительность аренды"
                            clearable
                        ></v-select>

                        <v-text-field
                            v-model.number="feed.yandex.commission"
                            type="number"
                            label="Размер комиссии для клиента в процентах"
                            placeholder="Указывается числовое значение в процентах без знака «%»."
                            clearable
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Указывается числовое значение в процентах без знака «%».<br>
                                </div>
                            </v-tooltip>
                        </v-text-field>

                        <v-text-field
                            v-model.number="feed.yandex.prepayment"
                            type="number"
                            label="Предоплата"
                            placeholder="Указывается числовое значение в процентах без знака «%»."
                            clearable
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Указывается числовое значение в процентах без знака «%».<br>
                                    Максимальное значение — 100. <br>
                                </div>
                            </v-tooltip>
                        </v-text-field>

                        <v-text-field
                            v-model.number="feed.yandex.security_prepayment"
                            type="number"
                            label="Размер обеспечительного платежа в процентах"
                            placeholder="Указывается числовое значение в процентах без знака «%»."
                            clearable
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Указывается числовое значение в процентах без знака «%».<br>
                                    Максимальное значение — 600. <br>
                                </div>
                            </v-tooltip>
                        </v-text-field>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="feed.yandex.rent_pledge"
                            label="Залог"
                            clearable
                        ></v-select>

                        <v-select
                            :items="ya.dealStatuses"
                            v-model="feed.yandex.deal_status"
                            label="Тип сделки"
                            clearable
                        ></v-select>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="feed.yandex.cleaning_included"
                            label="Клининг входит в договор аренды"
                            clearable
                        ></v-select>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="feed.yandex.utilities_included"
                            label="Коммунальные услуги включены в стоимость в договоре"
                            clearable
                        ></v-select>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="feed.yandex.electricity_included"
                            label="Электроэнергия включена в стоимость в договоре"
                            clearable
                        ></v-select>

                    </v-form>


                </v-tab-item>

                <v-tab-item key='3'>
                    <v-text-field
                        v-model="feed.yandex.layout_photo"
                        label="Изображение планировки"
                    ></v-text-field>
                    <v-text-field
                        v-model="feed.yandex.floor_plan_photo"
                        label="Изображение поэтажного плана"
                    ></v-text-field>
                    <v-form style='min-width: 320px; width: 800px;'>
                        <v-textarea
                            :rules="[rules.required]"
                            v-model="feed.yandex.images"
                            label="Фотографии объекта *"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Ссылки на фотографии и планировку<br>
                                    Каждая ссылка на отдельной строчке <br>
                                    <b>Первой должна быть фотография планировки</b>
                                </div>
                            </v-tooltip>
                        </v-textarea>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="feed.yandex.is_image_order_change_allowed"
                            label="Умные фотографии"
                        ></v-select>

                        <v-autocomplete
                            :items="ya.renovationCommercialChoices"
                            v-model="feed.yandex.renovation"
                            clearable
                            label="Ремонт"
                        ></v-autocomplete>

                        <v-select
                            :items="ya.objectStates"
                            v-model="feed.yandex.quality"
                            label="Состояние объекта"
                        ></v-select>


                        <v-textarea
                            v-model="feed.yandex.description"
                            label="Дополнительная информация"
                            @keydown="changeDinamicKey"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Описание в свободной форме.
                                </div>
                            </v-tooltip>
                        </v-textarea>

                        <div v-if="mKeys">
                          <div class='key-list-item'
                                v-for='(keyItem, index) in keyItems'
                                :key='index'
                                @click="insertKeyInText"
                                :title=keyItem.text
                               >
                                       {{ keyItem.value }}
                          </div>
                        </div>

                        <v-text-field
                            v-model="feed.yandex.youtube_url"
                            clearable
                            label="Прямая ссылка на видео с объектом с YouTube, без сокращений и счётчиков переходов"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Cсылка должна быть без параметра таймкода, например <i>&t=2s</i>
                                </div>
                            </v-tooltip>
                        </v-text-field>
                        <v-autocomplete
                            :items="ya.onlineShowChoices"
                            v-model="feed.yandex.online_show"
                            clearable
                            label="Показ недвижимости по видео-звонку"
                        >
                            <v-tooltip
                                :open-on-hover="true"
                                top
                                slot="append-outer"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label style="color: red; font-weight: bold" v-bind="attrs" v-on="on"><span><v-icon>info</v-icon></span></label>
                                </template>
                                <div>
                                    Если вы готовы показать недвижимость по видео-звонку, например в WhatsApp, Skype,
                                    Viber или FaceTime,
                                </div>
                            </v-tooltip>
                        </v-autocomplete>
                    </v-form>
                </v-tab-item>
                <v-tab-item key='4'>
                    <v-form style='min-width: 320px; width: 580px;'>

                        <v-select
                            :items="ya.entranceTypes"
                            v-model="feed.yandex.entrance_type"
                            label="Вход в помещение"
                            clearable
                        ></v-select>

                        <v-text-field
                            v-model.number="feed.yandex.phone_lines"
                            type="number"
                            label="Количество телефонных линий"
                            clearable
                        ></v-text-field>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="feed.yandex.adding_phone_on_request"
                            clearable
                            label="Возможность добавления телефонных линий"
                        ></v-select>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="feed.yandex.internet"
                            clearable
                            label="Наличие интернета"
                        ></v-select>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="feed.yandex.self_selection_telecom"
                            clearable
                            label="Возможность самостоятельного выбора оператора телекоммуникационных услуг"
                        ></v-select>

                        <v-select
                            :items="ya.yesNoChoices"
                            v-model="feed.yandex.room_furniture"
                            clearable
                            label="Наличие мебели"
                        ></v-select>

                        <v-select
                            :items="ya.windowTypes"
                            v-model="feed.yandex.window_type"
                            label="Тип окон"
                            clearable
                        ></v-select>

                        <v-select
                            :items="ya.windowViewChoices"
                            v-model="feed.yandex.window_view"
                            clearable
                            multiple
                            label="Вид из окон"
                        ></v-select>

                        <v-select
                            :items="ya.floorCoverChoices"
                            v-model="feed.yandex.floor_covering"
                            clearable
                            multiple
                            label="Покрытие пола"
                        ></v-select>



                    </v-form>

                </v-tab-item>
            </v-tabs-items>
        </template>
    </dialog-fullscreen>
</template>

<style>
    .required label::after {
        content: "*";
    }
</style>
<script>
    import DialogFullscreen from '@/components/elements/dialogs/Fullscreen.vue';
    import yaVariables from "../../../store/yaVariables";
    import feedVariables from "../../../store/feedVariables";

    export default {
        name: 'YandexFlatUpdateDialog',
        props: ['initialState', 'feed'],
        components: {'dialog-fullscreen': DialogFullscreen},
        data() {
            return {
                valid: false,
                validGeneralInfo: false,
                indicator: false,
                visibility: this.initialState,
                activeTab: 0,
                isLoading: false,
                search: null,
                mKeys: false,
                feedVariables: feedVariables,
                keyItems: [],
                currentCursorPosition: 0,
                rules: {
                    required: value => !!value || 'Обязательное поле.',
                    email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неправильный электронный адрес'

                },
                ya: {
                    buildings: [],
                    ...yaVariables.ya
                }
            }
        },
        watch: {
            initialState(value) {
                if (value) {
                    this.getYABuilding();
                    this.visibility = value;
                }

            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
        },
        methods: {
            print() {
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin,
                }
                if (this.feed.yandex.yandex_building) {
                    const yandex_building = this.feed.yandex.yandex_building.split('-')
                    this.feed.yandex.yandex_building_id = yandex_building[0]
                    this.feed.yandex.yandex_house_id = yandex_building[1];
                }
                this.$http.put(this.$store.getters.endpoint + `/feeds/${this.feed.id}/yandex`, {...requestData, yandex: this.feed.yandex}, {
                    headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}
                }).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        console.log(response)
                        this.visibility = false;
                    } else {
                        console.log(response)
                    }
                })
            },
            getYABuilding() {
                this.isLoading = true;
                this.$http.get(
                    this.$store.getters.endpoint + '/yandex/buildings', {
                        emulateJSON: true,
                        params: {
                            'login': this.$store.getters.userLogin,
                            'building_name': this.feed.building_name || '',
                        },
                        headers: this.$store.state.restrictedHeaders
                    },
                ).then((r) => {
                    let response = r.body;
                    if (response.result) {
                        this.ya.buildings = response.data;
                        this.isLoading = false;
                    } else {
                        console.log(response)
                    }
                })
            },
            selectYandexBuilding() {
                console.log(this.feed.yandex.building_yandex_building_id)
                console.log(this.ya.buildings)
            },
            changeDinamicKey(key) {
            let text_description = this.feed.yandex.description
            this.currentCursorPosition = event.path[0].selectionStart
            if (key.keyCode == 192) {
               if (!this.mKeys) {
                   this.mKeys = true
               } else {
                   this.mKeys = false
               }
            }
            this.keyItems = feedVariables.replaceKeys;
            },
            insertKeyInText(e) {
            let F = this.feed.yandex.description.substr(0, this.currentCursorPosition)
            let L = this.feed.yandex.description.substr(this.currentCursorPosition + 1)
            this.feed.yandex.description = F + e.target.textContent.trim() + L

            this.mKeys = false
            }
        }
    }
</script>
