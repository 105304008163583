<template>

    <v-layout d-flex row justify-end style='max-width: 42px;'>

        <v-menu bottom left transition='slide-y-transition' min-width='120px'>
            <template v-slot:activator="{ on }">
                <v-avatar
                        v-on='on'
                        size='42px'
                        style='cursor: pointer;'
                >
                    <img :src='$store.getters.userAvatar'>
                </v-avatar>
            </template>
            <v-layout column>

                <v-card tile flat>
                    <v-card-text class = 'px-5 py-3'>
                        <v-layout d-flex row align-center align-content-center justify-center px-2>
                            <v-avatar size='64px'>
                                <img :src = '$store.getters.userAvatar'>
                            </v-avatar>
                            <v-layout column ml-2 :class = '!this.$vuetify.theme.dark? `black--text`:``'>
                                <span class = 'body-1'>{{$store.getters.currentUser.name}} {{$store.getters.currentUser.lastname}}</span>
                                <v-layout>
                                    <v-icon left x-small color = 'blue lighten-1'>mdi-at</v-icon>
                                    <v-spacer></v-spacer>
                                    <span class = 'font-weight-light ml-auto'>{{$store.getters.currentUser.login}}</span>
                                </v-layout>
                                <v-layout justify-start>
                                    <v-btn
                                        class = 'mt-1'
                                        x-small block
                                        color = 'blue lighten-1'
                                        style = 'text-transform: none !important;'
                                        outlined
                                    >{{$store.getters.currentUser.superuser? 'Администратор': 'Пользователь'}}</v-btn>
                                </v-layout>
                            </v-layout>
                        </v-layout>
                    </v-card-text>
                </v-card>

                <v-divider :color = '$store.getters.uiAccent'></v-divider>
                <v-card tile flat>
                    <v-card-text class = 'px-3 py-2'>
                        <v-btn
                            depressed block
                            large
                            @click = "$store.dispatch('deauthorizeUser')"
                            style = 'text-transform: none !important;'
                            :color = '!this.$vuetify.theme.dark? `red lighten-5`:`error`'
                            :class = '!this.$vuetify.theme.dark? `red--text text--darken-5 mt-1 mb-0`:`mt-1 mb-0`'
                        >
                            <v-icon left class = 'mr-auto'>mdi-power</v-icon>
                            <v-spacer></v-spacer>
                            <span class = 'font-weight-regular ml-auto'>Выйти</span>
                            <v-spacer></v-spacer>
                        </v-btn>
                    </v-card-text>
                </v-card>

            </v-layout>

        </v-menu>

    </v-layout>

</template>
<script>
    export default {
        name: 'UserMenu'
    }
</script>
