<template>
    <dialog-generic :visibility.sync='visibility' :indicator='indicator'>
        <template v-slot:title>{{project.name }}</template>
        <template v-slot:content>
            <div class='py-3'>Удалить проект?</div>
        </template>
        <template v-slot:action>
            <v-btn class='px-0 red--text text--darken-2' :loading='indicator' block depressed @click='deleteReport'>
                Удалить
            </v-btn>
        </template>
    </dialog-generic>
</template>

<script>
    import DialogGeneric from '@/components/elements/dialogs/Generic.vue'

    export default {
        name: 'ProjectDeleteDialog',
        props: ['initialState', 'data'],
        components: {'dialog-generic': DialogGeneric},
        data() {
            return {
                visibility: this.initialState,
                project: this.data,
                indicator: false,
            }
        },
        watch: {
            initialState(value) {
                this.visibility = value
            },
            visibility(value) {
                this.$emit('update:initialState', value);
            },
            data(value) {
                this.project = value
            }
        },
        methods: {
            deleteReport() {
                this.indicator = true;
                let requestHeaders = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + String(this.$store.getters.userToken)
                }
                let requestData = {
                    'login': this.$store.getters.userLogin,
                    'id': this.project.id
                }
                this.$http.patch(this.$store.getters.endpoint + '/projects',
                    requestData,
                    {headers: {...this.$store.state.restrictedHeaders, ...requestHeaders}}
                ).then((r) => {
                    this.$emit('requestUpdateReportsList', 'requestUpdateReportsList')
                    this.visibility = false;
                    this.indicator = false;
                });
            },
        }
    }
</script>