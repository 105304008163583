<template>
    <v-layout column justify-center align-start>
        <v-breadcrumbs :items='$store.state.breadcrumbs' class='pa-0 my-0' divider='>' style='user-select: none;'>
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-menu
                fixed
                transition="slide-y-transition"
                v-model='visibility.list_reports'
                :close-on-content-click='false'
                offset-y bottom left allow-overflow
                :min-width='$vuetify.breakpoint.name == "xs"? "95%":"60%"'
                :max-width='$vuetify.breakpoint.name == "xs"? "95%":"60%"'
                class='px-0'
        >
        </v-menu>
    </v-layout>

</template>


<script>
    export default {
        name: 'MainMenu',
        data() {
            return {
                toggle: {
                    archive_projects: false,
                    archive_reports: false,
                    search: false
                },
                visibility: {
                    create_project: false,
                    list_reports: false
                },
                textFilter: '',
                lastIntersected: 0,
                clients: [],
                projects: [
                    {
                        id: 1,
                        name: 'MR Group'
                    },
                    {
                        id: 2,
                        name: 'Ingrad'
                    }
                ]
            }
        },
        computed: {
            currentMoment() {
                return dayjs()
            },
        },
        methods: {
            formatTime(time) {
                return dayjs(time).format('D MMM YYYY')
            },
            diffTime(time1, time2) {
                return dayjs(time1).diff(dayjs(time2), 'hour')
            },
            onIntersection(e, o) {
                if (e[0].isIntersecting) {
                    this.lastIntersected = parseInt(e[0].target.dataset.index)
                }
            }
        }
    }
</script>